import React from "react";
import LoginDialog from "../shared/dialog/login-modal";
import RegisterDialog from "../shared/dialog/register-modal";
import ResetNewPasswordDialog from "../shared/dialog/reset-new-password-modal";
import ResetPasswordMessageDialog from "../shared/dialog/reset-password-message-modal";
import RecoverPasswordDialog from "../shared/dialog/recover-password-modal";
import GenericDialog from "../shared/dialog/generic-modal";
import ErrorDialog from "../shared/dialog/error-dialog";
import LoginErrorDialog from "../shared/dialog/login-error-modal";
import { IcoIsoOrbiColor } from "./components/IcoIsoOrbiColor";
import { IconHistorial } from "./components/IconHistorial";
import { IconFcl1 } from "./components/IconFcl1";
import "../styles/landingStyle.css"
import LoginDetailModal from "../shared/dialog/login-detail-modal";
import { Link } from "react-router-dom";

class Login extends React.Component {

  constructor(props) {
    super(props);

    this.pricingRef = React.createRef();

    this.state = {
      showLoginModal: false,
      showRegisterModal: false,
      showRecoverPasswordModal: false,
      showRecoverMessageModal: false,
      showRegisterMessageModal: false,
      showResetNewPasswordModal: false,
      showResetNewPasswordMessage: false,
      showSuccessValidation: false,
      showSuccessInvitation: false,
      showRegisterInvitationMessageModal: false,
      showInvitationModal: false,
      showSessionError: false,
      emailInvitation: this.getEmailInvitation(props),
      hasInvitation: false,
      errorMessage: "",
      emailRegister: "",

      currentSlide: 0,

      mode: 1,
      showViewDetail: false,
      detailTitle: '',
      detailMessage: '',
      detailImg: '',
      showMobileMenu: false,
    };
  }

  componentDidMount() {
    const {
      location: { search = "" },
    } = this.props;
  }

  getEmailInvitation = (props) => {
    const {
      location: { search = "" },
    } = props;

    if (search !== "" && search.length > 1 && search.includes("email")) {
      const result = search.split("=");

      if (result.length > 1) {
        return String(result[1]);
      } else {
        return "";
      }
    }

    return "";
  };

  handleEnd = () => {
    const { currentSlide } = this.slider.context.state;
    this.setState({ currentSlide });
  };

  handleModeChange = (e) => {
    this.setState({ mode: e });
  };

  closeModals = () =>
    this.setState({
      showLoginModal: false,
      showRegisterModal: false,
      showRecoverPasswordModal: false,
      showRecoverMessageModal: false,
      showRegisterMessageModal: false,
      errorMessage: "",
      showResetNewPasswordModal: false,
      showResetNewPasswordMessage: false,
      showSuccessValidation: false,
      showSuccessInvitation: false,
      showRegisterInvitationMessageModal: false,
      showSessionError: false,
    });

  forgotModal = (value) => {
    this.setState({
      showLoginModal: false,
      showRecoverPasswordModal: value,
    });
  };

  changeToLogin = () => {
    this.setState({
      showRegisterModal: false,
      showLoginModal: true,
    });
  };

  toggleMobileMenu = () => {
    this.setState({
      showMobileMenu: !this.state.showMobileMenu
    });
  };

  render() {
    return (
    <div className="landingpage">
      <div className={"group"+(this.state.mode === 1 ? '' : ' white')+(this.state.mode !== 3 ? '' : ' TL')}>
        <div className="overlap">
          {
            this.state.mode === 1 ? (
              <div className="frame">
                <div className="div">
                  <img
                    className="subtract"
                    alt="Subtract"
                    src="/images/img/subtract.svg"
                  />
    
                  <img
                    className="mask-group"
                    alt="Mask group"
                    src="/images/img/mask-group.png"
                  />
                </div>
              </div>
            ) : (
              <div className="frame-0">
              </div>
            )
          }

          <div className="group-2">

            <div className="group-3">
            <div className="frame-4">
                <img
                  className="ico-logo-orbi-color"
                  alt="Ico logo orbi color"
                  src={`/images/img/${this.state.mode === 1 ? 'ico-logo-orbi-color' : 'logo-orbi-blanco'}.svg`}
                  onClick={() => {this.handleModeChange(1)}}
                />
                <img
                  className="ico-logo-orbi-color-mobile"
                  alt="Ico logo orbi color"
                  src={`/images/img/${this.state.mode === 1 ? 'ico-logo-orbi-color-mobile' : 'ico-logo-orbi-blanco-mobile'}.svg`}
                  onClick={() => {this.handleModeChange(1)}}
                />


                <div className="frame-5">
                  <div className={"text-wrapper-3" + (this.state.mode === 2 ? ' selected' : '') } onClick={() => {this.handleModeChange(2)}}>Dueños de Cargas</div>

                  <div className={"text-wrapper-3" + (this.state.mode === 3 ? ' selected' : '') } onClick={() => {this.handleModeChange(3)}}>Transportistas</div>

                  <div className="crea-tu-cuenta-wrapper">
                    <div className="crea-tu-cuenta-2">
                      <button 
                        className="crea-tu-cuenta-2-btn"
                        // style={{border: "0px", backgroundColor: "unset", height: "59px", width: "243px"}}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.setState({
                            showLoginModal: true
                          });
                        }}
                      >
                        Iniciar sesión
                      </button>
                    </div>
                  </div>

                  <div className="frame-5-mobile">
                    <img
                      className="ico-mobile-menu"
                      alt="Ico logo orbi color"
                      src={`/images/img/mobile-menu-icon${this.state.mode !== 1 ? '-white' : ''}.svg`}
                      onClick={() => {this.toggleMobileMenu()}}
                    />
                    <div className="mobile-menu" style={{display: this.state.showMobileMenu ? 'block' : 'none'}}>
                      <div className="mobile-menu-item" onClick={() => {this.setState({mode: 1, showMobileMenu: false})}}>Home</div>
                      <div className="mobile-menu-item" onClick={() => {this.setState({mode: 2, showMobileMenu: false})}}>Dueños de Cargas</div>
                      <div className="mobile-menu-item" onClick={() => {this.setState({mode: 3, showMobileMenu: false})}}>Transportistas</div>
                      <div className="mobile-menu-item" onClick={() => {
                        this.pricingRef.current.scrollIntoView();
                        this.setState({showMobileMenu: false});
                      }}>
                          Precios
                      </div>
                      <div className="mobile-menu-item">
                        <Link to={`/survey`}>
                          Regístrate
                        </Link>
                      </div>
                      <div className="mobile-menu-item" onClick={() => {this.setState({showLoginModal: true, showMobileMenu: false})}}>Iniciar Sesión</div>
                    </div>
                  </div>
                </div>

              </div>

              <div className="frame-2">
                <p className="la-forma-m-s-r-pida">
                  <span className="text-wrapper">
                    {
                      this.state.mode === 1 ? (
                        'El único canal que conecta todas las cadenas de abastecimiento.'
                      ) : this.state.mode === 2 ? (
                        <>La forma más <b>rápida</b> y <b>simple</b> de conectar a todos tus clientes, proveedores y transportistas en el mismo canal.</>
                      ) : <>La forma más <b>rápida</b> y <b>simple</b> de conectar con todos tus clientes en el mismo canal.</>
                    }
                  </span>
                </p>

                {
                  this.state.mode === 1 && (
                    <div className="text-wrapper-2">
                      Para recibir una invitación:
                    </div>
                  )
                }

                <div className="frame-3">
                  <div className="CTA-primary rectangle">
                    {/* <div className="rectangle" /> */}
                    <Link to={`/survey`}>
                      <button className="crea-tu-cuenta">Regístrate aquí</button>
                    </Link>
                  </div>
                </div>
              </div>

              
            </div>

            <img
              className="element-ordenes-linea"
              alt="Element ordenes linea"
              src="/images/img/2-ordenes-linea-transportista.png"
            />
            
          </div>

          {
            this.state.mode === 1 && (
              <div className="frame-6">
                <p className="p">Empresas que confían en nosotros</p>
    
                {/* <img className="group-4" alt="Group" src="/images/img/group-330.png" /> */}
                <div className="group-container">
                  <img className="group-4" alt="Group" src="/images/img/group-330-1.svg" />
                  <img className="group-4" alt="Group" src="/images/img/group-330-2.svg" />
                  <img className="group-4" alt="Group" src="/images/img/group-330-3.svg" />
                  <img className="group-4" alt="Group" src="/images/img/group-330-4.svg" />
                  <img className="group-4" alt="Group" src="/images/img/group-330-5.svg" />
                  <img className="group-4" alt="Group" src="/images/img/group-330-6.svg" />
                </div>
              </div>
            )
          }
        </div>
      </div>
      
      {
        this.state.mode === 1 && (
          <div className="tech-wrapper">
            <img
              className="img-2-s"
              alt="Icon calendar"
              src="/images/img/group-146.svg"
            />
            <div className="tech">
              <div className="title">
                <p className="uno-para-todos-y">
                  Un mismo canal para todos
                  {/* <br /> */} y todos para uno
                </p>
    
                <p className="text-wrapper-4">
                  Visualiza, optimiza y transforma con una misma plataforma
                  universal, ominicanal y omnidireccional para todas las
                  organizaciones de tu cadena de abastecimiento y deja que todos
                  tengan los mismos beneficios al compartir la misma herramienta.
                </p>
    
                <div className="frame-3">
                  <div className="CTA-primary rectangle">
                    <Link to={`/survey`}>
                      <button className="crea-tu-cuenta">Regístrate aquí</button>
                    </Link>
                  </div>
                </div>
    
                <div className="overlap-wrapper">
                  <img
                    className="img-2"
                    alt="Icon calendar"
                    src="/images/img/group-146.svg"
                  />
                </div>
              </div>
    
              <div className="row">
                <div className="frame-wrapper">
                  <div className="frame-7">
                    <div className="text-wrapper-5">Universal</div>
    
                    <p className="text-wrapper-6">
                      Un canal de comunicación único con interfaces simples que
                      cualquiera puede usar sin ser expertos en tecnología, para
                      todas las areas de la operación y toma de decisiones de tu
                      negocio, desde protección patrimonial hasta dirección general,
                      pasando por almacén, producción, planeación, logística,
                      compras, ventas, dirección, etc. Además con el mismo canal
                      pueden colaborar todos tus clientes, proveedores y
                      transportistas, sin importar el giro ni el tamaño de sus
                      empresas.
                    </p>
                  </div>
                </div>
    
                <div className="frame-wrapper">
                  <div className="frame-8">
                    <div className="text-wrapper-5">Omnicanal</div>
    
                    <p className="text-wrapper-6">
                      Integra a todas las fábricas, almacenes, puntos de venta,
                      transportistas de tu distribución primaria, secundaria, ultima
                      milla, canal moderno, tradicional o digital de tu empresa, de
                      tus clientes y de tus proveedores.
                    </p>
                  </div>
                </div>
    
                <div className="frame-wrapper">
                  <div className="frame-9">
                    <div className="text-wrapper-5">Omnidireccional</div>
    
                    <p className="text-wrapper-6">
                      Conectate de forma privada con todos tus clientes,
                      proveedores, transportistas con una misma red donde todos
                      ellos  también puedan colaborar con su propia red de clientes
                      proveedores y transportistas sin complejidades y sin
                      fragmentación.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }

      {
        this.state.mode === 1 && (
          <div className="frame-10">
            <div className="frame-11">
              <div className="text-wrapper-7">Integra tu operación</div>
    
              <p className="text-wrapper-8">
                Forma parte de una comunidad privada que soporta cientos de empresas
                transportistas, miles de clientes y millones de pedidos y viajes.
              </p>
            </div>
    
            <div className="frame-12">
              <div className="frame-13">
                <div className="frame-14">
                  <p className="text-wrapper-9">
                    Reduce hasta un 50% el tiempo de ciclo de viajes en tu
                    operación.
                  </p>
                </div>
              </div>
    
              <div className="frame-15">
                <div className="frame-14">
                  <p className="text-wrapper-9">
                    Incrementa hasta un 30% la productividad de los camiones en tu
                    operación.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )
      }

      {/* <div className="frame-16">
        <div className="frame-17">
          <div className="text-wrapper-10">Testimoniales</div>

          <div className="frame-18">
            <div className="group-6">
              <div className="overlap-group-2">
                <p className="text-wrapper-11">
                  Designers need to have a strong understanding of the
                  principles of design in order to create effective solutions.
                  They must also be aware of the latest trends and technologies
                  so that they can stay ahead of the curve.
                </p>

                <div className="overlap-group-3">
                  <div className="text-wrapper-12">Jane Doe</div>

                  <div className="text-wrapper-13">CEO of Inkyy.com</div>
                </div>

                <img className="group-7" alt="Group" src="/images/img/group-91.png" />

                <img className="line" alt="Line" src="/images/img/line-1.svg" />
              </div>

              <img
                className="rectangle-2"
                alt="Rectangle"
                src="/images/img/path-8.png"
              />
            </div>

            <div className="group-6">
              <div className="overlap-3">
                <p className="text-wrapper-11">
                  Designers need to have a strong understanding of the
                  principles of design in order to create effective solutions.
                  They must also be aware of the latest trends and technologies
                  so that they can stay ahead of the curve.
                </p>

                <div className="overlap-group-3">
                  <div className="text-wrapper-12">Jane Doe</div>

                  <div className="text-wrapper-13">CEO of Inkyy.com</div>
                </div>

                <img className="group-7" alt="Group" src="/images/img/group-91.png" />

                <img className="line-2" alt="Line" src="/images/img/line-1-1.svg" />

                <img
                  className="rectangle-3"
                  alt="Rectangle"
                  src="/images/img/path-8.png"
                />
              </div>
            </div>

            <div className="group-8">
              <div className="overlap-3">
                <p className="text-wrapper-11">
                  Designers need to have a strong understanding of the
                  principles of design in order to create effective solutions.
                  They must also be aware of the latest trends and technologies
                  so that they can stay ahead of the curve.
                </p>

                <div className="overlap-group-3">
                  <div className="text-wrapper-12">Jane Doe</div>

                  <div className="text-wrapper-13">CEO of Inkyy.com</div>
                </div>

                <img
                  className="group-9"
                  alt="Group"
                  src="/images/img/group-91-2.png"
                />

                <img className="line-3" alt="Line" src="/images/img/line-1-2.svg" />

                <img
                  className="rectangle-3"
                  alt="Rectangle"
                  src="/images/img/path-8.png"
                />
              </div>
            </div>
          </div>

          <div className="group-10">
            <div className="ellipse-6" />

            <div className="ellipse-7" />

            <div className="ellipse-8" />
          </div>
        </div>
      </div> */}

      {
        this.state.mode === 1 && (
          <div className="frame-19">
            <div className="frame-20">
              <div className="text-wrapper-14">Nuestros datos</div>
    
              <div className="text-wrapper-15">Mas de...</div>
            </div>
    
            <div className="frame-21">
              <div className="frame-22">
                <p className="element">
                  <span className="text-wrapper">3</span>
    
                  <span className="text-wrapper-16">M</span>
                </p>
    
                <div className="text-wrapper-17">De viajes monitoreados</div>
              </div>
    
              <div className="frame-22">
                <p className="element">
                  <span className="text-wrapper">$50</span>
    
                  <span className="text-wrapper-16">MM</span>
                </p>
    
                <p className="text-wrapper-17">
                  de pesos timbrados en cartas portes desde nuestra plataforma
                </p>
              </div>
    
              <div className="frame-22">
                <p className="element">
                  <span className="text-wrapper">4</span>
    
                  <span className="text-wrapper-16">K</span>
                </p>
    
                <p className="text-wrapper-17">
                  {" "}
                  operadores que utilizan nuestra app
                </p>
              </div>
            </div>
          </div>
        )
      }

      <div className="frame-23">

        {
          this.state.mode === 1 && (
            <div className="frame-11">
              <div className="text-wrapper-7">Beneficios</div>
    
              <p className="text-wrapper-8">
                Decide transformarte y siente el poder de  la velocidad de la
                información colaborativa en tiempo real y la simplicidad en su
                máxima expresión para explotar tu verdadero potencial
              </p>
            </div>
          )
        }

        {
          this.state.mode === 1 && (
            <div className="frame-24">
              <div className="frame-25">
                <div className="frame-26">
                  <img className="vector" alt="Vector" src="/images/img/vector.svg" />
    
                  <div className="frame-27">
                    <p className="text-wrapper-18">
                      Incrementa la productividad de tus recursos y  operación
                    </p>
                  </div>
                </div>
              </div>
    
              <div className="frame-25">
                <div className="frame-26">
                  <img
                    className="pag-pregunta-b"
                    alt="Pag pregunta b"
                    src="/images/img/pag-11-pregunta-b-act.svg"
                  />
    
                  <div className="frame-27">
                    <p className="text-wrapper-18">
                      Incrementa el nivel de servicio y experiencia de tus clientes
                      (NPS)
                    </p>
                  </div>
                </div>
              </div>
    
              <div className="frame-25">
                <div className="frame-26">
                  <img
                    className="pag-pregunta-a"
                    alt="Pag pregunta a"
                    src="/images/img/pag-11-pregunta-a-act.svg"
                  />
    
                  <div className="frame-27">
                    <p className="text-wrapper-18">
                      Incrementa la rentabilidad de tu negocio: EBITDA
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )
        }

        <div className="frame-28">
          {
            this.state.mode === 2 && (
              <div className="text-wrapper-19-1">
                Beneficios a tu operación
              </div>
            )
          }

          <div className="text-wrapper-19">
            {
              this.state.mode === 1 ? (
                'Cómo funciona Orbinetwork'
              ) : this.state.mode === 2 ? (
                'Transformación digital integral'
              ) : 'Beneficios a tu operación'
            }
          </div>

          {
            this.state.mode === 2 && (
              <div className="text-wrapper-19-2">
                Nuestra plataforma ofrece soluciones integrales para la cadena de suministro, mejorando la eficiencia y la colaboración. 
                Con Orbi Network, transforma tus operaciones logísticas en un proceso ágil y efectivo.
              </div>
            )
          }

          <div className="frame-29">
            <div className="frame-30">
              <div className="frame-31">
                <div className="frame-31-1">
                  <img
                    className="frame-32"
                    alt="Frame"
                    src={ this.state.mode === 1 ? (
                      "/images/img/group-129-1.svg" ) : this.state.mode === 2 ? (
                        "/images/img/cargo-owner-1.svg"
                      ) : "/images/img/cargo-owner-2.svg"
                    }
                  />
                </div>

                <div className="frame-33">
                  <div className="text-wrapper-20">
                    {
                      this.state.mode === 1 ? (
                        'Regístrate'
                      ) : this.state.mode === 2 ? (
                        'Visibilidad Omnicanal en tiempo real'
                      ) : 'Transformación digital integral '
                    }
                  </div>

                  <p className="div-2">
                    <span className="text-wrapper-21">
                      {
                        this.state.mode === 1 ? (
                          'Recibe una invitación para crear tu perfil personal y el perfil de empresa.'
                        ) : this.state.mode === 2 ? (
                          'Monitorea cada fase del ciclo de un pedido, desde su creación hasta la entrega, con seguimiento minuto a minuto. Mejora la transparencia y reacciona rápido ante imprevistos.'
                        ) : 'El único SAAS de logística que te permite conectar todas las areas de tu negocio...'
                      }
                      <br />
                    </span>

                    {
                      this.state.mode === 3 && (
                        <span
                         className="text-wrapper-22"
                         onClick={() => {
                          this.setState({
                            showViewDetail: true,
                            detailImg: '/images/img/cargo-owner-2.svg',
                            detailTitle: 'Transformación digital integral',
                            detailMessage: 'El único SAAS de logística que te permite conectar todas las areas de tu negocio, sucursales o bases con todos tus clientes sin importar su industria, giros comercial o tipo de operación facilitando una operación más rápida, eficiente y con experiencia digital.'
                          })
                         }}
                        >Leer más</span>
                      )
                    }
                  </p>
                </div>
              </div>
            </div>

            <div className="frame-30">
              <div className="frame-31">
                <div className="frame-31-1">
                  <img 
                    className="frame-32" 
                    alt="Frame" 
                    src={ this.state.mode === 1 ? (
                      "/images/img/group-129-2.svg" ) : this.state.mode === 2 ? (
                        "/images/img/cargo-owner-2.svg"
                      ) : "/images/img/cargo-owner-1.svg"
                    }
                  />
                </div>

                <div className="frame-33">
                  <div className="text-wrapper-23">
                    {
                      this.state.mode === 1 ? (
                        'Invita'
                      ) : this.state.mode === 2 ? (
                        `Logística inteligente con IA`
                      ) : 'Visibilidad Omnicanal en tiempo real'
                    }
                  </div>

                  <p className="div-2">
                    <span className="text-wrapper-21">
                      {
                        this.state.mode === 1 ? (
                          `A los colaboradores de áreas clave en tu empresa para
                          decisiones y operaciones.`
                        ) : this.state.mode === 2 ? (
                          `Automatiza la planificación, gestión de envíos y citas de recolección/entrega con análisis predictivos basados en datos históricos y situaciones en tiempo real.`
                        ) : `Brinda estatus automático a todos tus clientes de cada etapa de sus viajes...`
                      }
                      <br />
                    </span>
                    {
                      this.state.mode === 3 && (
                        <span
                          className="text-wrapper-22"
                          onClick={() => {
                          this.setState({
                            showViewDetail: true,
                            detailImg: '/images/img/cargo-owner-1.svg',
                            detailTitle: 'Visibilidad Omnicanal en tiempo real',
                            detailMessage: 'Brinda estatus automático a todos tus clientes de cada etapa de sus viajes en tiempo real desde la confirmación de solicitud de viaje, la asignación de unidad, la recolección de las mercancias y hasta la entrega en destino final. '
                          })
                         }}
                        >Leer más</span>
                      )
                    }
                  </p>
                </div>
              </div>
            </div>

            <div className="frame-30">
              <div className="frame-31">
                <div className="frame-31-1">
                  <img
                    className="group-11"
                    alt="Group"
                    src={ this.state.mode === 1 ? (
                      "/images/img/group-129-3.svg" ) : this.state.mode === 2 ? (
                        "/images/img/cargo-owner-3.svg"
                      ) : "/images/img/cargo-owner-3.svg"
                    }
                  />
                </div>

                <div className="frame-33">
                  <div className="text-wrapper-23">
                    
                      {
                        this.state.mode === 1 ? (
                          `Configura`
                        ) : this.state.mode === 2 ? (
                          `Seguridad vial y cumplimiento fiscal garantizado`
                        ) : `Logística inteligente con IA`
                      }
                  </div>

                  <p className="div-2">
                    <span className="text-wrapper-21">
                      {
                        this.state.mode === 1 ? (
                          `Los catálogos de datos maestros de la cuenta de tu
                          empresa.`
                        ) : this.state.mode === 2 ? (
                          `Verifica que tus transportistas cumplan con las normativas viales y fiscales, incluyendo la Carta Porte, reduciendo el riesgo de accidentes y sanciones.`
                        ) : `Recibe asignaciones de viajes y citas de recolección y entrega de forma...`
                      }
                      
                      <br />
                    </span>

                    {
                      this.state.mode === 3 && (
                        <span
                          className="text-wrapper-22"
                          onClick={() => {
                          this.setState({
                            showViewDetail: true,
                            detailImg: '/images/img/cargo-owner-3.svg',
                            detailTitle: 'Logística inteligente con IA',
                            detailMessage: 'Recibe asignaciones de viajes y citas de recolección y entrega de forma automática por parte de tus clientes con la disponibilidad que tus unidades reportan de forma automática a los clientes con quien se encuentran asignadas.'
                          })
                         }}
                        >Leer más</span>
                      )
                    }
                  </p>
                </div>
              </div>
            </div>

            {
              this.state.mode === 3 && (
                <div className="frame-30">
                  <div className="frame-31">
                    <div className="frame-31-1">
                      <img
                        className="group-11"
                        alt="Group"
                        src="/images/img/cargo-owner-3.svg"
                      />
                    </div>

                    <div className="frame-33">
                      <div className="text-wrapper-23">
                        Seguridad vial y cumplimiento fiscal garantizado
                      </div>

                      <p className="div-2">
                        <span className="text-wrapper-21">
                          {
                            `Audita el cumplimiento normativo de tu flota y permite a tus clientes que...`
                          }
                          <br />
                        </span>
                        <span
                          className="text-wrapper-22"
                          onClick={() => {
                            this.setState({
                              showViewDetail: true,
                              detailImg: '/images/img/cargo-owner-3.svg',
                              detailTitle: 'Seguridad vial y cumplimiento fiscal garantizado',
                              detailMessage: 'Audita el cumplimiento normativo de tu flota y permite a tus clientes que validen tus documentos.  Garantiza el cumplimiento fiscal de la carta porte con las funciones de timbrado automatico al momento de cargar las mercancías o durante el viaje cada vez que hay un cambio de operador o vehículo.'
                            })
                          }}
                        >Leer más</span>
                      </p>
                    </div>
                  </div>
                </div>
              )
            }

            <div className="frame-30">
              <div className="frame-31">
                <div className="frame-31-1">
                  <img 
                    className="frame-35" 
                    alt="Frame" 
                    src={ this.state.mode === 1 ? (
                      "/images/img/group-129-4.svg" ) : this.state.mode === 2 ? (
                        "/images/img/group-129-5.svg"
                      ) : "/images/img/group-129-5.svg"
                    } 
                  />
                </div>

                <div className="frame-33">
                  <p className="text-wrapper-20">
                    
                    {
                      this.state.mode === 1 ? (
                        `Crea tu red de colaboración`
                      ) : this.state.mode === 2 ? (
                        `Medición de productividad y mejora continua`
                      ) : `Medición de productividad y mejora continua`
                    }
                  </p>

                  <p className="text-wrapper-24">
                    
                    {
                      this.state.mode === 1 ? (
                        `Envía invitaciones a tus clientes, proveedores y
                        transportistas para que registren sus empresas y todos
                        puedan colaborar de forma privada en la red de Orbinetwork.`
                      ) : this.state.mode === 2 ? (
                        `Evalúa el desempeño de  toda la cadena logística con reportes e indicadores personalizables del módulo de Inteligencia de Negocios (BI), para tomar decisiones estratégicas.`
                      ) : `Evalúa el desempeño de tus unidades, operadores y de tus clientes e...`
                    }
                    {
                      this.state.mode === 3 && (
                        <>
                          <br />
                          <span
                            className="text-wrapper-22"
                            onClick={() => {
                            this.setState({
                              showViewDetail: true,
                              detailImg: '/images/img/group-129-5.svg',
                              detailTitle: 'Medición de productividad y mejora continua',
                              detailMessage: 'Evalúa el desempeño de tus unidades, operadores y de tus clientes e identifica oportunidades para mejorar, asi como la rentabilidad de  rutas, operaciones y clientes con los reportes y decenas de indicadores, tablas y gráficas personalizables del módulo de Inteligencia de Negocios (Bi Business Intelligence.)'
                            })
                           }}
                          >Leer más</span>
                        </>
                      )
                    }
                  </p>
                </div>
              </div>
            </div>
            
            <div className={this.state.mode === 3 ? "frame-30" : "frame-36"}>
              {
                this.state.mode !== 3 && (
                  <div className="frame-31-1">
                    <img 
                      className="frame-32" 
                      alt="Frame" 
                      src={ this.state.mode === 1 ? (
                        "/images/img/group-129-5.svg" ) : this.state.mode === 2 ? (
                          "/images/img/cargo-owner-5.svg"
                        ) : ""
                      } 
                    /> 
                  </div>
                )
              }

              <p className="text-wrapper-25">
                Todo listo, comienza a usar Orbinetwork
              </p>

              <p className="text-wrapper-26">
                {
                  this.state.mode === 1 ? (
                    `Conoce todo lo que Orbinetwork puede hacer para ti.`
                  ) : this.state.mode === 2 ? (
                    `Conoce todo lo que Orbinetwork puede hacer para ti. Regístrate ahora para recibir una invitación.`
                  ) : `Audita el cumplimiento normativo de tu flota y permite a tus clientes que...`
                }
              </p>

              <div className="frame-37">
                    <Link to={`/survey`}>
                      <div className="crea-tu-cuenta-2">Regístrate</div>
                    </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="frame-38">
        <div className="frame-39">
          <div className="text-wrapper-27">
            
            {
              this.state.mode === 1 ? (
                `Beneficios`
              ) : this.state.mode === 2 ? (
                `Features`
              ) : `Features`
            }
          </div>

          <div className="frame-40">
            <p className="text-wrapper-28">
              
              {
                this.state.mode === 1 ? (
                  `Conoce los beneficios y funciones para`
                ) : this.state.mode === 2 ? (
                  `Funciones para Dueños de Carga`
                ) : `Funciones para Transportistas`
              }
            </p>
          </div>
        </div>
        
        {
          this.state.mode === 1 ? (
            <div className="frame-41">
              <div className="frame-42">
                <div className="frame-43">
                  <div className="group-12" />
    
                  <div className="frame-44">
                    <div className="frame-45">
                      <div className="text-wrapper-29">Dueños de Cargas</div>
    
                      <p className="si-tu-actividad">
                        Si tu actividad preponderante es la fabricación,
                        distribución, comercialización y/o venta de algún producto
                        terminado o materia prima, descubre que beneficios tiene
                        nuestra comunidad para los dueños de carga como tú.
                      </p>
                    </div>
    
                    <div className="frame-46">
                      <div className="CTA-primary-2 rectangle-4">
                        <Link to={`/survey`}>
                          <button className="crea-tu-cuenta-3">Regístrate aquí</button>
                        </Link>
                      </div>
    
                      <div className="crea-tu-cuenta-4"  onClick={() => {this.handleModeChange(2)}}>Conoce más</div>
                    </div>
                  </div>
                </div>
              </div>
    
              <div className="frame-47">
                <div className="frame-48">
                  <div className="frame-49">
                    <img
                      className="group-13"
                      alt="Group"
                      src="/images/img/group-148.png"
                    />
                    <div className="text-wrapper-30">Transportistas</div>
                  </div>
    
                  
                  <p className="si-tu-actividad-2">
                    Si tu actividad preponderante es la prestación de servicio
                    de transportación carga federal o privada a dueños de carga,
                    descubre que beneficios tiene nuestra comunidad para los
                    transportistas como tú.
                  </p>
    
                  <div className="frame-46">
                    <div className="CTA-primary-3">
                      <img
                        className="rectangle-5"
                        alt="Rectangle"
                        src="/images/img/rectangle.svg"
                      />
    
                      <Link to={`/survey`}>
                        <button className="crea-tu-cuenta-5">Regístrate aquí</button>
                      </Link>
                    </div>
    
                    <div className="crea-tu-cuenta-6"  onClick={() => {this.handleModeChange(3)}}>Conoce más</div>
                  </div>
                </div>
              </div>
            </div>
          ) : this.state.mode === 2 ? (
            <div className="frame-41-1">
              <div className="frame-41-2">
                <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-1.svg" />
                <p className="frame-41-2-title">
                  Red de colaboración
                </p>
                <p className="frame-41-2-text">
                  Crea tu propia red privada de colaboración con todos tus clientes en una misma herramienta.
                </p>
              </div>
              <div className="frame-41-2">
                <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-2.svg" />
                <p className="frame-41-2-title">
                  Control de Órdenes y Viajes
                </p>
                <p className="frame-41-2-text">
                  Gestiona de principio a fin los pedidos y embarques de todas tus operaciones (T1, T2, UM)
                </p>
              </div>
              <div className="frame-41-2">
                <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-3.svg" />
                <p className="frame-41-2-title">
                  Monitoreo
                </p>
                <p className="frame-41-2-text">
                  Visualiza estatus y ubicacion en tiempo real, y centraliza la gestiona citas de recolección y entrega.
                </p>
              </div>
              <div className="frame-41-2">
                <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-4.svg" />
                <p className="frame-41-2-title">
                  Timbrado CFDI con Complemento Carta Porte
                </p>
                <p className="frame-41-2-text">
                  Automatiza el timbrado de los CFDIS con Complemento Carta Porte de todos tus viajes.
                </p>
              </div>
              <div className="frame-41-2">
                <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-5.svg" />
                <p className="frame-41-2-title">
                  Normatividad
                </p>
                <p className="frame-41-2-text">
                  Audita las normatividades de todos tus transportistas
                </p>
              </div>
              <div className="frame-41-2">
                <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-6.svg" />
                <p className="frame-41-2-title">
                  Disponibilidad de Flota
                </p>
                <p className="frame-41-2-text">
                  Consulta la unidades disponibles de tus transportistas.
                </p>
              </div>
              <div className="frame-41-2">
                <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-7.svg" />
                <p className="frame-41-2-title">
                  Reportes y KPIs
                </p>
                <p className="frame-41-2-text">
                  Mide el desempeño de toda tu cadena con nuestro Business Intelligence.
                </p>
              </div>
            </div>
          ) : (
            <div className="frame-41-1">
              <div className="frame-41-2">
                <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-1.svg" />
                <p className="frame-41-2-title">
                  Red de colaboración
                </p>
                <p className="frame-41-2-text">
                  Crea tu propia red privada de colaboración con todos tus clientes en una misma herramienta.
                </p>
              </div>
              <div className="frame-41-2">
                <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-2.svg" />
                <p className="frame-41-2-title">
                  Control de Viajes
                </p>
                <p className="frame-41-2-text">
                  Gestiona y asigna unidades a tus viajes.
                </p>
              </div>
              <div className="frame-41-2">
                <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-3.svg" />
                <p className="frame-41-2-title">
                  Monitoreo
                </p>
                <p className="frame-41-2-text">
                  Visualiza estatus, ubicacion y evidencias en tiempo real y solicita cambios de citas de recolección y entrega.
                </p>
              </div>
              <div className="frame-41-2">
                <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-4.svg" />
                <p className="frame-41-2-title">
                  Timbrado CFDI con Complemento Carta Porte
                </p>
                <p className="frame-41-2-text">
                  Automatiza el timbrado de los CFDIS con Complemento Carta Porte de todos tus viajes de todos tus clientes.
                </p>
              </div>
              <div className="frame-41-2">
                <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-5.svg" />
                <p className="frame-41-2-title">
                  Normatividad
                </p>
                <p className="frame-41-2-text">
                  Permite que tus clientes auditen las normatividades de tu flotilla.
                </p>
              </div>
              <div className="frame-41-2">
                <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-6.svg" />
                <p className="frame-41-2-title">
                  Disponibilidad de Flota
                </p>
                <p className="frame-41-2-text">
                  Reporta tus unidades disponibles de forma automática a tus clientes.
                </p>
              </div>
              <div className="frame-41-2">
                <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-7.svg" />
                <p className="frame-41-2-title">
                  Reportes y KPIs
                </p>
                <p className="frame-41-2-text">
                  Mide el desempeño de todas tus operaciones y clientes con nuestro Business Intelligence.
                </p>
              </div>
            </div>
          )
        }
        

      </div>

      <div className="frame-50"  ref={this.pricingRef}>
        <div className="frame-51">
          <div className="text-wrapper-19">Nuestros precios</div>

          <p className="text-wrapper-31">
            Elige el plan que mejor se adapte a ti
          </p>
        </div>

        <div className="frame-52">
          <div className="rectangle-6" />

          <div className={"frame-53 " + (this.state.mode !== 3 ? 'active' : '')} onClick={() => { 
            this.handleModeChange(2);
            this.pricingRef.current.scrollIntoView();
          }}>
            <div className="text-wrapper-32" >
              Dueños de carga
            </div>
          </div>

          <div className={"frame-54 " + (this.state.mode === 3 ? 'active' : '')} onClick={() => {
            this.handleModeChange(3);
            this.pricingRef.current.scrollIntoView();
          }}>
            <div className="text-wrapper-33">
              Transportistas
              </div>
          </div>
        </div>

        <div className="group-14-1">
          <div className="group-14-container">
            <p className="text-wrapper-141">Basic**</p>
            <p className="text-wrapper-142">GRATIS</p>
            <div className="group-14-item">
              <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
              Usuarios
            </div>
            <div className="group-14-item">
              <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
              Productos
            </div>
            <div className="group-14-item">
              <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
              Instalaciones
            </div>
            <div className="group-14-item">
              <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
              Red (Comunidad)
            </div>
            <div className="group-14-item">
              <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
              Rutas
            </div>
            <div className="group-14-item">
              <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
              RutaFlotilla (Normatividad)
            </div>
            <p className="text-wrapper-143">Controles</p>
            <div className="group-14-item">
              <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
              Órdenes y citas
            </div>
            <div className="group-14-item">
              <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
              Embarques
            </div>
            <div className="group-14-item">
              <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109-1.svg"/>
              Contratos
            </div>
          </div>
          {
            this.state.mode !== 3 && (
              <div className="group-14-container">
                <p className="text-wrapper-144">Premium</p>
                <p className="text-wrapper-145">$1,199 USD/mes</p>
                <div className="group-14-item">
                  <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                  Usuarios
                </div>
                <div className="group-14-item">
                  <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                  Productos
                </div>
                <div className="group-14-item">
                  <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                  Instalaciones
                </div>
                <div className="group-14-item">
                  <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                  Red (Comunidad)
                </div>
                <div className="group-14-item">
                  <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                  Rutas
                </div>
                <div className="group-14-item">
                  <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                  RutaFlotilla (Normatividad)
                </div>
                <p className="text-wrapper-143">Controles</p>
                <div className="group-14-item">
                  <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                  Órdenes y citas
                </div>
                <div className="group-14-item">
                  <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                  Embarques
                </div>
                <div className="group-14-item">
                  <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                  Tráfico y GPS
                </div>
                <div className="group-14-item">
                  <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                  Instalaciones
                </div>
                <div className="group-14-item">
                  <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                  Contratos
                </div>
                <div className="group-14-item">
                  <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                  Reportes: Inteligencia de negocios
                </div>
              </div>
            )
          }
        </div>

        <div className="group-14-2">
          <p className="text-wrapper-146">*Versión Lite incluida gratis. Opcionalmente puede pagar la versión</p>

          <div className="group-14-item">
            <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
            Incluido
          </div>
          <div className="group-14-item">
            <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109-1.svg"/>
            Incluido para las transacciones/viajes pagados por evento con Orbibucks
          </div>
          <div className="group-14-item">
            <img className="group-14-checkmark" alt="Checkmark" src="/images/img/group-286.svg"/>
            Pago por evento con Orbibucks tarifa variable por tipo de embarque
          </div>
        </div>

        <div className="group-14-3">
          <p className="text-wrapper-147">**Costo en Orbibucks por tipo de embarques</p>
          <div className="group-14-item">
            <img className="group-14-checkmark" alt="Checkmark" src="/images/img/group-286-1.svg"/>
            <div className="group-14-item-description">
              <div className="text-wrapper-148">*FTL (T1)</div>
              <div className="text-wrapper-149">1.00 Orbibuck por remolque por viaje</div>
            </div>
          </div>
          <div className="group-14-item">
            <img className="group-14-checkmark" alt="Checkmark" src="/images/img/group-286-1.svg"/>
            <div className="group-14-item-description">
              <div className="text-wrapper-148">LTL (T2 y UM)</div>
              <div className="text-wrapper-149">0.40 Orbibucks por pedido/entrega</div>
            </div>
          </div>
        </div>

        <div className="frame-69-1">
          <p className="text-wrapper-691">Precios Orbibucks</p>
          <div className="frame-69-container">
            <img className="frame-69-checkmark" alt="Checkmark" src="/images/img/group-286-2.svg"/>
            <div className="frame-69-item-description">
              <div className="text-wrapper-692">100 Orbibucks</div>
              <div className="text-wrapper-693">$190 USD + IVA</div>
              <div className="text-wrapper-694">P. Unitario $1.90 USD + IVA</div>
            </div>
            <div className="frame-69-extra-info">

            </div>
          </div>
          <div className="frame-69-container extra-info">
            <img className="frame-69-checkmark" alt="Checkmark" src="/images/img/group-286-2.svg"/>
            <div className="frame-69-item-description">
              <div className="text-wrapper-692">200 Orbibucks</div>
              <div className="text-wrapper-693">$340 USD + IVA</div>
              <div className="text-wrapper-694">P. Unitario $1.70 USD + IVA</div>
            </div>
            <div className="frame-69-extra-info">
              Ahorra
              <p className="text-wrapper-695">10%</p>
            </div>
          </div>
          <div className="frame-69-container extra-info">
            <img className="frame-69-checkmark" alt="Checkmark" src="/images/img/group-286-2.svg"/>
            <div className="frame-69-item-description">
              <div className="text-wrapper-692">500 Orbibucks</div>
              <div className="text-wrapper-693">$750 USD + IVA</div>
              <div className="text-wrapper-694">P. Unitario $1.50 USD + IVA</div>
            </div>
            <div className="frame-69-extra-info">
              Ahorra
              <p className="text-wrapper-695">20%</p>
            </div>
          </div>
          <div className="frame-69-container extra-info">
            <img className="frame-69-checkmark" alt="Checkmark" src="/images/img/group-286-2.svg"/>
            <div className="frame-69-item-description">
              <div className="text-wrapper-692">1000+ Orbibucks</div>
              <div className="text-wrapper-693">$1300 USD + IVA</div>
              <div className="text-wrapper-694">P. Unitario $1.30 USD + IVA</div>
            </div>
            <div className="frame-69-extra-info">
              Ahorra
              <p className="text-wrapper-695">30%</p>
            </div>
          </div>
        </div>

      </div>

      <div className="frame-75">
        <div className="group-41">
          <div className="overlap-10">
            <div className="rectangle-13" />

            <div className="frame-77">
              <div className="frame-78">
                <div className="frame-79">
                  <p className="text-wrapper-60">
                    Decide transformarte y únete a la comunidad Orbinetwork hoy
                    mismo.
                  </p>

                  <p className="adem-s-recibe">
                    Además recibe beneficios por invitar a tus clientes,
                    proveedores y transportistas&nbsp;&nbsp;a que se únan y
                    disfruten juntos los beneficios de Orbinetwork
                  </p>
                </div>

                <div className="frame-80">
                  <div className="CTA-primary-wrapper">
                    <div className="CTA-primary-4">
                      <div className="rectangle-20" />

                      <Link to={`/survey`}>
                        <div className="crea-tu-cuenta-7">Regístrate ahora</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="group-43">
                <img
                  className="cloud-copy"
                  alt="Cloud copy"
                  src="/images/img/group-135.svg"
                />
              </div>
            </div>
            <div className="group-76">
              <div className="frame-76">
                <div className="text-wrapper-59">Respaldo</div>

                <p className="text-wrapper-4">
                  Organizaciones y certificaciones que respaldan nuestro trabajo
                </p>
              </div>

              <div className="group-42">
                <div className="group-42-1">
                  <div className="rectangle-14" />

                  <div className="rectangle-14" />

                  <div className="rectangle-14" />
                </div>
                <div className="group-42-1">
                  <div className="rectangle-14" />

                  <div className="rectangle-14" />

                  <div className="rectangle-14" />
                </div>

              </div>
            </div>

            
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="footer-3">
          <div className="frame-81">
            <div className="text-wrapper-61">Dueños de Cargas</div>
          </div>

          <img className="icon" alt="Icon" src="/images/img/icon-1.svg" />

          <div className="frame-82">
            <div className="text-wrapper-61">Transportistas</div>
          </div>
        </div>
        <img className="footer-2" alt="Footer" src="/images/img/footer.svg" />
      </div>

      
      <LoginDetailModal
        open={this.state.showViewDetail}
        img={this.state.detailImg}
        title={this.state.detailTitle}
        message={this.state.detailMessage}
        closeAction={() => this.setState( { showViewDetail: false, detailImg: '', detailTitle: '', detailMessage: '' })}
      />

      <LoginDialog
        open={this.state.showLoginModal}
        title="Iniciar sesión"
        closeAction={() => this.closeModals()}
        closure={() => this.setState({ showRegisterModal: true })}
        showError={(errorMessage) => {
          this.setState({ errorMessage });
        }}
        forgotAction={this.forgotModal}
      />

      <RegisterDialog
        open={this.state.showRegisterModal}
        title="Crea tu cuenta"
        closeAction={() => this.closeModals()}
        closure={() => {
          if (this.state.emailRegister !== '') {
            this.setState({ showRegisterInvitationMessageModal: true, emailRegister: "" });
            return;
          }

          if (this.state.emailInvitation === "") {
            this.setState({ showRegisterMessageModal: true });
          } else {
            this.setState({ showRegisterInvitationMessageModal: true });
          }
        }}
        showError={(errorMessage) => {
          this.setState({ errorMessage });
        }}
        emailInvitation={this.state.emailInvitation}
        hasInvitation={this.state.hasInvitation}
        changeToLogin={() => this.changeToLogin()}
        emailRegister={this.state.emailRegister}
      />

      <ResetNewPasswordDialog
        open={this.state.showResetNewPasswordModal}
        token={this.state.token}
        title="Crea contraseña nueva"
        closeAction={() => this.closeModals()}
        closure={() => this.setState({ showResetNewPasswordMessage: true })}
        showError={(errorMessage) => {
          this.setState({ errorMessage });
        }}
      />

      <ResetPasswordMessageDialog
        open={this.state.showResetNewPasswordMessage}
        message="Tu contraseña fue modificada con éxito"
        acceptAction={() => {
          this.closeModals();
          this.setState({ showLoginModal: true });
        }}
        closeAction={() => {
          this.closeModals();
          this.setState({ showLoginModal: true });
        }}
      />

      <RecoverPasswordDialog
        open={this.state.showRecoverPasswordModal}
        title="Recupera tu contraseña"
        closeAction={() => this.closeModals()}
        closure={() => this.setState({ showRecoverMessageModal: true })}
      />

      <GenericDialog
        open={this.state.showSessionError}
        title="¡Error!"
        message="La sesión se ha expirado"
        acceptAction={() => this.closeModals()}
        closeAction={() => this.closeModals()}
      />

      <GenericDialog
        open={this.state.showRecoverMessageModal}
        title="¡Enviado!"
        message="Te hemos enviado un correo, para que puedas cambiar tu contraseña"
        acceptAction={() => this.closeModals()}
        closeAction={() => this.closeModals()}
      />

      <GenericDialog
        open={this.state.showSuccessValidation}
        title="Éxito"
        message="Tu email ha sido verificado."
        acceptAction={() => this.closeModals()}
        closeAction={() => this.closeModals()}
      />

      <GenericDialog
        open={this.state.showSuccessInvitation}
        title="Éxito"
        message="La invitación ha sido aceptada."
        acceptAction={() => this.closeModals()}
        closeAction={() => this.closeModals()}
      />

      <GenericDialog
        open={this.state.showRegisterMessageModal}
        title="Listo!"
        message="Te hemos enviado un correo, para que confirmes tu cuenta"
        acceptAction={() => {
          this.closeModals();
          window.location.href = "/register/account/";
        }}
        closeAction={() => this.closeModals()}
      />

      <GenericDialog
        open={this.state.showRegisterInvitationMessageModal}
        title="Listo!"
        message="Su cuenta ha sido creada con exito"
        acceptAction={() => {
          this.closeModals();
          window.location.href = "/register/account/";
        }}
        closeAction={() => this.closeModals()}
      />

      <ErrorDialog
        addClass="invitation-modal"
        open={this.state.showInvitationModal}
        message="Ya tienes una cuenta"
        acceptText="Iniciar Sesión"
        acceptAction={() => {
          this.setState({ showInvitationModal: false, showLoginModal: true });
        }}
        closeAction={() => {
          this.setState({ showInvitationModal: false });
        }}
      />
      
      <LoginErrorDialog
        open={this.state.errorMessage !== ""}
        message={this.state.errorMessage}
        acceptText="Aceptar"
        acceptAction={() => {
          this.closeModals();
          this.setState({ emailInvitation: "" });
        }}
        openCreateAccount={ () => {
          this.setState({
            errorMessage: '',
            showRegisterModal: true,
          });
        }}
        openForgetPassword={() => {
          this.setState({
            errorMessage: '',
            showRecoverPasswordModal: true,
          });
        }}
        closeAction={() => {
          this.closeModals();
          this.setState({ emailInvitation: "" });
        }}
      />

    </div>
  );
  }
}

export default Login;