import React, { useState, useMemo, useCallback, createRef } from "react";

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Button from "../../../shared/controls/button";
import { RequisitionText } from "./style";
import { AG_GRID_LOCALE_ES } from "../../../shared/language";
import { ActionsTricolon } from "../../../shared/controls/actions-tricolon";

const getCellView = (key, params) => {
  let view = null;

  switch(key) {
    case 'requisition_number': 
      view = <RequisitionText>{params.value}</RequisitionText>
      break;
    default:
      view = <div></div>;
  }

  return view
};

const filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    const dateAsString = cellValue;
    if (dateAsString == null) return -1;
    const dateParts = dateAsString.split("/");
    const cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0]),
    );

    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }

    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }

    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  }
};

export const RequisitionView = (props) => {

  const gridRef = createRef();
  const [rowData, setRowData] = useState(props.rowData);
  
  const [colDefs, setColDefs] = useState([
    { headerName: 'Fecha de creación de pedido', field: 'creation_date', minWidth: 250, filter: 'agDateColumnFilter', suppressMenu: true, cellClass: "locked-col", lockPosition: "left", cellRenderer: "agGroupCellRenderer", checkboxSelection: props.isModeSelection, filterParams: filterParams },
    { headerName: 'Numero de pedido', field: 'requisition_number', minWidth: 200, filter: 'agTextColumnFilter', suppressMenu: true,
      cellRenderer: params => {
        return getCellView('requisition_number', params);
      }
    },
    { headerName: 'Peso total de pedido', field: 'requisition_total_weight', minWidth: 200, filter: 'agNumberColumnFilter', suppressMenu: true },
    { headerName: 'Volumen total de pedido', field: 'requisition_total_volume', minWidth: 230, filter: 'agNumberColumnFilter', suppressMenu: true },
    { headerName: 'Variantes SKU y cantidad piezas SKU', field: 'sku_quantities', minWidth: 300, filter: 'agTextColumnFilter', suppressMenu: true },
    { headerName: 'Company', field: 'company', minWidth: 200, filter: 'agTextColumnFilter', suppressMenu: true },
    { headerName: 'Origen', field: 'origin', minWidth: 200, filter: 'agTextColumnFilter', suppressMenu: true },
    { headerName: 'Destino', field: 'destiny', minWidth: 200, filter: 'agTextColumnFilter', suppressMenu: true },
    { headerName: 'Fecha entrega', field: 'delivery_date', minWidth: 200, filter: 'agDateColumnFilter', suppressMenu: true, filterParams: filterParams },
    { headerName: 'Etiquetas', field: 'tags', minWidth: 200, filter: 'agTextColumnFilter', suppressMenu: true },
    { headerName: 'Tipo', field: 'type', minWidth: 200, filter: 'agTextColumnFilter', suppressMenu: true },
    { headerName: 'Criticidad', field: 'criticality', minWidth: 200, filter: true, suppressMenu: true },
    { headerName: 'Estatus orden', field: 'order_status', minWidth: 200, filter: true, suppressMenu: true },
    { headerName: 'Estatus pedido', field: 'requisition_status', minWidth: 200, filter: true, suppressMenu: true },
    { headerName: 'CTA', field: 'cta', minWidth: 200, suppressMenu: true, 
      cellRenderer: params => {
        return(
          <div style={{width: '100%', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Button 
              text='Convertir a orden' 
              type='primary small'
              onClick={() => {
                props.handleChange({
                  target: {
                    name: "test",
                    value: 'test'
                  }
                });  
              }}
            />
          </div>
        );
      }
    },
    { headerName: 'Acciones', field: 'actions', minWidth: 200, suppressMenu: true, tooltipField: 'actions' }
  ]);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      floatingFilter: true,
      tooltipComponent: params => {
        return(
          <div
            style={{
              backgroundColor: 'black',
              color: 'white',
              padding: 10
            }}
          >
            <div
              style={{
                cursor: 'pointer'
              }}
              onClick={() => {
                props.handleChange({
                  target: {
                    name: "test",
                    value: 'test'
                  }
                })
              }}
            >{'Cancelar pedido'}</div>
            <div
              style={{
                cursor: 'pointer'
              }}
              onClick={() => {
                props.handleChange({
                  target: {
                    name: "test",
                    value: 'test'
                  }
                })
              }}
            >{'Añadir producto nuevo'}</div>
            <div
              style={{
                cursor: 'pointer'
              }}
              onClick={() => {
                props.handleChange({
                  target: {
                    name: "test",
                    value: 'test'
                  }
                })
              }}
            >{'Dividir pedido'}</div>
          </div>
        );
      },
    };
  }, []);

  const onGridReady = useCallback((params) => {

    if (props.configuration['filter'] !== undefined && typeof props.configuration['filter'] === "object") {
      gridRef.current.api.setFilterModel(
        props.configuration['filter']
      );
    }

    if (props.configuration['columns'] !== undefined && typeof props.configuration['columns'] === "object") {

      let temp_columns = props.configuration['columns'];

      if (temp_columns.length === colDefs.length) {
        gridRef.current.api.setColumnDefs(
          temp_columns
        );
        return;
      }

      try {

        let currentColumns = colDefs.map( item => item.field);
        let currentColumnsSaved = props.configuration['columns'].map( item => item.field);
        let difference = currentColumns.filter(x => !currentColumnsSaved.includes(x));

        let newColumnsToAdd = [];

        colDefs.map( (item, index) => {
          if (difference.includes(item.field)) {
            newColumnsToAdd.push({
              index,
              column: item
            })
          }
        });

        newColumnsToAdd.map( item => {
          temp_columns.splice(item.index, 0, item.column);
        });

        gridRef.current.api.setColumnDefs(
          temp_columns
        );

      } catch (error) {
        console.error('Error setting columns definitions', error);

        gridRef.current.api.setColumnDefs(
          temp_columns
        );
      }
    }

  }, []);

  const detailCellRendererParams = useMemo(() => {
    return {
      detailGridOptions: {
        rowHeight: 50,
        columnDefs: [
          { headerName: 'Producto', field: "product", suppressMenu: true },
          { headerName: 'Peso Bruto', field: "gross_weight", suppressMenu: true },
          { headerName: 'Peso Neto', field: "net_weight", suppressMenu: true },
          { headerName: 'Volumen', field: "volume", suppressMenu: true },
          { headerName: 'Incoterm', field: "incoterm", suppressMenu: true },
          { headerName: 'Compartido con', field: "shared_with", suppressMenu: true }
        ],
        defaultColDef: {
          flex: 1,
        }
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.products);
      },
    };
  }, []);

  const getRowHeight = useCallback((params) => {
    const isDetailRow = params.node.detail;
    // for all rows that are not detail rows, return nothing
    if (!isDetailRow) { return undefined; }

    // otherwise return height based on number of rows in detail grid
    const detailPanelHeight = ((params.data?.products || []).length * 52) + 48;
    return detailPanelHeight < 50 ? 150 : detailPanelHeight;
  }, []);

  return (
    <div>
      {/* <div
        onClick={() => {
          console.log('Hola');
          console.log(gridRef.current.api.getSelectedRows());
        }}
      >
        hola
      </div> */}

      <div
        className="ag-theme-alpine"
        style={{ height: 650, marginTop: 20, marginBottom: 50 }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={colDefs}
          enableCellTextSelection={true}
          rowHeight={50}
          detailCellRendererParams={detailCellRendererParams}
          masterDetail={true}
          getRowHeight={getRowHeight}
          rowSelection="multiple"
          suppressRowClickSelection={true}
          localeText={AG_GRID_LOCALE_ES}

          // checkboxSelection

          defaultColDef={defaultColDef}

          tooltipShowDelay={500}
          tooltipInteraction={true}
          

          // detailRowHeight={136}
          // onFirstDataRendered={onFirstDataRendered}
          // groupDisplayType={"groupRows"}
          // rowGroupPanelShow={"always"}
          // groupDefaultExpanded={1}

          // autoGroupColumnDef={autoGroupColumnDef}

          // sideBar={true}
          // enableCharts={true}
          // enableRangeSelection={true}
          // onGridReady={onGridReady}
        />
      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'end',
        marginBottom: 20
      }}>
      </div>
    </div>
  );
}