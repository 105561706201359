import React, { Component } from "react";
import SelectImage from "../shared/controls/select-image";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  loadSession,
  setSessionCompany,
} from "../redux/reducers/global-catalog.reducer";
import { getUrlsEnv } from "../shared/backend-api";
import { removeShoppingCart, setValidationCompanyNotified } from "../shared/utils";

class CompanySelect extends Component {

  componentDidMount() {
    removeShoppingCart()
  }

  handleClick = (id) => {
    const res = this.props.companies.filter((c) => String(c.id) === String(id));

    setValidationCompanyNotified({});
    this.props.setSessionCompany(res[0]);

    const companyData = JSON.parse(sessionStorage.getItem("orbinetwork_session")).company;
    const companyUserType = companyData.companyuser.type;
    const companyType = companyData.type.id;

    switch (companyUserType) {
      case "Warehouser":
        this.props.history.push("/facility/select");
        
        window.location.reload();
        break;

      default:

        this.props.history.push("/command");
        
        window.location.reload();
        break;
    }
  };

  render() {
    return (
      <div className="company-select">
        <img alt="" className="logo" src="/images/logo-white.svg" />
        <div className="welcome-content">
          <div className="welcome-title">¿A qué empresa deseas ingresar?</div>
          <div className="welcome-actions">
            <div className="dropdown">
              <div className="dropbtn"></div>
              <div className="dropdown-content">
                <a href="/register/company">Crear empresa</a>
                {/* <a href="#">Eliminar empresa</a> */}
              </div>
            </div>
          </div>

          <div className="companies">
            {this.props.companies.map((c, index) => {
              const { files } = getUrlsEnv();
              //const { type: { description = '' }, companyuser: { type = '' } } = c;
              // console.log(c);

              let newCompany = {
                id: c.id,
                name: c.orbi_id,
                img: c.logo
                  ? `${files}${c.logo}`
                  : c.type.avatar_image,
                verification_status: c.verification_status
                //subtitle: description,
                //role: type
              };

              return (
                <SelectImage
                  data={newCompany}
                  key={index}
                  isSelected={false}
                  handleClick={this.handleClick}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // let storage = JSON.parse(window.sessionStorage.getItem('orbinetwork_session'));

  // const {
  //     companies = storage.listCompanies || []
  // } = state;

  let companies = [];
  if(state.globalCatalog.session.listCompanies){
    if(Array.isArray(state.globalCatalog.session.listCompanies)){
      companies = state.globalCatalog.session.listCompanies;
    } else{
      companies = Object.values(state.globalCatalog.session.listCompanies);
    }
  }

  return {
    companies,
    userType: state.globalCatalog.session.company?.companyuser || 0,
  };
};

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());

  return {
    setSessionCompany: (company) => dispatch(setSessionCompany(company)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CompanySelect)
);
