import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Tag from '../../shared/controls/tag';
import { CollapsibleHeaderList } from '../shipment/styled/collapsible-header';
// import { FacilityRow } from '../shipment/styled/facility-row';
import { SummaryPanel } from '../shipment/styled/summary-panel';
import ImageWithCheckMark from '../../shared/component/image-with-check-mark';
import { getUrlsEnv } from '../../shared/backend-api';

const COLUMNS = [
  { description: "Enviadas", name: "purchase_orders_sent" },
  { description: "Confirmadas", name: "purchase_orders_confirmed" },
  { description: "Canceladas", name: "purchase_orders_canceled" },
];

const PEDIDO_COLUMNS  = [
  { description: "Pendiente", name: 1 },
  { description: "Confirmado", name: 2 },
  // { description: "Orden creada", name: "purchase_orders_canceled" },
  // { description: "Salida inventario", name: "purchase_orders_sent" },
  // { description: "Entrada inventario", name: "purchase_orders_confirmed" },
  // { description: "Cancelado", name: "purchase_orders_canceled" },
];

const ORDER_COLUMNS = [
  { description: "Pendiente", name: 1 },
  { description: "Sin reservación", name: 2 },
  { description: "Reservación pendiente", name: 3 },
  { description: "Reservación rechazada", name: 4 },
  { description: "Reservación confirmada s/asignar", name: 5 },
  { description: "Reservación confirmada asignada", name: 6 },
  // { description: "Viaje en proceso", name: "purchase_orders_sent" },
  // { description: "Viaje completado", name: "purchase_orders_confirmed" },
  { description: "Cancelada", name: 9 },
  { description: "Finalizada s/datos", name: 11 },
  // { description: "Pendiente", name: "purchase_orders_confirmed" },
];

const ORDER_COLUMNS_2 = [
  { description: "Orden creada", name: 1 },
  { description: "Orden confirmada", name: 2 },
  { description: "Reservación solicitada", name: 3 },
  { description: "Reservación rechazada", name: 4 },
  { description: "Reservación confirmada s/asignar", name: 5 },
  { description: "Reservación confirmada asignada", name: 6 },
  { description: "Viaje iniciado", name: 7 },
];

const TRIP_COLUMNS = [
  { description: "Creación de viaje sin reservación", name: 8 },
  { description: "Creación de viaje / Reservación solicitada", name: 1 },
  { description: "Reservación rechazada", name: 2 },
  { description: "Reservación confirmada sin transporte asignado", name: 3 },
  { description: "Reservación confirmada con transporte asignado", name: 4 },
  { description: "Viaje iniciado", name: 5 }
];

const TRIP_COLUMNS_TR = [
  { description: "Creación de viaje / Reservación solicitada", name: 1 },
  { description: "Reservación rechazada", name: 2 },
  { description: "Reservación confirmada sin transporte asignado", name: 3 },
  { description: "Reservación confirmada con transporte asignado", name: 4 },
  { description: "Viaje iniciado", name: 5 }
];

class OrdersSummaryList extends React.Component {
  state = {

  }

  chooseColumns = () => {
    switch(this.props.itemType){
      case 1: 
        return PEDIDO_COLUMNS;
      case 2:
        return ORDER_COLUMNS;
      case 3: 
        return ORDER_COLUMNS_2;
      case 4:
        return TRIP_COLUMNS; 
      case 5: 
        return TRIP_COLUMNS_TR;
      default:
        return []
    } 
  }

  getTransporType = () => {
    return this.props.transportType === 1 ? 'ftl' : 'ltl';
  }

  getDataIndex = () => {
    if(this.props.itemType === 5){//viajes para tr
      return `trips_${this.getTransporType()}`
    } else if(this.props.itemType === 4){//viajes
      if(this.props.transactionType === 1){//compra
        return `purchase_trips_${this.getTransporType()}`
      } else {
        return `sales_trips_${this.getTransporType()}`
      }
    } else if(this.props.itemType === 1){//pedidos
      if(this.props.transactionType === 1){//compra
        return 'purchase_requisitions'
      } else {
        return 'sales_requisitions'
      }
    } else {
      if(this.props.transactionType === 1){//compra
        return 'purchase_orders'
      } else {
        return 'sales_orders'
      }
    }
  }

  getIndexCounter = () => {
    if (this.props.transactionType === 1) {
      return this.props.itemType === 4 ? `purchase_${this.getTransporType()}`: 'purchase';
    } else if (this.props.transactionType === 2) {
      return this.props.itemType === 4 ? `sales_${this.getTransporType()}`: 'sales';
    } else if (this.props.transactionType === 4) {
      return (this.props.itemType === 4 || this.props.itemType === 5) ? `all_${this.getTransporType()}`: 'all';
    } else {
      return '';
    }
  }

  render() {
    return(
      <SummaryPanel>
        <div 
          className="resume-header"
          style={
            this.props.simpleRow ? ((this.props.ordersResume || []).length > 9 ? {marginRight: '40px'} : {}) : (this.props.ordersResume || []).length > 12 ? { marginLeft: 'calc(15% + 32px)', marginRight: '40px'} : { marginLeft: 'calc(15% + 32px)' }
          }
        >
          {/* <div>Enviadas</div>
          <div>Confirmadas</div>
          <div>Canceladas</div> */}
          {
            this.chooseColumns().map( (c, i) => (
              <div>
                <div>{c.description}</div>
                {
                  (this.props.columnCounter && this.getIndexCounter() in this.props.columnCounter && c.name in this.props.columnCounter[this.getIndexCounter()]) &&
                  <div
                    style={{fontSize: 24}}
                  >{ this.props.columnCounter[this.getIndexCounter()][c.name] }</div>
                }
              </div>
            ))
          }
        </div>
        <div style={{margin: "0 25px 25px"}}>
          <div
            style={{
              position: "relative",
              maxHeight: "500px",
              overflowY: "auto",
              // marginTop: "120px"
            }}
          >
            {
              this.props.ordersResume.map(tag => {
                if (this.props.simpleRow) {
                  return(
                    <div
                      style={{
                        display: 'flex'
                      }}
                    >
                      <div style={{
                        width: "15%",
                        fontWeight: "bold",
                        fontSize: "16px",
                        lineHeight: "25px",
                        color: "#FFFFFF",
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                        <ImageWithCheckMark
                          src={getUrlsEnv().files.concat(tag[1].logo || '')}
                          width={30}
                          isCheckMark={tag[1].verification_status === 3}
                          customStyle={{
                            width: 30, height: 30, borderRadius: '50%', position: 'relative', marginRight: 12
                          }}
                          isCo={true}
                        />
                        {tag[0]}
                      </div>
                      <div
                        style={{
                          width: "85%",
                          display: "flex",
                          fontSize: "24px",
                          fontWeight: "bold",
                          color: "#ffffff",
                          justifyContent: "space-evenly",
                          borderBottom: "2px solid #ffffff80",
                          alignItems: "center",
                          height: "50px"
                        }}
                      >
                        {(this.chooseColumns()).map((column) => (
                          <div style={{ width: "100%", textAlign: "center" }}>
                            {
                              (tag[1] && tag[1][this.getDataIndex()] && tag[1][this.getDataIndex()][column.name]) ?
                              tag[1][this.getDataIndex()][column.name] : '0'
                            }
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                }
                 
                return(
                  <CollapsibleHeaderList
                    maxExpandedHeight="auto"
                    contentStyle={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                    collapsedHeight="40px"
                    title={
                      <div style={{
                        width: "15%",
                        fontWeight: "bold",
                        fontSize: "16px",
                        lineHeight: "25px",
                        color: "#FFFFFF",
                      }}>
                        {tag[0]}
                      </div>
                    }
                    caret={{ fill: "#79A3FF", stroke: "#79A3FF" }}
                    initialStateCollapsed={true}
                    headerContent={
                      <div
                        style={{
                          width: "85%",
                          display: "flex",
                          fontSize: "24px",
                          fontWeight: "bold",
                          color: "#ffffff",
                          justifyContent: "space-evenly",
                          borderBottom: "2px solid #ffffff80",
                          alignItems: "center",
                          height: "100%"
                        }}
                      >
                        {(this.chooseColumns()).map((column) => (
                          <div style={{ width: "100%", textAlign: "center" }}>
                            { 
                              tag[1].length 
                              ? (tag[1]
                                .map((f) => {

                                  if (this.props.transactionType === 4 && this.props.itemType === 1) {
                                    let result = parseInt(f['purchase_requisitions'][column.name]) + parseInt(f['sales_requisitions'][column.name]);
                                    return result;
                                  }

                                  if (this.props.transactionType === 4 && (this.props.itemType === 2 || this.props.itemType === 3)) {
                                    let result = parseInt(f['purchase_orders'][column.name]) + parseInt(f['sales_orders'][column.name]);
                                    return result;
                                  }

                                  if (this.props.transactionType === 4 && this.props.itemType === 4) {
                                    let result = parseInt(f[`purchase_trips_${this.getTransporType()}`][column.name]) + parseInt(f[`sales_trips_${this.getTransporType()}`][column.name]);
                                    return result;
                                  }

                                  return f[this.getDataIndex()][column.name];
                                })
                                .reduce((total, value) => total + value))
                              : '-'
                            }
                            
                          </div>
                        ))}
                      </div>
                    }
                  >
                    {tag[1]
                      .sort(function(a, b) {
                        if (a.name > b.name) {
                          return 1;
                        }
                        if (a.name < b.name) {
                          return -1;
                        }
                        return 0;
                      })
                      .map((facility) => (
                        <FacilityRow
                          onClick={() => {
                            if (this.props.disabled) {
                              return;
                            }
                            this.props.history.push(
                              `/company/controls/order/detail/${facility.id}`, {
                                transactionType: this.props.transactionType && this.props.transactionType !== 4 ? this.props.transactionType : null, 
                                itemType: (this.props.itemType && this.props.itemType === 1) ? this.props.itemType : (this.props.itemType && [2,3].includes(this.props.itemType)) ? 2 : null
                              }
                            )
                          }}
                        >
                          <div style={{ width: "15%", textAlign: "right", cursor: "pointer" }}>
                            {facility.name} - {facility.alias} - {facility.code}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              width: "85%",
                              justifyContent: "space-evenly",
                              fontSize: "20px",
                              fontWeight: "bold",
                              alignItems: "center"
                            }}
                          >
                            {(this.chooseColumns()).map((column) => {
                                return <div style={{ width: "100%", textAlign: "center" }}>
                                  {facility[this.getDataIndex()][column.name]}
                                  
                                </div>
                            })}
                          </div>
                        </FacilityRow>
                      ))}
                  </CollapsibleHeaderList>
                )
              })
            }
          </div>
        </div>
      </SummaryPanel>
    )
  }
}

const mapStateToProps = (state) => {
  const companyId = state.globalCatalog.session.company.id;
  return {
    companyId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdersSummaryList);

const FacilityRow = styled.div`
  display: flex;
  color: #ffffff;
  font-family: Overpass;
  font-size: 14px;
  width: 100%;
  height: auto;
  line-height: 33px;
  overflow: hidden;
  transition: 1s ease-in;
  &:hover {
    background-color: #5c6d9a;
  }
`;