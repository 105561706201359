import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import Axios from "axios";
import { SERVER } from "../../redux/api/api-urls";
import CatalogHeader, {
  GRID,
  LIST,
} from "../../shared/component/catalog-header";
import FilterBar from "../../shared/component/filter/filter-bar";
import TabSelectorRibbon from "../../shared/controls/tab-selector-ribbon";
import FleetCardContent from "../../shared/component/fleet-card";
import CatalogSelectionFooter from "../../shared/component/catalog-selection-footer";
import FleetRow from "../../shared/component/fleet-row";
import BaseDialog from '../../shared/dialog/base-dialog';
import {
  /* loadFleetData, */ loadOperatorNormativityTypes,
  loadNormativityTypes,
  loadNormativityStatus,
  loadFleetProvider,
  loadOperatorTypes,
  loadSanctions,
  setSanctions,
  patchSanctions,
  deleteSanctions,
  loadVehicleTypes,
  loadEquipmentTypes,
  loadFleetEquipmentCatalog,
  loadFleetOperatorCatalog,
  loadFleetVehicleCatalog,
  loadFleets,
  getOperatorByLicense,
  searchFleetsByName,
  setFleetSearchStr,
  // getNormativityHistoryData,
  // getNormativityHistoryOperatorData,
  loadFleetCompanyTypes,
  postUploadFleets,
} from "../../redux/reducers/fleet-reducer";
import TagsSuggestionModal from "../../shared/dialog/tags-suggestion-modal";
import {
  loadSession,
  removeToast,
  removeFleetCatalogTabSeleted,
  setFleetCatalogTabSeleted,
  validateObjPerms,
  loadPermissionsUser
} from "../../redux/reducers/global-catalog.reducer";
import { loadTags } from "../../redux/reducers/tags.reducer";
import TextInput from "../../shared/controls/text-input";
import Button from "../../shared/controls/button";
import Selector from "../../shared/component/selector";
import CatalogCard from "../../shared/component/catalog-card";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCurrentTabSelected, isPremiumSubscriptionCo, normalizeStr, setCurrentTabSelected } from "../../shared/utils";
import ShareFleetModal from "../../shared/dialog/share-fleets-modal";
import { listNetworkApprovedInvitations } from "../../redux/reducers/company-reducer";
import ValidatePerms from "../../shared/component/validate-perms";
import { FILTER_LIST_TYPES } from "../../shared/component/filter/filter-bar-content";
import FileSelector, {
  acceptTypes,
  getFileDescription,
} from "../../shared/controls/file-selector";
import SVGIcon, { icons } from "../../shared/svg-icon";
import Chip from "../../shared/controls/chip";
import { setConfig } from "../../redux/reducers/configuration-reducer";
import PaginatorControlled from "../../shared/controls/paginator-controlled";
import { DownloadIcon } from "../routes/style";
import DownloadImage from "../../shared/icons/routes/downloadIcon";
import ReactTooltip from "react-tooltip";
import { getUrlsEnv, openSecureDoc } from "../../shared/backend-api";
import { cancelRequest, createEntity, deleteEntity, getEntityItems, readEntities, SaveItemCounter } from "../../redux/api";
import ChipContainer from "../../shared/component/chip-container";
import SelectInput from '../../shared/controls/select-input';
import UserChipContainer from '../../shared/component/user-chip-container';
import Loader from '../../shared/icons/tat/loader';
import ConfirmDialog from "../../shared/dialog/confirm-dialog";
import GenericDialog from "../../shared/dialog/generic-modal";
import styled from "styled-components";

const fleetTypes = [
  { id: 1, description: "Vehículos" },
  { id: 2, description: "Equipos" },
  { id: 3, description: "Operadores" },
];

const getWeeks = () => {
  let weeks = [];

  for (let i = 1; i < 53; i++) {
    weeks.push({id: i, description: i.toString()})
  }
  return weeks;
}

class FleetCatalogView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewMode: LIST,
      fleetTypeItem: fleetTypes[0],
      // filters: filtrosFlotillas,
      selectionModeOn: false,
      selectedItemsIds: [],
      openTagsModal: false,
      openInviteModal: false,
      openDeleteModal: false,
      openXlsModal: false,
      openXlsSuccessModal: false,
      openXlsErrorModal: false,
      openShareFleetModal: false,
      hasSearchedOperator: false,
      searchedLicence: "",
      selectedOperatorTag: 0,

      isAddSanction: false,

      weeks: getWeeks(),
      weekSanction: {id: 1, description: "1"},
      sanctionSelected: undefined,
      sanctionFromDate: '',
      sanctionToDate: '',

      operatorToLock: null,

      // filters
      tags: [],
      // documents: [],
      statuses: [],
      companies: [],
      types: [],
      gps: [],
      limit: 10,
      offset: 1,
      searchStr: "",
      // searchText: "",
      companiesCatalog: [],
      xls: null,
      loading: false,
      totalImport: 0,
      correctImport: 0,
      failedImport: 0,
      invalidImport: 0,
      duplicatedSystemImport: 0,
      duplicatedCompanyImport: 0,
      uploadIdImport: 0,
      companyIdImport: 0,
      descriptionImport: [],

      buttonFilterName: "Aplicar",

      downloadingFile: false,
      cancelTokenSource: null,
      isLoading: false,
      showUnshareModalSuccess: false,

      downloadTemplateOperatorsFocused: false,
      downloadTemplateOperatorPasswordsFocused: false,
      unshare_data: null,
      generic_message: null,
      first_called: false,
      isFreeSubscription: false
    };

    this.showToast = (text) => {
      toast.success(
        <div>
          <img
            alt=""
            src="/images/white-check.svg"
            style={{ marginRight: "8px", marginLeft: "4px" }}
          />
          {text}
        </div>,
        {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          className: "my-toast-class",
        }
      );
    };
  }

  filterContents = () => {
    let contents = [];
    if (this.props.companyType === 2) {
      contents = [
        // {
        //   title: "Etiquetas",
        //   items: this.props.tags,
        //   name: "tags",
        //   listType: FILTER_LIST_TYPES.chips,
        // },
        {
          title: "Empresas",
          // items: this.getCompaniesByType(this.state.fleetTypeItem.id),
          items: this.props.networkComaniesFilter,
          // this.props.documents,
          name: "companies",
          listType: FILTER_LIST_TYPES.checkList,
        },
        {
          title: "Estatus",
          items: this.props.normativity_status_list,
          name: "statuses",
          listType: FILTER_LIST_TYPES.checkList,
        },
      ];
    } else {
      contents = [
        {
          title: "Bases",
          items: this.props.tags,
          name: "tags",
          listType: FILTER_LIST_TYPES.chips,
        },
        {
          title: "Estatus",
          items: this.props.normativity_status_list,
          name: "statuses",
          listType: FILTER_LIST_TYPES.checkList,
        },
      ];
    }

    switch (this.state.fleetTypeItem.id) {
      case 1:
        return contents.concat(
          {
            title: "Tipo de vehículo",
            items: this.props.vehicle_types,
            name: "types",
            listType: FILTER_LIST_TYPES.checkList,
          },
          {
            title: "GPS",
            items: [
              { id: 1, description: "Sí" },
              { id: 2, description: "No" },
            ],
            name: "gps",
            listType: FILTER_LIST_TYPES.checkList,
          }
        );
      // break;
      case 2:
        return contents.concat(
          {
            title: "Tipo de equipo",
            items: this.props.equipment_types,
            name: "types",
            listType: FILTER_LIST_TYPES.checkList,
          },
          {
            title: "GPS",
            items: [
              { id: 1, description: "Sí" },
              { id: 2, description: "No" },
            ],
            name: "gps",
            listType: FILTER_LIST_TYPES.checkList,
          }
        );
      // break;
      case 3:
        return contents.concat([
          {
            title: "Tipo de operador",
            items: this.props.operator_types,
            name: "types",
            listType: FILTER_LIST_TYPES.checkList,
          },
        ]);
      // break;
      default:
        return;
    }
  };

  filtersChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ buttonFilterName: "Aplicar" });
  };

  handleSearch = (e, searchText) => {
    this.setState({ searchStr: normalizeStr(searchText), offset: 1});
    // this.props.setSearchStr(searchText);
  };

  handleFilter = () => {
    //   let selectedTags = [...this.selectedTags.values()].toString();
    //   let selectedStatus = [...this.selectedStatus.values()].toString();
    //   let selectedCompany = [...this.selectedCompany.values()].toString();
    //   let selectedType = [...this.selectedType.values()].toString();
    //   let selectedGpsStatus = [...this.selectedGpsStatus.values()].toString();
    if(this.state.buttonFilterName === "Aplicar"){
      // Cancel request
      if(this.state.cancelTokenSource !== null){
        this.state.cancelTokenSource.cancel();
      }
      this.setState(
        {
          buttonFilterName: "Restablecer",
          downloadingFile: false
        },
        () => { this.loadCatalogItems(); }
      );
    } else {
      this.setState({ buttonFilterName: "Aplicar" }, () => this.handlerClean() );
    }
  };

  handlerClean = () => {
    this.setState({
      statuses: [],
      companies: [],
      types: [],
      gps: [],
      tags: [],
    }, () => this.loadCatalogItems() );
  }

  cleanList = (data) => {
    return Object.values(
      data.reduce((row, col) => {
        row[col.id] = col;
        return row;
      }, {})
    );
  };

  getCompaniesByType = (typeId) => {
    let companies = [];
    // let data = [];
    // switch (typeId) {
    //   case 1:
    //     data = this.state.vehiclesAll || [];
    //     break;
    //   case 2:
    //     data = this.state.equipmentsAll || [];
    //     break;
    //   case 3:
    //     data = this.props.operatorsAll || [];
    //     break;
    //   default:
    //     break;
    // }

    companies = (this.state.companiesCatalog || []).map((item) => {
      return {
        id: item.id,
        visible: true,
        description: item.orbi_id,
      };
    });
    // return this.cleanList(companies);
    return companies;
  };

  loadCatalogItems = () => {
    this.props.cancelRequest("COMPANIES.FLEETS.CATALOG.EQUIPMENTS");
    this.props.cancelRequest("COMPANIES.FLEETS.CATALOG.VEHICLES");
    this.props.cancelRequest("COMPANIES.FLEETS.CATALOG.OPERATORS");
    this.props.cancelRequest("COMPANIES.FLEETS.CATALOG.STATUSLOG");
    let filters = {
      tags: this.state.tags,
      status: this.state.statuses,
      companies: this.state.companies,
      type: this.state.types,
      gps: this.state.gps,
      text: this.state.searchStr,
    };
    this.setState({isLoading: true, first_called: true})
    switch (this.state.fleetTypeItem.id) {
      case 1:
        // this.props.loadVehicleCatalog(
        //   this.props.companyId,
        //   1,
        //   this.state.limit,
        //   this.state.offset,
        //   filters
        // );
        this.props.loadVehicleCatalog({
          company_id: this.props.companyId,
          type_id: 1,
          limit: this.state.limit,
          page: this.state.offset,
          ...filters
        }, {
          onSuccess: () => {
            this.setState({isLoading: false})
          }
        });
        this.props.loadStatusLog({
          company_id: this.props.companyId,
          type_id: 1,
          limit: this.state.limit,
          page: this.state.offset,
          ...filters
        })
        break;
      case 2:
        // this.props.loadEquipmentCatalog(
        //   this.props.companyId,
        //   2,
        //   this.state.limit,
        //   this.state.offset,
        //   filters
        // );
        this.props.loadEquipmentCatalog({
          company_id: this.props.companyId,
          type_id: 2,
          limit: this.state.limit,
          page: this.state.offset,
          ...filters
        }, {
          onSuccess: () => {
            this.setState({isLoading: false})
          }
        })
        this.props.loadStatusLog({
          company_id: this.props.companyId,
          type_id: 2,
          limit: this.state.limit,
          page: this.state.offset,
          ...filters
        })
        break;
      case 3:

        if (this.props.companyType === 1) {

          this.props.loadOperatorCatalogV2({
            company_id: this.props.companyId,
            limit: this.state.limit,
            page: this.state.offset,
            fleet_id: (this.props.companyFleet && this.props.companyFleet.length) ? this.props.companyFleet[0].id : 0,
            view: '',
            ...filters
          }, {
            alias: "operators_info",
            onSuccess: () => {
              this.setState({isLoading: false})
            },
            onError: (e) => 
              this.setState({isLoading: false, generic_message: {
                title: 'Error!',
                message: 'Ocurrió un error, intente de nuevo'
              }})
          });

          this.props.loadOperatorCatalogV2({
            company_id: this.props.companyId,
            limit: this.state.limit,
            page: this.state.offset,
            fleet_id: (this.props.companyFleet && this.props.companyFleet.length) ? this.props.companyFleet[0].id : 0,
            view: 'action_logs',
            ...filters
          }, {
            alias: "operators_action_logs",
          });

        } else {

          this.props.loadOperatorCatalogCOV2({
            company_id: this.props.companyId,
            limit: this.state.limit,
            page: this.state.offset,
            view: '',
            ...filters
          }, {
            alias: "operators_info",
            onSuccess: () => {
              this.setState({isLoading: false})
            },
            onError: (e) => 
              this.setState({isLoading: false, generic_message: {
                title: 'Error!',
                message: 'Ocurrió un error, intente de nuevo'
              }})
          });

          this.props.loadOperatorCatalogCOV2({
            company_id: this.props.companyId,
            limit: this.state.limit,
            page: this.state.offset,
            view: 'action_logs',
            ...filters
          }, {
            alias: "operators_action_logs",
          });

        }
        break;
      default:
    }
  };

  componentDidMount() {

    if (this.props.isCO && !this.props.isPremiumSubscriptionCo) {
      this.setState({isFreeSubscription: true});
      return;
    }

    document.addEventListener("keydown", this.cancelUpdate);
    document.addEventListener('mousedown', this.handlePageContentScroll, false);
    document.addEventListener('page-content-scroll', this.handlePageContentScroll);

    this.setState({ ...this.props.configuration.base }, () =>
      // fletItemTypes = {};
      this.props
        .getLoadFleetCompanyTypes(this.state.fleetTypeItem.id)
        .then((companies) => {
          if (companies)
            this.setState({
              companiesCatalog: companies.response,
            });
        })
    );

    this.props.loadFleets().then((result) => {
      this.loadCatalogItems();
    });

  }

  cancelUpdate = (e) => {
    if(e.key === "Escape"){
      this.setState({
        isLoading: false
      }, () => {
        this.props.cancelRequest("COMPANIES.FLEETS.CATALOG.EQUIPMENTS");
        this.props.cancelRequest("COMPANIES.FLEETS.CATALOG.OPERATORS");
        this.props.cancelRequest("COMPANIES.FLEETS.CATALOG.VEHICLES");  
        this.props.cancelRequest("COMPANIES.FLEETS.CATALOG.STATUSLOG");  
      });
    }
  }


  componentWillUnmount() {
    this.state.selectionModeOn = false;
    this.props.setBaseConfig({ ...this.state });
    this.props.setConfig(this.state.fleetTypeItem.id, { ...this.state });

    // Cancel request
    if(this.state.cancelTokenSource !== null){
      this.state.cancelTokenSource.cancel();
    }

    document.removeEventListener('keydown', this.cancelUpdate, true);
    document.removeEventListener('page-content-scroll', this.handlePageContentScroll);
    document.removeEventListener('mousedown', this.handlePageContentScroll, false);
  }

  handlePageContentScroll = (e) => {
    if (e && e.target && e.target.id && (e.target.id.includes('download-template-operators') || e.target.id.includes('download-template-passwords') || e.target.id.includes('download-icon') || e.target.id.includes('unshare-operator-action'))) {
      return;
    }
    ReactTooltip.hide();
  }

  componentDidUpdate() {
    if (this.props.toast) {
      this.props.removeToast();
      this.showToast(this.props.toast.text);
    }
    
    let tab_selected = `${getCurrentTabSelected()}`;

    if (tab_selected) {
      switch (tab_selected) {
        case "2":
          setCurrentTabSelected(null);
          this.switchFleetType({
            target: {
              attributes: { name: { value: tab_selected } },
            },
          });

          break;

        case "3":
          setCurrentTabSelected(null);
          this.switchFleetType({
            target: {
              attributes: { name: { value: tab_selected } },
            },
          });

          break;

        default:
          break;
      }
    }
  }

  toggleView = (viewMode) => {
    this.setState({ viewMode: viewMode });
  };
  toggleSelectMode = () => {
    this.setState({
      selectionModeOn: !this.state.selectionModeOn,
      selectedItemsIds: [],
    });
  };
  switchFleetType = (e) => {
    if (e.target.attributes["name"]) {
      let ftId = e.target.attributes["name"].value;
      let type = fleetTypes.find((ft) => ft.id.toString() === ftId);
      this.props.setConfig(this.state.fleetTypeItem.id, { ...this.state, selectedItemsIds: [], selectionModeOn: false });
      this.setState(
        {
          ...this.props.configuration[type.id],
          fleetTypeItem: type,
          selectedItemsIds: [], 
          selectionModeOn: false,
          searchStr: ''
        },
        this.loadCatalogItems
      );

      this.props.getLoadFleetCompanyTypes(type.id).then((companies) => {
        this.setState({
          companiesCatalog: companies.response,
        });
      });
    }

    // Cancel request
    if(this.state.cancelTokenSource !== null){
      this.state.cancelTokenSource.cancel();
    }
  };

  switchFleetType2 = (e) => {
    if (e.target.attributes["name"]) {
      let ftId = e.target.attributes["name"].value;
      let type = fleetTypes.find((ft) => ft.id.toString() === ftId);

      console.log('::::::');
      console.log(type);
      console.log(this.state.fleetTypeItem.id);
      console.log('::::::');

      this.props.setConfig(this.state.fleetTypeItem.id, { ...this.state, selectedItemsIds: [], selectionModeOn: false });

      // this.setState(
      //   {
      //     ...this.props.configuration[type.id],
      //     fleetTypeItem: type,
      //     selectedItemsIds: [], 
      //     selectionModeOn: false,
      //     searchStr: ''
      //   },
      //   this.loadCatalogItems
      // );

      // this.props.getLoadFleetCompanyTypes(type.id).then((companies) => {
      //   this.setState({
      //     companiesCatalog: companies.response,
      //   });
      // });
    }

    // Cancel request
    if(this.state.cancelTokenSource !== null){
      this.state.cancelTokenSource.cancel();
    }
  };

  openDeleteModal = () => {
    //Agregar contenido de eliminar
    this.setState({
      openDeleteModal: true,
    })
  };

  openUnlinkModal = () => {
    //Agregar contenido de eliminar
    this.setState({
      openUnlinkModal: true,
    })
  };

  openSanctionModal = (e, option) => {
    switch (option) {
      case "add":
        this.setState({openSanctionModal: true, operatorToLock: e, isAddSanction: true})
        break;
      case "edit":
        if (e.sanctions.length) {
          this.setState({
            openSanctionModal: true, 
            operatorToLock: e, 
            isAddSanction: false,
          
            weekSanction: {id: e.sanctions[0].incidence_week, description: e.sanctions[0].incidence_week.toString()},
            sanctionSelected: this.props.sanctionsCatalog.find(s => s.id === e.sanctions[0].lock_motive),
            sanctionFromDate: new Date(e.sanctions[0].locked_from), 
            sanctionToDate: new Date(e.sanctions[0].locked_to),
  
          })
        }
        break;
      case "del":
        this.setState({openDelSanctionModal: true, operatorToLock: e})
        break;
      default:
        break;
    }
  };
  openTagsModal = () => {
    this.setState({ openTagsModal: true });
  };
  openShareFleetModal = () => {
    this.setState({ openShareFleetModal: true });
  };
  openInviteModal = () => {
    this.setState({ openInviteModal: true });
  };
  closeDeleteModal = () => {
    this.setState({
      openDeleteModal: false,
    })
  }
  closeInviteModal = () => {
    this.setState({
      openInviteModal: false,
      hasSearchedOperator: false,
      searchedLicence: "",
    });
  };
  openXlsModal = () => {
    this.setState({ openXlsModal: true });
  };
  closeXlsModal = () => {
    if (!this.state.loading) {
      this.setState({
        openXlsModal: false,
        xls: null,
        loading: false,
      });
    }
  };

  openXlsSuccessModal = (data) => {
    this.setState({
      openXlsModal: false,
      xls: null,
      loading: false,
      openXlsSuccessModal: true,
      totalImport: data.total,
      correctImport: data.correct,
      failedImport: data.failed,
      invalidImport: data.invalid,
      duplicatedSystemImport: data.system_duplicated,
      duplicatedCompanyImport: data.company_duplicated,
      uploadIdImport: data.upload_id,
      companyIdImport: data.company_id,
    });

    try {
      if ((data.company_duplicated + data.system_duplicated + data.invalid) > 0) {
        const win = window.open(
          `${SERVER}/api/v2/companies/${data.company_id}/upload/${data.upload_id}/`,
          "_blank"
        );
        if (win != null) {
          win.focus();
        }
      }
    } catch(error) {
      console.log('Error validando errores', error);
    }

  };

  closeXlsSuccessModal = () => {
    this.loadCatalogItems();
    this.setState({
      openXlsSuccessModal: false,
      totalImport: 0,
      correctImport: 0,
      failedImport: 0,
      invalidImport: 0,
      duplicatedSystemImport: 0,
      duplicatedCompanyImport: 0,
      uploadIdImport: 0,
      companyIdImport: 0,
    });
  };

  openXlsErrorModal = (data) => {
    let descriptionImport;
    if (Array.from(data).length > 0) {
      descriptionImport = [data.description];
    } else {
      descriptionImport = ["Ocurrió un error favor de llamar a soporte."];
    }
    this.setState({
      openXlsModal: false,
      xls: null,
      loading: false,
      openXlsErrorModal: true,
      descriptionImport,
    });
  };

  closeXlsErrorModal = () => {
    this.setState({
      openXlsErrorModal: false,
      totalImport: 0,
      correctImport: 0,
      failedImport: 0,
      invalidImport: 0,
      duplicatedSystemImport: 0,
      duplicatedCompanyImport: 0,
      uploadIdImport: 0,
      companyIdImport: 0,
      descriptionImport: [],
    });
  };

  handleLicence = (e) => {
    this.setState({ searchedLicence: e.target.value });
  };
  getOperatorByLicense = () => {
    this.setState({ hasSearchedOperator: true, selectedOperatorTag: 0 });
    this.props.getOperatorByLicense(
      this.props.companyFleet[0].id,
      this.state.searchedLicence
    );
  };

  getToolTipContent = (item) => {
    let fleetId = this.props.companyFleet && this.props.companyFleet[0].id;
    const { shared = 0 } = item;
    // const pageSelected = this.state.fleetTypeItem.id;

    // let typeToRequest = 2;

    // if (String(shared) === "-1") {
    //   typeToRequest = pageSelected === 1 ? 0 : 1;
    // }
    item.content =
      (this.props.catalogStatusLog || [])
      // (this.props.catalogStatusLog[1] || [])
        // .concat(this.props.catalogStatusLog[2] || [])
        .filter(
          (i) =>
            i.source.fleet_data_id === item.fleet_data_id &&
            (i.source.fleet_id === fleetId || shared < 0)
        ) || [];
    // this.props
    //   .getNormativityHistory(fleetId, item.fleet_data_id, typeToRequest)
    //   .then(e => (item.content = e));
  };

  getToolTipOperatorContent = (item) => {
    item.content =
      (this.props.operatoStatusLog || []).filter((i) => {
        return (
          item.norm_ids.includes(i.object_id?.id || 0)
        );
      }) || [];
  };

  getItems = () => {
    switch (this.state.fleetTypeItem.id) {
      case 1:
        let vI = this.props.vehicleItems
          // .filter(
          //   f =>
          //     normalizeStr(f.brand).includes(this.state.searchText) ||
          //     normalizeStr(f.economic_number).includes(this.state.searchText) ||
          //     normalizeStr(f.plates).includes(this.state.searchText) ||
          //     normalizeStr(f.tags.map(tag => tag.title).join()).includes(
          //       this.state.searchText
          //     )
          // )
          .map((item) => {
            this.getToolTipContent(item);
            return item;
            // item.fleet_id =
          });
        return vI;
      case 2:
        let eI = this.props.equipmentItems
          // .filter(
          //   f =>
          //     normalizeStr(f.brand).includes(this.state.searchText) ||
          //     normalizeStr(f.economic_number).includes(this.state.searchText) ||
          //     normalizeStr(f.plates).includes(this.state.searchText) ||
          //     normalizeStr(f.tags.map(tag => tag.title).join()).includes(
          //       this.state.searchText
          //     )
          // )
          .map((item) => {
            this.getToolTipContent(item);
            return item;
            // item.fleet_id =
          });
        return eI;
      default:
        let oI = this.props.operatorItems
          // .filter(
          //   f =>
          //     normalizeStr(f.name).includes(this.state.searchText) ||
          //     normalizeStr(f.tags.map(tag => tag.title).join()).includes(
          //       this.state.searchText
          //     )
          // )
          .map((item) => {
            this.getToolTipOperatorContent(item);
            return item;
            // item.fleet_id =
          });
        return oI;
    }
  };

  redirectToProfile = (fleetItemId) => {
    if (!this.state.selectionModeOn) {
      switch (this.state.fleetTypeItem.id) {
        case 1: //vehicle
          this.props.history.push(
            `/company/fleet/vehicle/profile/${
              fleetItemId.fleet_data_id
            }/?isShared=${
              String(fleetItemId.shared) === "-1"
                ? `1&fleets=${fleetItemId.fleetId}`
                : 0
            }`
          );
          break;
        case 2: //equipment
          setCurrentTabSelected("2");
          this.props.history.push(
            `/company/fleet/equipment/profile/${
              fleetItemId.fleet_data_id
            }/?isShared=${
              String(fleetItemId.shared) === "-1"
                ? `1&fleets=${fleetItemId.fleetId}`
                : 0
            }`
          );
          break;
        default:
          // || 3 operator
          setCurrentTabSelected("3");
          this.props.history.push(
            `/company/fleet/operator/profile/${
              fleetItemId.user_id
            }/?isShared=${
              String(fleetItemId.shared) === "-1"
                ? `1&fleets=${fleetItemId.fleetId}`
                : 0
            }`
          );
          break;
      }
    }
  };

  selectItem = (e) => {
    let selectedItemsIds = this.state.selectedItemsIds;
    if (e.target.value) {
      selectedItemsIds.push(e.target.name);
    } else {
      selectedItemsIds.splice(
        selectedItemsIds.findIndex((f) => f === e.target.name),
        1
      );
    }
    this.setState({ selectedItemsIds: selectedItemsIds });
  };

  selectAllItems = () => {
    if (this.getItems().length === this.state.selectedItemsIds.length) {
      this.setState({ selectedItemsIds: [] });
    } else {
      let selectedItemsIds = this.getItems().map((item) => {
        // selectedItemsIds.push(item.id);
        return item.id;
      });
      this.setState({ selectedItemsIds: selectedItemsIds });
    }
  };

  createFleetItem = () => {
    switch (this.state.fleetTypeItem.id) {
      case 1:
        this.props.history.push("/company/fleet/vehicle");
        break;
      case 2:
        setCurrentTabSelected("2");
        this.props.history.push("/company/fleet/equipment");
        break;
      default:
        setCurrentTabSelected("3");
        this.props.history.push("/company/fleet/operator");
    }
  };

  // reloadItems = (maxResults = defaultPaginatorItems, page = 1) => {
  //   let filters = {
  //     tags: this.state.tags,
  //     status: this.state.statuses,
  //     companies: this.state.companies,
  //     type: this.state.types,
  //     gps: this.state.gps,
  //     text: this.state.searchStr,
  //   };

  //   if (this.state.fleetTypeItem.id === 1)
  //     this.props.loadVehicleCatalog(
  //       this.props.companyId,
  //       this.state.fleetTypeItem.id,
  //       maxResults,
  //       page,
  //       filters
  //     );

  //   if (this.state.fleetTypeItem.id === 2)
  //     this.props.loadEquipmentCatalog(
  //       this.props.companyId,
  //       this.state.fleetTypeItem.id,
  //       maxResults,
  //       page,
  //       filters
  //     );

  //   if (this.state.fleetTypeItem.id === 3)
  //     this.props.loadOperatorCatalog(
  //       this.props.companyId,
  //       this.state.fleetTypeItem.id,
  //       maxResults,
  //       page,
  //       filters
  //     );
  // };

  getTotalElements = () => {
    if (this.state.fleetTypeItem.id === 1) return this.props.vehicleItemsCount;
    if (this.state.fleetTypeItem.id === 2)
      return this.props.equipmentItemsCount;
    if (this.state.fleetTypeItem.id === 3) return this.props.operatorItemsCount;
  };

  validShow = (perm) => {
    let response = this.props.validateObjPerms([perm], {
      display: true,
    });
    if (Object.keys(response).length) {
      return true;
    }
    return false;
  };

  handleAddDocument = (e) => {
    this.setState({ xls: e.target.value });
  };

  handleRemoveDocument = () => {
    if (!this.state.loading) {
      this.setState({ xls: null });
    }
  };

  importXls = () => {
    this.setState({ loading: true });
    postUploadFleets(this.state.xls, this.state.fleetTypeItem.id)
      .then((data) => {
        if (!('status' in data)) {
          this.validateError(data);
          return;
        }

        if (data.status !== "error") {
          this.openXlsSuccessModal(data);
        } else {
          this.openXlsErrorModal(data);
        }
      })
      .catch((error) => {
        this.openXlsErrorModal(error);
      });
  };

  validateError = (datas) => {
    let error_message = '';

    try {
      let errors = [];

      datas.map(data => {
        
        for (const [key, value] of Object.entries(data)) {
          if (Array.isArray(value.errors)) {
            errors.push(`${key}: ${value.errors.join(', ')}`);
          }
        }

      })

      error_message = <div>{
        errors.map( item => <p>{item}</p>)
      }</div>;
    } catch(error) {
      error_message = 'Ocurrió un error, verifique los datos de la plantilla';
    }

    this.setState({
      generic_message: {
        title: '¡Error!',
        message: error_message
      },
      openXlsModal: false,
      xls: null,
      loading: false
    });

  }

  getDocumentUrl = () => {
    switch (this.state.fleetTypeItem.id) {
      case 2:
        return {
          url: this.isMexicoAccount() ? "media/files/Cargador_Equipos_MX_V10012024.xlsx" : "media/files/Cargador_Equipos_V10012024.xlsx",
          description: "equipos",
        };
      case 3:
        return {
          url: this.isMexicoAccount() ? "media/files/Cargador_Operadores_MX_V23102024.xlsx" : "media/files/Cargador_Operadores_V10012024.xlsx",
          description: "operadores",
        };

      default:
        return {
          url: this.isMexicoAccount() ? "media/files/Cargador_Vehiculos_MX_V10012024.xlsx" : "media/files/Cargador_Vehiculos_V10012024.xlsx",
          description: "vehiculos",
        };
    }
  };

  getDocumentPasswordsUrl = () => {
    return {
      url: "media/files/Cargador_Operadores_contrasenas_V18092023.xlsx",
      description: "operadores_contraseñas",
    };
  };

  getToken = () => {
    return {
      Authorization:
        "Bearer " +
        (JSON.parse(sessionStorage.getItem("orbinetwork_session")) || {})
          .access,
    };
  };

  getUrl = () => {

    if (this.state.fleetTypeItem.id === 3) {
      if (this.props.companyType === 1) {
        return `${SERVER}/api/v2/companies/${this.props.companyId}/fleets/${this.props.companyId}/catalog-operators-v2/?page=${this.state.offset}&limit=${this.state.limit}&tags=${this.state.tags}&status=${this.state.statuses}&companies=${this.state.companies}&type=${this.state.types}&gps=${this.state.gps}&text=${this.state.searchStr}&view=download_xls`
      } else {
        return`${SERVER}/api/v2/companies/${this.props.companyId}/fleets/fleet-catalog-operators-v2/?page=${this.state.offset}&limit=${this.state.limit}&tags=${this.state.tags}&status=${this.state.statuses}&companies=${this.state.companies}&type=${this.state.types}&gps=${this.state.gps}&text=${this.state.searchStr}&view=download_xls`
      }

    } else {
      return `${SERVER}/api/v2/companies/${this.props.companyId}/fleets/catalog/${this.state.fleetTypeItem.id}/?page=${this.state.offset}&limit=${this.state.limit}&tags=${this.state.tags}&status=${this.state.statuses}&companies=${this.state.companies}&type=${this.state.types}&gps=${this.state.gps}&text=${this.state.searchStr}&action=download`;
    }

  };

  downloadDocument = (url) => {
    this.setState({ 
      downloadingFile: true, 
      cancelTokenSource: Axios.CancelToken.source() 
    }, () => {
      Axios({
        url: url,
        method: "GET",
        responseType: "blob",
        cancelToken: this.state.cancelTokenSource.token,
        headers: this.getToken(),
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Flotilla.xlsx");
        document.body.appendChild(link);
        link.click();
        this.setState({ downloadingFile: false })
      });
    })
  };

  openDocument = (url) => {
    openSecureDoc(getUrlsEnv().files.concat(url)).then((response) => {
      var array = url.split("/");
      var lastsegment = array.length > 0 ? array[array.length - 1] : ".";

      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);

        let a = document.createElement("a");
        a.href = url;
        a.download = lastsegment;
        a.click();
      });
    });
  };

  inviteOperator = () => {
    this.props.inviteOperator({
      company_id: this.props.companyId,
      fleet_id: this.props.companyFleet[0]?.id,
      operator_id: this.props.operatorByLicense?.id,
      license: this.state.searchedLicence,
      mobile_user: this.props.operatorByLicense?.user,
      tag: this.state.selectedOperatorTag || null
    }, {
      onSuccess: (() => {this.closeInviteModal(); this.loadCatalogItems();}),
      onError: (e) => {let err = e.response?.data?.error; this.closeInviteModal(); this.setState({openXlsErrorModal: true, descriptionImport: [err]})}
    });
  }

  fleetTagsToChips = () => {
    return this.props.tags.map((tag) => ({
      id: tag.id,
      title: tag.title,
      color: tag.color,
      isFull: (this.state.selectedOperatorTag === tag.id),
      
    }));
  };

  handleTagChange = (e) => {
    // let st = this.state.fleetTags.filter((ft) => ft !== e.target.id);
    if (!e.target.value) {
      // this.handleInput({ target: { name: "fleetTags", value: [e.target.id] } });
      this.setState({selectedOperatorTag: e.target.id});
    } else {
      // this.handleInput({ target: { name: "fleetTags", value: st } });
      this.setState({selectedOperatorTag: 0});
    }
  };

  getAvatarUrl = (route) => {
    if (route) {
      return getUrlsEnv().files.concat(route);
    }
    return "/images/Artboard.svg";
  };

  handleDelete = () => {

    if(!this.state.selectedItemsIds.length) this.closeDeleteModal();

    this.setState({isLoading: true});

    const counter = new SaveItemCounter(() => {
      this.loadCatalogItems();
      this.setState({ openDeleteModal: false, selectedItemsIds: [], selectionModeOn: false, isLoading: false });
      if(this.state.descriptionImport.length) {
        this.setState({openXlsErrorModal: true});
      }
    });

    if(this.state.fleetTypeItem.id === 1 || this.state.fleetTypeItem.id === 2) {

      let itemsIds = this.state.selectedItemsIds;

      this.state.selectedItemsIds.forEach( sI => {
        counter.add();
      });

      const new_task = (items) => {

        if (items.length === 0) {
          return;
        }

        let sI = items.pop();
        let params = sI.split('-');

        this.props.deleteFleetData({
          company_id: this.props.companyId,
          fleet_id: params[0],
          fleet_data_id: params[1]
        },
        {
          onSuccess: () => {
            counter.done();
            new_task(items)
          },
          onError: (e) => {
            let err = e.response?.data?.errors || ''; 
            this.state.descriptionImport = this.state.descriptionImport.concat(err);

            counter.done();
            new_task(items)
          }
        })
      } 

      new_task(itemsIds);

    } else {
      
      let itemsIds = this.state.selectedItemsIds;

      this.state.selectedItemsIds.forEach( sI => {
        counter.add();
      });

      const new_task = (items) => {

        if (items.length === 0) {
          return;
        }

        let sI = items.pop();
        let params = sI.split('-');

        this.props.deleteOperator({
          company_id: this.props.companyId,
          fleet_id: params[0],
          operator_id: params[1],
        },
        {
          onSuccess: () => {
            counter.done();
            new_task(items)
          },
          onError: (e) => {
            let err = e.response?.data?.errors || ''; 
            this.state.descriptionImport = this.state.descriptionImport.concat(err);

            counter.done();
            new_task(items)
          }
        })
      } 

      new_task(itemsIds);
    }
  }

  getSanction = (sanctionId) => {
    var sanctionToReturn = undefined;

    for(var i = 0; i < this.props.sanctionsCatalog.length; i++) {

      const { id = '' } = this.props.sanctionsCatalog[i];
      if (`${id}` === `${sanctionId}`) {
        sanctionToReturn = this.props.sanctionsCatalog[i];
      }
    }

    return sanctionToReturn;
  }

  isMexicoAccount = () => {
    return this.props.country && this.props.country === 'México';
  }

  closeUnshareOperator = () => {
    this.props.cancelRequest("COMPANIES.FLEETS.DATA.OPERATORS.UNSHARE");
    
    this.setState({
      unshare_data: null
    });
  }

  unshareOperatorAction = () => {

    if (this.state.fleetTypeItem.id === 1) {

      this.props.unshareVehicle({
        company_id: this.props.companyId,
        fleet_provider: this.state.unshare_data.fleet_provider_id,
        vehicle_id: this.state.unshare_data.data_id
      }, {
        onSuccess: () => this.setState({unshare_data: null, generic_message: {
          title: '¡Listo!',
          message: 'Se realizó con éxito la operación'
        }}, () => this.loadCatalogItems()),
        onError: () => this.setState({unshare_data: null, generic_message: {
          title: 'Error!',
          message: 'Ocurrió un error, intente de nuevo'
        }})
      })

    } else if (this.state.fleetTypeItem.id === 2) {

      this.props.unshareEquipment({
        company_id: this.props.companyId,
        fleet_provider: this.state.unshare_data.fleet_provider_id,
        equipment_id: this.state.unshare_data.data_id
      }, {
        onSuccess: () => this.setState({unshare_data: null, generic_message: {
          title: '¡Listo!',
          message: 'Se realizó con éxito la operación'
        }}, () => this.loadCatalogItems()),
        onError: () => this.setState({unshare_data: null, generic_message: {
          title: 'Error!',
          message: 'Ocurrió un error, intente de nuevo'
        }})
      })

    } else if (this.state.fleetTypeItem.id === 3) {

      this.props.unshareOperator({
        company_id: this.props.companyId,
        fleet_provider: this.state.unshare_data.fleet_provider_id,
        operator_id: this.state.unshare_data.data_id
      }, {
        onSuccess: () => this.setState({unshare_data: null, generic_message: {
          title: '¡Listo!',
          message: 'Se realizó con éxito la operación'
        }}, () => this.loadCatalogItems()),
        onError: () => this.setState({unshare_data: null, generic_message: {
          title: 'Error!',
          message: 'Ocurrió un error, intente de nuevo'
        }})
      })

    } else {
      this.setState({unshare_data: null, generic_message: {
        title: 'Error!',
        message: 'Ocurrió un error, intente de nuevo'
      }})
    }
  }

  buildDownloadOptions = (file) => {

    if (this.props.companyType === 1) {

      return(
        <>
          {/* tooltip cuando es operador */}
          <div id='download_section_operator' style={{display: this.state.fleetTypeItem.id === 3 ? 'block' : 'none'}}> 
            <DownloadIcon
              data-tip
              data-for={"operators-templeta-icon"}
              onMouseEnter={(e) => {
                ReactTooltip.hide();
                ReactTooltip.show(e.currentTarget);
              }}
              style={{
                cursor: 'default'
              }}
            >
              <DownloadImage
                fill={"#FFFFFF"}
                width={"50px"}
                height={"50px"}
              />
            </DownloadIcon>
            <ReactTooltip
              id={"operators-templeta-icon"}
              delayHide={20}
              delayShow={20}
              delayUpdate={200}
              place={"bottom"}
              border={true}
              type={"light"}
              effect="solid"
              clickable={true}
              event={'click'}
              eventOff={'click'}
            >
              <div>
                <div 
                  id={"download-template-operators"} 
                  style={{cursor: 'pointer', fontSize: this.state.downloadTemplateOperatorsFocused ? 14 : 13}} 
                  onMouseEnter={() => this.setState({downloadTemplateOperatorsFocused: true})} 
                  onMouseLeave={() => this.setState({downloadTemplateOperatorsFocused: false})}   
                  onClick={(e) => {
                    ReactTooltip.hide();
                    e.stopPropagation(); 
                    this.openDocument(file);
                  }}
                >
                  {`Descargar template p/ crear o editar operadores`}
                </div>
                <div 
                  id={"download-template-passwords"} 
                  style={{cursor: 'pointer', fontSize: this.state.downloadTemplateOperatorPasswordsFocused ? 14 : 13}} 
                  onMouseEnter={() => this.setState({downloadTemplateOperatorPasswordsFocused: true})} 
                  onMouseLeave={() => this.setState({downloadTemplateOperatorPasswordsFocused: false})}   
                  onClick={(e) => {
                    ReactTooltip.hide();
                    e.stopPropagation(); 
                    this.openDocument(this.getDocumentPasswordsUrl().url);
                  }}
                >
                  {`Descargar template p/ editar contraseñas de operadores`}
                </div>
              </div>
              
            </ReactTooltip>
          </div>

          {/* tooltip cuando es vehiculo o equipo */}
          
          <div id='download_section_vehicle_equipment' style={{display: this.state.fleetTypeItem.id === 3 ? 'none' : 'block'}}> 
            <DownloadIcon data-tip data-for={"download-icon"}>
                <DownloadImage
                  fill={"#FFFFFF"}
                  width={"50px"}
                  height={"50px"}
                  onClick={(e) => {e.stopPropagation(); this.openDocument(file)}}
                />
            </DownloadIcon>
            <ReactTooltip
              id={"download-icon"}
              delayHide={50}
              delayShow={50}
              delayUpdate={200}
              place={"bottom"}
              border={true}
              type={"light"}
              effect="solid"
              clickable={false}
              event={'mouseenter'}
              eventOff={'mouseleave'}
            >
              {`Descargar ${this.getDocumentUrl().description} template xlsx`}
            </ReactTooltip>
          </div>
        </>
      )
    }

    return "";
  }

  getFreeSubscription = () => {
    return(
      <NotAllowedContent>
        {'La cuenta no tiene acceso a este modulo'}
      </NotAllowedContent>
    );
  }

  getMainContent() {
    let file = this.getDocumentUrl().url;
    let permission = "";
    if (this.state.fleetTypeItem.description === 'Operadores')
      permission = "58";
    else
      permission = "35";

    let showLockMenu = this.props.permissions["38"] && this.props.permissions["38"].u && this.props.companyType === 2;

    let minDate = new Date();
    minDate.setDate(minDate.getDate - 1);

    return (
      <div className="fleet-catalog-view catalog view">
        {
          this.state.isLoading && <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              backgroundColor: "#00000080",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1001,
              backdropFilter: "blur(3px)"
            }}
          >
            <div style={{display: "flex", alignItems: "center"}}>
              <Loader width="100px" height="100px"/>
              <div style={{color: "#fff", fontSize: "36px"}}>
                Cargando
              </div>
            </div>
            <div style={{color: "#fff", fontSize: "30px"}}>
              Si desea cancelar la consulta, presione ESC
            </div>
          </div>
        }

        <ToastContainer className="global-toast-container" />
        <ValidatePerms
          perms={[
            {
              id: permission,
              perm: "c",
            },
          ]}
          optionalElement={
            <CatalogHeader
              title="Flotillas"
              viewMode={this.state.viewMode}
              toggleView={this.toggleView}
            />
          }
        >
          <CatalogHeader
            title="Flotillas"
            viewMode={this.state.viewMode}
            toggleView={this.toggleView}
            actionButtons={[
              {
                text: "Subir xls",
                onClick: this.openXlsModal,
                type: "primary outline",
                className: this.props.companyType === 1 ? "" : "hidden",
              },
              {
                text: "Invitar",
                onClick: this.openInviteModal,
                type: "primary outline",
                className: (this.props.companyType === 1 && this.state.fleetTypeItem.id === 3 )? "" : "hidden",
              },
              {
                text: "Agregar ".concat(this.state.fleetTypeItem.description),
                onClick: this.createFleetItem,
                type: "primary fit-width",
                className: this.props.companyType === 1 ? "" : "hidden",
              },
            ]}
          >
            { this.buildDownloadOptions(file) }
          </CatalogHeader>
        </ValidatePerms>

        <TabSelectorRibbon
          items={fleetTypes}
          onChange={this.switchFleetType}
          activeId={this.state.fleetTypeItem.id}
        />
        <FilterBar
          filterAction={this.handleFilter}
          searchStr={this.state.searchStr}
          openDocument={this.downloadDocument}
          showDownloadFile={true}//this.props.companyType === 1
          getUrl={this.getUrl}
          downloadingFile={this.state.downloadingFile}
          selectedItems={{
            tags: this.state.tags,
            companies: this.state.companies,
            statuses: this.state.statuses,
            types: this.state.types,
            gps: this.state.gps,
          }}
          onChange={this.filtersChange}
          searchButtonText={this.state.buttonFilterName}
          searchAction={this.handleSearch}
          content={this.filterContents()}
        />
        <div className={"content ".concat(this.state.viewMode)}>
          {this.state.viewMode === GRID ? (
            ""
          ) : (
            <div className="row fleet-row-header">
              <div className="credentials">
                {this.state.fleetTypeItem.id === 1
                  ? "Datos del vehículo"
                  : this.state.fleetTypeItem.id === 2
                  ? "Datos del remolque"
                  : "Transportista"}
              </div>
              <div className="normativity">Normatividad</div>
              <div className="validated">Estatus de validación</div>
              {
                (this.props.companyType === 1) ? <div className="tags">Etiquetas</div> : ''
              }
              {this.state.fleetTypeItem.id === 3 ? (
                <div className="rfc">RFC</div>
              ) : (
                ""
              )}
              {this.state.fleetTypeItem.id === 3 ? (
                <div className="operativeStatus">Estatus operativo</div>
              ) : (
                ""
              )}
              {this.state.fleetTypeItem.id === 3 ? (
                <div className="gps"></div>
              ) : (
                <div className="gps">GPS</div>
              )}
            </div>
          )}
          <Selector
            selectedItemClass="selected"
            selectionModeClass="selection"
            selectedItems={this.state.selectedItemsIds}
            onSelect={this.selectItem}
            selectionModeOn={this.state.selectionModeOn}
            toggleSelectionMode={this.toggleSelectMode} 
            showSelector={this.props.companyType === 1 && this.validShow({
              id: "35",
              perm: "u",
            })}
          >
            {
              this.getItems().map((fleetItem) => {
                  return this.state.viewMode === GRID ? (
                    <CatalogCard
                      id={fleetItem.id}
                      key={fleetItem.id}
                      defaultClass="fleet-card card"
                      selected={this.state.selectedItemsIds.includes(fleetItem.id)}
                      onClick={() => this.redirectToProfile(fleetItem)}
                    >
                      <FleetCardContent
                        id={fleetItem.id}
                        itemInfo={fleetItem}
                        itemType={this.state.fleetTypeItem}
                      />
                    </CatalogCard>
                  ) : (
                    <FleetRow
                      key={fleetItem.id} 
                      id={fleetItem.id}
                      itemType={this.state.fleetTypeItem}
                      itemInfo={fleetItem}
                      taxInfo={fleetItem.fiscal_number ?? ''}
                      addSanction={ showLockMenu ? (() => this.openSanctionModal(fleetItem, "add")) : null }
                      editSanction={ showLockMenu ? (() => this.openSanctionModal(fleetItem, "edit")) : null }
                      delSanction={ showLockMenu ? (() => this.openSanctionModal(fleetItem, "del")) : null }
                      companyType={ this.props.companyType } 
                      // selectionModeOn={this.state.selectionModeOn}
                      isSelected={this.state.selectedItemsIds.includes( 
                        fleetItem.id
                      )}
                      // selectItem={this.selectItem}
                      onClick={() => this.redirectToProfile(fleetItem)}
                      isLoadingUnshare={this.props.isUnshareLoading}
                      unshareItem={(unshare_data) => this.setState({unshare_data})}
                      unshare={(item) => {

                        if (this.state.fleetTypeItem.id === 3) {
                          this.props.deleteShareOperator({
                              company_id: this.props.companyId,
                              provider_id: item.fleet_id,
                              operator_id: item.fleet_data_id,
                              company_to_unshare: item.company,
                            }, 
                            {
                              onSuccess: () => {
                                this.setState({showUnshareModalSuccess: true})
                                ReactTooltip.hide();
                                this.loadCatalogItems()
                              },
                              onError: (e) => {
                                this.setState({showUnshareModalSuccess: true})
                                ReactTooltip.hide();
                                this.loadCatalogItems()
                            }
                          });
                        } else {
                          this.props.deleteShareFleetData({
                              company_id: this.props.companyId,
                              fleet_id: item.fleet_id,
                              fleet_data_id: item.fleet_data_id,
                              company_to_unshare: item.company,
                              type: this.state.fleetTypeItem.id
                            }, 
                            {
                              onSuccess: () => {
                                this.setState({showUnshareModalSuccess: true})
                                ReactTooltip.hide();
                                this.loadCatalogItems()
                              },
                              onError: (e) => {
                                this.setState({showUnshareModalSuccess: true})
                                ReactTooltip.hide();
                                this.loadCatalogItems()
                            }
                          });
                        }

                      }}
                    />
                  );
                })
            }
          </Selector>
          <PaginatorControlled
            itemCount={this.getTotalElements()}
            onChange={(e) =>
              {
                this.setState({ [e.target.name]: e.target.value }, () => {
                  if (this.state.first_called) {
                    this.loadCatalogItems()
                  }
                })
              }
            }
            limit={this.state.limit}
            offset={this.state.offset}
          />
          <CatalogSelectionFooter
            show={this.state.selectionModeOn}
            selectedElements={this.state.selectedItemsIds.length}
            selectAll={this.selectAllItems}
            actions={[
              {
                description: "Cerrar",
                f: () => this.setState({selectedItemsIds: [], selectionModeOn: false})
              },
              // this.props.companyType !== 1 && this.props.validateObjPerms(
              //   [
              //     {
              //       id: "35",
              //       perm: "d",
              //     },
              //   ],
              //   {
              //     description: "Eliminar de mi cuenta",
              //     f: this.openUnlinkModal, // Agregar contenido a funcion de openDeleteModal
              //     disabled: !this.state.selectedItemsIds.length
              //   }
              // ),
              this.props.companyType === 1 && this.props.validateObjPerms(
                [
                  {
                    id: "35",
                    perm: "d",
                  },
                ],
                {
                  description: this.state.fleetTypeItem.id === 3 ? "Desvincular operador" : this.state.fleetTypeItem.id === 1 ? "Eliminar vehículo" : "Eliminar equipo",
                  f: this.openDeleteModal,
                  disabled: !this.state.selectedItemsIds.length
                }
              ),
              this.props.companyType === 1
                && this.props.validateObjPerms(
                    [
                      {
                        id: "35",
                        perm: "u",
                      },
                    ],
                    {
                      description: "Compartir",
                      f: this.openShareFleetModal,
                      disabled: !this.state.selectedItemsIds.length
                    }
                  ),
              // this.props.validateObjPerms(
              //   [
              //     {
              //       id: "35",
              //       perm: "u",
              //     },
              //   ],
              //   {
              //     description: "Editar etiquetas",
              //     f: this.openTagsModal,
              //     disabled: !this.state.selectedItemsIds.length
              //   }
              // ),
            ]}
          />
        </div>
        <Modal
          isOpen={this.state.openInviteModal}
          portalClassName="dialog confirm-dialog"
          overlayClassName="overlay"
          className="content invite-operator"
        >
          <div className="title">Invitar operador</div>
          <div className="close" onClick={this.closeInviteModal}></div>
          <div className="message">
            <div className="license-search">
              <TextInput
                type="text"
                label="Ingresa N° Licencia"
                name="licenseNumber"
                value={this.state.searchedLicence}
                onChange={this.handleLicence}
              />
              <Button
                settings={{
                  text: "Buscar licencia",
                  disabled: false,
                  onClick: this.getOperatorByLicense,
                }}
              />
            </div>
            {this.state.hasSearchedOperator && (this.props.operatorByLicense && !this.props.operatorByLicense.error) ? (
              <>
                <p className="result-message success">✔ Operador existente</p>
                <div style={{display: "flex", transform: "translateY(-15px)"}}>
                  <div style={{fontWeight:"bold",backgroundColor:"#0000001a",borderRadius:"5px",padding:"3px 5px",fontSize:"12px",display:"flex",alignItems:"center"}}>
                    <img
                      alt=""
                      src={`${this.getAvatarUrl(this.props.operatorByLicense.user_info?.profile_image || '')}`}
                      style={{ backgroundColor: "#FFF", borderRadius: "50%", marginRight:"5px",height:"25px" }}
                    />
                    {this.props.operatorByLicense.user_info?.first_name} {" "}
                    {this.props.operatorByLicense.user_info?.last_name} {"  - "}
                    {this.state.searchedLicence}
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {this.state.hasSearchedOperator && (!this.props.operatorByLicense || this.props.operatorByLicense.error) ? (
              <>
                <p className="result-message failure">
                  {/* eslint-disable-next-line */}❌ No existe registro del
                  operador
                </p>
                <div className="create-operator">
                  <div>
                    Para crear una cuenta a este operador, debes generar una
                    cuenta nueva
                  </div>
                  <div>
                    <Button
                      settings={{
                        text: "Crear Perfil",
                        disabled: false,
                        onClick: () => {this.closeInviteModal(); this.createFleetItem()},
                      }}
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}{
              //this.props.tags
              (this.state.hasSearchedOperator && (this.props.operatorByLicense && !this.props.operatorByLicense.error)) &&
              <div>
                <ChipContainer
                  items={this.fleetTagsToChips()}
                  title="Base"
                  onChange={this.handleTagChange}
                  name="fleetTags"
                  background= "light"
                />
              </div> 
            }
          </div>
          <div className="actions">
            <Button
              settings={{
                text: "Cancelar",
                type: "primary outline",
                onClick: this.closeInviteModal,
              }}
            />
            <Button
              settings={{
                text: "Invitar",
                disabled:
                  !this.state.hasSearchedOperator ||
                  !this.props.operatorByLicense ||
                  (this.props.operatorByLicense || {}).error ||
                  (this.props.tags.length && !this.state.selectedOperatorTag),
                onClick: this.inviteOperator,
              }}
            />
          </div>
        </Modal>
        <Modal
          isOpen={this.state.openXlsModal}
          portalClassName="dialog confirm-dialog"
          overlayClassName="overlay"
          className="content invite-operator"
        >
          <div className="title">Agregar archivos</div>
          <div className="close" onClick={this.closeXlsModal}></div>
          <div className="message">
            <div className="attach-xls">
              <div>
                <FileSelector
                  name={"xls"}
                  acceptTypes={[acceptTypes.xls]}
                  onChange={this.handleAddDocument}
                  allowMultiple={false}
                >
                  <Button
                    settings={{
                      type: "primary small",
                      text: "Subir archivo",
                      prefix: {
                        element: (
                          <SVGIcon
                            name={icons.uploadFile}
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                          />
                        ),
                      },
                    }}
                  />
                </FileSelector>
              </div>
              <div>
                {(this.state.xls || []).map((f, i) => (
                  <Chip
                    key={i}
                    id={i}
                    active={true}
                    className="medium"
                    name="files"
                    description={getFileDescription(f, 25)}
                    onClick={() => {}}
                    prefix={{
                      element: (
                        <SVGIcon
                          name={icons.imageFile}
                          fill="#657795"
                          viewBox="0 -1 16 16"
                        />
                      ),
                    }}
                    suffix={{
                      element: (
                        <div
                          onClick={() => this.handleRemoveDocument("xls", f)}
                        >
                          {" "}
                          <SVGIcon
                            name={icons.cross}
                            fill="#A7B6D6"
                            viewBox="0 3 16 16"
                          />
                        </div>
                      ),
                    }}
                  />
                ))}
              </div>
            </div>
            {this.state.hasSearchedOperator && this.props.operatorByLicense ? (
              <p className="result-message success">✔ Operador existente</p>
            ) : (
              ""
            )}
            {this.state.hasSearchedOperator && !this.props.operatorByLicense ? (
              <>
                <p className="result-message failure">
                  <span role="img" aria-label="">
                    ❌
                  </span>{" "}
                  No existe registro del operador
                </p>
                <div className="create-operator">
                  <div>
                    Para crear una cuenta a este operador, debes generar una
                    cuenta nueva
                  </div>
                  <div>
                    <Button
                      settings={{
                        text: "Crear Perfil",
                        disabled: false,
                        onClick: this.createFleetItem,
                      }}
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="actions">
            <Button
              settings={{
                text: "Cancelar",
                type: "primary outline",
                onClick: this.closeXlsModal,
              }}
            />
            <Button
              settings={{
                text: this.state.loading ? "Cargando..." : "Cargar",
                disabled:
                  this.state.xls === null || this.state.loading ? true : false,
                onClick: () => {
                  this.importXls();
                },
              }}
            />
          </div>
        </Modal>
        <Modal
          isOpen={this.state.openXlsSuccessModal}
          portalClassName="dialog confirm-dialog"
          overlayClassName="overlay"
          className="content invite-operator successModal"
        >
          <div className="title">Carga finalizada</div>
          <div className="close" onClick={this.closeXlsSuccessModal}></div>
          <div className="message">
            <div className="successContent">
              <div className="text-group">
                <div class="image">
                  <img alt="" src="/images/oval green.svg"></img>
                </div>
                <div className="text">
                  {this.state.correctImport} de {this.state.totalImport}{" "}
                  Registros cargados éxitosamente.
                </div>
              </div>

              <div className="text-group">
                <div class="image">
                  <img alt="" src="/images/oval red.svg"></img>
                </div>
                <div className="text">
                  {this.state.failedImport} de {this.state.totalImport}{" "}
                  Registros rechazados.
                </div>
              </div>

              <div className="errorsDetails">
                <div>
                  - {this.state.duplicatedCompanyImport} de{" "}
                  {this.state.failedImport} Registros ya están en tu empresa
                </div>
                <div>
                  - {this.state.duplicatedSystemImport} de{" "}
                  {this.state.failedImport} Ya existe en Orbinetwork, envia una
                  invitación
                </div>
                <div>
                  - {this.state.invalidImport} de {this.state.failedImport}{" "}
                  Campo incompleto o inválido
                </div>
              </div>

              <div className="export">
                Para ver más detalles ver el{" "}
                <a
                  href={`${SERVER}/api/v2/companies/${this.state.companyIdImport}/upload/${this.state.uploadIdImport}/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  descargable
                </a>
              </div>
            </div>
          </div>
          <div className="actions successActions">
            <Button
              settings={{
                text: "Aceptar",
                onClick: this.closeXlsSuccessModal,
              }}
            />
          </div>
        </Modal>
        <Modal
          isOpen={this.state.openXlsErrorModal}
          portalClassName="dialog confirm-dialog"
          overlayClassName="overlay"
          className="content invite-operator successModal"
        >
          <div className="title">Error</div>
          <div className="close" onClick={this.closeXlsErrorModal}></div>
          <div className="message">{this.state.descriptionImport.map(e => <div>{e}</div>)}</div>
          <div className="actions successActions">
            <Button
              settings={{
                text: "Aceptar",
                onClick: this.closeXlsErrorModal,
              }}
            />
          </div>
        </Modal>
        <TagsSuggestionModal
          closeAction={() => this.setState({ openTagsModal: false })}
          open={this.state.openTagsModal}
          itemType="flotillas"
          items={this.getItems()
            .filter((f) => this.state.selectedItemsIds.includes(f.id))
            .map((item) => {
              if (this.state.fleetTypeItem.id === 3) return item;
              else
                return {
                  ...item,
                  name: item.economic_number.concat(" ", item.plates),
                };
            })}
          tagCatalog={this.props.tags}
        />
        <ShareFleetModal
          fleetType={this.state.fleetTypeItem.id}
          closeSelectMode={() => this.setState({ selectionModeOn: false })}
          closeAction={() => this.setState({ openShareFleetModal: false })}
          open={this.state.openShareFleetModal}
          companiesCatalog={
            Array.isArray(this.props.networkCompanies)
              ? this.props.networkCompanies.map((network) => {
                  // debugger
                  if (network.from_companies.id === this.props.companyId) {
                    return {
                      name: network.to_companies.corporation_name,
                      alias: network.to_companies_alias,
                    };
                  } else {
                    return {
                      name: network.from_companies.corporation_name,
                      alias: network.from_companies_alias,
                    };
                  }
                })
              : []
          }
          reloadItems={this.loadCatalogItems}
          items={this.getItems()
            .filter((f) => this.state.selectedItemsIds.includes(f.id))
            .map((item) => {
              if (this.state.fleetTypeItem.id === 3) return item;
              else
                return {
                  ...item,
                  name: item.economic_number.concat(" ", item.plates),
                };
            })}
        />
        <Modal
          isOpen={this.state.openDeleteModal}
          portalClassName="dialog confirm-dialog"
          overlayClassName="overlay"
          className="content invite-operator successModal"
        >
          <div className="title">Confirmar</div>
          <div className="close" onClick={this.closeDeleteModal}></div>
          <div className="message">¿Estás seguro que deseas {this.state.fleetTypeItem.id === 3 ? "desvincular" : "eliminar"} estos {this.state.selectedItemsIds.length} elementos?</div>
          <div className="actions ">
            <Button
              text="Cancelar"
              type="secondary"
              onClick={this.closeDeleteModal}
            />
            <Button
              settings={{
                text: "Aceptar",
                onClick: this.handleDelete,
                disabled: this.state.isLoading
              }}
            />
          </div>
        </Modal>
        <Modal
          isOpen={this.state.openUnlinkModal}
          portalClassName="dialog confirm-dialog"
          overlayClassName="overlay"
          className="content invite-operator successModal"
        >
          <div className="title">{this.getUnlinkTitleModal()}</div>
          <div className="close" onClick={() => this.setState({ openUnlinkModal: false })}></div>
          <div className="message">{this.getUnlinkMessageModal()}</div>
          <div className="actions ">
            <Button
              text="Cancelar"
              type="secondary"
              onClick={() => this.setState({ openUnlinkModal: false })}
            />
            <Button
              settings={{
                text: "Si, deseo eliminarlo",
                onClick: () => {
                  ReactTooltip.hide();

                  this.unshareCounter = 0;
                  this.setState({isLoading: true, openUnlinkModal: false});

                  this.state.selectedItemsIds.forEach(sI => {
                    let params = sI.split('-');

                    if (this.state.fleetTypeItem.id !== 3) {
                      this.props.deleteShareFleetData({
                          company_id: this.props.companyId,
                          fleet_id: params[1],
                          fleet_data_id: params[0],
                          company_to_unshare: this.props.companyId,
                          type: this.state.fleetTypeItem.id
                        }, 
                        {
                          onSuccess: () => {
                            this.unshareCounter = this.unshareCounter + 1;

                            if (this.unshareCounter === this.state.selectedItemsIds.length) {
                              this.setState({showUnshareModalSuccess: true, selectedItemsIds: [], selectionModeOn: false});
                              this.loadCatalogItems();
                            }
                          },
                          onError: (e) => {
                            this.unshareCounter = this.unshareCounter + 1;

                            if (this.unshareCounter === this.state.selectedItemsIds.length) {
                              this.setState({showUnshareModalSuccess: true, selectedItemsIds: [], selectionModeOn: false});
                              this.loadCatalogItems();
                            }
                          }
                      });

                    } else {
                      let operatorItem = this.getItems().filter(item => `${item.id }` === `${sI}`);

                      this.props.deleteShareOperator({
                          company_id: this.props.companyId,
                          provider_id: params[0],
                          operator_id: operatorItem[0].detail_id,
                          company_to_unshare: params[0],
                        }, 
                        {
                          onSuccess: () => {
                            this.unshareCounter = this.unshareCounter + 1;

                            if (this.unshareCounter === this.state.selectedItemsIds.length) {
                              this.setState({showUnshareModalSuccess: true, selectedItemsIds: [], selectionModeOn: false});
                              this.loadCatalogItems();
                            }
                          },
                          onError: (e) => {
                            this.unshareCounter = this.unshareCounter + 1;

                            if (this.unshareCounter === this.state.selectedItemsIds.length) {
                              this.setState({showUnshareModalSuccess: true, selectedItemsIds: [], selectionModeOn: false});
                              this.loadCatalogItems();
                            }
                        }
                      });
                    }
                  });
                },
              }}
            />
          </div>

        </Modal>
        <Modal
          isOpen={this.state.openSanctionModal}
          portalClassName="dialog confirm-dialog"
          overlayClassName="overlay"
          className="content invite-operator successModal"
        >
          <div className="title">Bloqueo operativo</div>
          <div className="close" onClick={() => 
            this.setState({
              openSanctionModal: false,

              weekSanction: {id: 1, description: "1"},
              sanctionSelected: undefined,
              sanctionFromDate: '',
              sanctionToDate: '',

              operatorToLock: null,
            })}>
          </div>
          <div className="message">
            <h3>
              Selecciona un periodo de tiempo para aplicar el bloqueo operativo
            </h3>

            <div className='form data formDivided'>
              <SelectInput
                  label='Semana de incidencia'
                  className="full"
                  name="weekSanction"
                  items={this.state.weeks}
                  value={this.state.weekSanction.description}
                  onChange={(e) => {
                    let value = e.target.value;
                    value--;
                    this.setState({ weekSanction: this.state.weeks[value] });
                  }}
                />
                <SelectInput
                  label='Motivo de bloqueo'
                  className="full"
                  name="sanctionSelected"
                  items={this.props.sanctionsCatalog}
                  value={this.state.sanctionSelected !== undefined ? this.state.sanctionSelected.id : 0}
                  onChange={(e) => {
                    let value = e.target.value;
                    this.setState({ sanctionSelected: this.getSanction(value) });
                  }}
                />
              </div>
              <TextInput
                type="date"
                format="dd/MMM/yyyy"
                minDate={minDate}
                value={this.state.sanctionFromDate}
                dateAdjust={true}
                label="Inicio de bloqueo operativo"
                name="selectedLockStart"
                onChange={e => {
                  this.setState({ 
                    sanctionFromDate: e.target.value,
                    sanctionToDate: ''
                  })
                }}
                suffix={{
                  element: <SVGIcon name={icons.calendar} fill="#3D77F7" />,
                }}
              />
              <TextInput
                type="date"
                format="dd/MMM/yyyy"
                minDate={this.state.sanctionFromDate === '' ? new Date() : this.state.sanctionFromDate}
                value={this.state.sanctionToDate}
                dateAdjust={true}
                label="Fin de bloqueo operativo"
                name="selectedLockEnd"
                onChange={e => {
                  this.setState({ 
                    sanctionToDate: e.target.value,
                  })
                }}
                suffix={{
                  element: <SVGIcon name={icons.calendar} fill="#3D77F7" />,
                }}
              />

          </div>
          <div className="actions ">
            <Button
              text="Cancelar"
              type="secondary"
              onClick={() => {
                this.setState({
                  openSanctionModal: false,

                  weekSanction: {id: 1, description: "1"},
                  sanctionSelected: undefined,
                  sanctionFromDate: '',
                  sanctionToDate: '',

                  operatorToLock: null,
                })
              }}
            />
            <Button
              settings={{
                text: this.state.isAddSanction ? "Crear" : "Actualizar",
              }}
              disabled={this.state.sanctionSelected === undefined || this.state.sanctionFromDate === '' || this.state.sanctionToDate === ''}
              onClick={() => {
                if(this.state.isAddSanction){
                  this.props
                  .setSanctions(
                    this.props.companyId,
                    this.state.operatorToLock.fleetId, 
                    this.state.operatorToLock.fleet_data_id,
                    {
                      locked_from:new Date(this.state.sanctionFromDate),
                      locked_to:new Date(this.state.sanctionToDate),
                      incidence_week: this.state.weekSanction.id,
                      fleet_catalog_operator: this.state.operatorToLock.fleet_data_id,
                      lock_motive: this.state.sanctionSelected.id
                    }
                  ).then((response) => {
                    this.setState({
                      openSanctionModal: false,

                      weekSanction: {id: 1, description: "1"},
                      sanctionSelected: undefined,
                      sanctionFromDate: '',
                      sanctionToDate: '',

                      operatorToLock: null,
                    });
                    this.loadCatalogItems()
                  });
                } else {

                  let sanction_id = -1;

                  if (this.state.operatorToLock.sanctions.length) {
                    sanction_id = this.state.operatorToLock.sanctions[0].id;
                  }

                  this.props.patchSanctions(
                    this.props.companyId,
                    this.state.operatorToLock.fleetId, 
                    this.state.operatorToLock.fleet_data_id,
                    sanction_id, 
                    {
                      locked_from:new Date(this.state.sanctionFromDate),
                      locked_to:new Date(this.state.sanctionToDate),
                      incidence_week: this.state.weekSanction.id,
                      fleet_catalog_operator: this.state.operatorToLock.fleet_data_id,
                      lock_motive: this.state.sanctionSelected.id
                    }
                  ).then((response) => {
                    this.setState({
                      openSanctionModal: false,

                      weekSanction: {id: 1, description: "1"},
                      sanctionSelected: undefined,
                      sanctionFromDate: '',
                      sanctionToDate: '',

                      operatorToLock: null,
                    });
                    this.loadCatalogItems()
                  });
                }
              }}
            />
          </div>
        </Modal>
        <Modal
          isOpen={this.state.openDelSanctionModal}
          portalClassName="dialog confirm-dialog"
          overlayClassName="overlay"
          className="content invite-operator successModal"
        >
          <div className="title">Confirmar</div>
          <div className="close" onClick={() => this.setState({ openDelSanctionModal: false })}></div>
          <div className="message">¿Estás seguro que deseas eliminar el bloqueo de {this.state.operatorToLock === null ? '' : this.state.operatorToLock.name}?</div>
          <div className="actions ">
            <Button
              text="Cancelar"
              type="secondary"
              onClick={() => this.setState({ openDelSanctionModal: false })}
            />
            <Button
              settings={{
                text: "Aceptar",
              }}
              onClick={() => {

                let sanction_id = -1;

                  if (this.state.operatorToLock.sanctions.length) {
                    sanction_id = this.state.operatorToLock.sanctions[0].id;
                  }


                this.props
                  .deleteSanctions(
                    this.props.companyId,
                    this.state.operatorToLock.fleetId, 
                    this.state.operatorToLock.fleet_data_id,
                    sanction_id
                  )
                  .then((response) => {
                    this.setState({ openDelSanctionModal: false })
                    this.loadCatalogItems()
                  });
              }}
            />
          </div>
        </Modal>
        <BaseDialog
          closeAction={() => this.setState({showUnshareModalSuccess: false})}
          acceptAction={() => this.setState({showUnshareModalSuccess: false})}
          open={this.state.showUnshareModalSuccess}
          title={this.props.companyType === 1 ? "Dejar de compartir" : "Eliminar de mi cuenta"}
          contentObject={ this.props.companyType === 1 ? "Se dejó de compartir" : "Se eliminó de mi cuenta"}
          showCancel={false}
          cancelText="Cerrar"
          cancelButtonType="primary outline dark"
          acceptText="Aceptar"
          acceptButtonType="primary"
          style={{ content: { width: "480px", marginLeft: "-240px" } }}
        />
        <ConfirmDialog
          closeAction={this.closeUnshareOperator}
          open={this.state.unshare_data !== null}
          title="Dejar de compartir"
          className="unshare_operator"
          acceptText="Continuar"
          acceptAction={this.unshareOperatorAction}
          message={`¿Estás seguro que deseas dejar de compartir este registro con “${this.state.unshare_data?.company_alias || ''}”? Esta acción eliminará de forma definitiva los registros históricos de tus normatividades realizados por los usuarios de esta empresa.`}
          showCancel={true}
          cancelText="Cancelar"
          disabledAccept={this.props.isUnsharingOperatorLoading}
        />

        <GenericDialog
          open={this.state.generic_message !== null}
          title={this.state.generic_message?.title || ''}
          message={this.state.generic_message?.message || ''}
          acceptAction={() => this.setState({generic_message: null})}
          closeAction={() => this.setState({generic_message: null})}
        />
      </div>
    );
  }

  render() {
    return( this.state.isFreeSubscription ? this.getFreeSubscription() : this.getMainContent() );
  }

  getUnlinkTitleModal = () => {
    if (this.state.fleetTypeItem.id === 3) {
      return "¿Eliminar operador(es)?";
    } else if (this.state.fleetTypeItem.id === 2) {
      return "¿Eliminar equipo(s)?";
    } else if (this.state.fleetTypeItem.id === 1) {
      return "¿Eliminar vehículo(s)?";
    } else {
      return "";
    }
  }

  getUnlinkMessageModal = () => {
    let message = "";
    if (this.state.fleetTypeItem.id === 3) {
      message = "¿Estas seguro que deseas eliminar este(os) operador(es)?";
    } else if (this.state.fleetTypeItem.id === 2) {
      message = "¿Estas seguro que deseas eliminar este(os) equipo(s)?";
    } else if (this.state.fleetTypeItem.id === 1) {
      message = "¿Estas seguro que deseas eliminar este(os) vehículo(s)?";
    }

    let itemsToShow = this.getItems().filter( item => this.state.selectedItemsIds.includes(item.id));

    return(
      <>
        <div>{message}</div>
        <UserChipContainer
          deleteTag={(id) => { 
            let result = this.state.selectedItemsIds.filter(item => item !== id);
            if (result.length === 0) {
              this.setState({
                openUnlinkModal: false,
                selectedItemsIds: result
              });
            } else {
              this.setState({
                selectedItemsIds: result
              });
            }
          }}
          items={
            itemsToShow.map(item => {
              if (this.state.fleetTypeItem.id === 3) {
                return({
                  avatar: (item.avatar !== '' && item.avatar !== null) ? getUrlsEnv().files.concat(item.avatar) : '/images/user.svg',
                  name: item.name,
                  avatarType: "full",
                  id: item.id
                });
              } else if (this.state.fleetTypeItem.id === 2) {
                return({
                  avatar: (item.avatar !== '' && item.avatar !== null) ? getUrlsEnv().files.concat(item.avatar) : '/images/remolque.svg',
                  name: `${item.economic_number} ${item.plates}`,
                  avatarType: "full",
                  id: item.id
                });
              } else if (this.state.fleetTypeItem.id === 1) {
                return({
                  avatar: (item.avatar !== '' && item.avatar !== null) ? getUrlsEnv().files.concat(item.avatar) : '/images/truck.svg',
                  name: `${item.economic_number} ${item.plates}`,
                  avatarType: "full",
                  id: item.id
                });
              } else {
                return item;
              }
            })
          }
        />
      </>
    );

  }

}

const mapStateToProps = (state) => {
  const country = state.globalCatalog.session.company.country__description;
  // const { tags : { tags = [] }  = { tags: [] } } = state.tags;

  let tagsResult = (state.tags.tags || []).filter(
    (tag) =>
      tag.type ===
      (
        (state.tags.tagTypes.data || []).find(
          (type) => type.description.toLowerCase() === "flotilla"
        ) || {}
      ).id
  );
  let normativityTypes = state.fleetReducer.normativityType.data || [];

  let operatorNormativity =
    state.fleetReducer.operatorNormativityType.data || [];
  let vehicleNormativity = normativityTypes.filter((n) => n.type === 1);
  let equipmentNormativity = normativityTypes.filter((n) => n.type === 2);
  let normativityStatus = state.fleetReducer.normativityStatus.data || [];
  let fleetProvider = state.fleetReducer.fleetProvider.data || [];
  let vehicleType = state.fleetReducer.vehicleType.data || [];
  let operatorType = state.fleetReducer.operatorType.data || [];
  let sanctionsCatalog = state.fleetReducer.sanctionsCatalog.data || [];
  let equipmentType = state.fleetReducer.equipmentType.data || [];

  // operatorNormativity = Array.isArray(operatorNormativity) && operatorNormativity.map(n => ({ id: n.id, description: n.description, items: [], active: false }));
  // vehicleNormativity = vehicleNormativity.map(n => ({ id: n.id, description: n.description, items: [], active: false }));
  // equipmentNormativity = equipmentNormativity.map(n => ({ id: n.id, description: n.description, items: [], active: false }));

  vehicleType = vehicleType
    .filter((item) => {
      return item.type === 1;
    })
    .map((t) => ({
      id: t.id,
      description: t.description,
      items: [],
      active: false,
    }));
  operatorType = operatorType.map((t) => ({
    id: t.id,
    description: t.description,
    items: [],
    active: false,
  }));
  let equipmentTypeCategories = equipmentType
    .map((type) => type.type)
    .filter((value, index, self) => self.indexOf(value) === index); //Obtener las categorías de tipos de equipos
  equipmentType = equipmentTypeCategories.map((category, index) => {
    return {
      id: index,
      description: category,
      items: equipmentType
        .filter((t) => t.type === category)
        .map((t) => ({ id: t.id, description: t.description, active: false })),
      active: false,
      contentType: "equipmentType",
      status: "full",
    };
  });

  // filtrosFlotillas[0].data = tags.map(t => ({ id: t.id, description: t.title, items: [], active: false, status: 'full' }));
  // // filtrosFlotillas[1].data = vehicleNormativity.map(t => ({ id: t.id, description: t.description, items: [], active: false }));
  // filtrosFlotillas[1].data = normativityStatus.map(t => ({ id: t.id, description: t.description, items: [], active: false }));
  // filtrosFlotillas[2].data = fleetProvider.map(t => ({ id: t.company, description: t.company_name, items: [], active: false }));
  // filtrosFlotillas[3].data = vehicleType;
  // console.log("3", state.fleetReducer);
  const configuration = {
    base: state.configuration.FleetCatalogBase,
    1: state.configuration.FleetCatalog1,
    2: state.configuration.FleetCatalog2,
    3: state.configuration.FleetCatalog3,
  };

  const vehiclesResult = getEntityItems(state, "COMPANIES.FLEETS.CATALOG.VEHICLES");
  const equipmentsResult = getEntityItems(state, "COMPANIES.FLEETS.CATALOG.EQUIPMENTS");
  // const operatorsResult = getEntityItems(state, "COMPANIES.FLEETS.CATALOG.OPERATORS");
  const operatorsResultV2 = getEntityItems(state, "operators_info");
  const statusLog = getEntityItems(state, "COMPANIES.FLEETS.CATALOG.STATUSLOG") || [];
  const operatoStatusLog = (getEntityItems(state, "operators_action_logs") || {results: []})['results'] || [];

  const networkComaniesFilter = Array.isArray(state.companyReducer.networkApprovedInvitations.data)
    ? state.companyReducer.networkApprovedInvitations.data.map((network) => {
        if (network.from_companies.id === state.globalCatalog.session.company.id) {
          return {
            id: network.to_companies.id,
            visible: true,
            description: network.to_companies.orbi_id,
          };
        } else {
          return {
            id: network.from_companies.id,
            visible: true,
            description: network.from_companies.orbi_id,
          };
        }
      })
    : [];

  const isLoadingFleetsUnshare = state?.api['COMPANIES.FLEETS.DATA.UNSHARE']?.status?.isFetching || false;
  const isLoadingOperatorUnshare = state?.api['COMPANIES.FLEETS.OPERATORS.UNSHARE']?.status?.isFetching || false;
  const isUnsharingOperatorLoading = state?.api['COMPANIES.FLEETS.DATA.OPERATORS.UNSHARE']?.status?.isFetching || false;

  const operatorCatalogInfo = getOperatorItemsV2(operatorsResultV2['results'] || [], state.globalCatalog.session.company.type.id === 1);

  return {
    operatoStatusLog,
    isUnsharingOperatorLoading,
    country,
    configuration,
    // filters
    tags: tagsResult,
    tabSelected: state.globalCatalog.tabSelected,
    toast: state.globalCatalog.toast,
    companyFleet: state.fleetReducer.fleets.data,
    // vehicle_list: state.fleetReducer.fleets.response,
    companyId: state.globalCatalog.session.company.id,
    companyType: state.globalCatalog.session.company.type.id,
    vehicle_normativity_types: vehicleNormativity,
    equipment_normativity_types: equipmentNormativity,
    operator_normativity_types: operatorNormativity,
    normativity_status_list: normativityStatus,
    fleet_provider_list: fleetProvider,

    equipment_types: equipmentType,
    vehicle_types: vehicleType,
    operator_types: operatorType,
    sanctionsCatalog: sanctionsCatalog,

    permissions: state.globalCatalog.permissionUser.data || {},

    // vehicleItemsCount:
    //   state.fleetReducer.vehicleCatalog.response &&
    //   state.fleetReducer.vehicleCatalog.response.count,
    vehicleItemsCount: vehiclesResult['count'] || 0,
    // vehicleItems: getVehicleItems(
    //   state.fleetReducer.vehicleCatalog.response &&
    //     state.fleetReducer.vehicleCatalog.response.results
    // ),
    vehicleItems: getVehicleItems(vehiclesResult['results'] || []),
        // isVehicleLoading: state?.fleetReducer?.vehicleCatalog?.isFetching,
        // isEquipmentLoading: state?.fleetReducer?.equipmentCatalog?.isFetching,
        // isOperatorLoading: state?.fleetReducer?.operatorCatalog?.isFetching,
    equipmentItemsCount: equipmentsResult['count'] || 0,
      // state.fleetReducer.equipmentCatalog.response &&
      // state.fleetReducer.equipmentCatalog.response.count,
    equipmentItems: getVehicleItems(
      equipmentsResult['results'] || []
      // state.fleetReducer.equipmentCatalog.response &&
      //   state.fleetReducer.equipmentCatalog.response.results
    ),
    operatorItemsCount: operatorsResultV2['count'] || 0,
      // state.fleetReducer.operatorCatalog.response &&
      // state.fleetReducer.operatorCatalog.response.count,
    operatorItems: operatorCatalogInfo,

    networkCompanies:
      state.companyReducer.networkApprovedInvitations.data || [],
    operatorByLicense: state.fleetReducer.operatorByLicense.response,
    // equipmentTypes: state.fleetReducer.fleetClass.data,
    // // equipmentTypes: joinRelatedArrays(equipmentTypesCatalog, classesCatalog, 'typeId'),
    // // classes: [],
    // fleetTagsCatalog: fleetTagsCatalog,
    // normativityItems: normativityItems

    // catalogStatusLog: state.fleetReducer.catalogStatusLog.data,
    catalogStatusLog: statusLog,
    networkComaniesFilter,
    isUnshareLoading: isLoadingFleetsUnshare || isLoadingOperatorUnshare,
    isCO: (state.globalCatalog.session.company.type.id || 0) === 2,
    isPremiumSubscriptionCo: isPremiumSubscriptionCo(state.globalCatalog.session)
  };
};
const mapDispatchToProps = (dispatch) => {
  // dispatch(loadFleetClasses());
  dispatch(loadSession());
  // dispatch(loadFleetData(1));

  // dispatch(loadFleets());

  dispatch(loadTags());
  dispatch(loadOperatorNormativityTypes());
  dispatch(loadNormativityTypes());
  dispatch(loadNormativityStatus());
  dispatch(loadFleetProvider());
  dispatch(loadVehicleTypes());
  dispatch(loadEquipmentTypes());
  dispatch(loadOperatorTypes());
  dispatch(loadSanctions());

  dispatch(loadPermissionsUser());

  dispatch(listNetworkApprovedInvitations("", ""));

  return {
    loadFleets: () => dispatch(loadFleets()),
    cancelRequest: (entityPath) => dispatch(cancelRequest(entityPath)),
    setBaseConfig: (config) => dispatch(setConfig("FleetCatalogBase", config)),
    setConfig: (type, config) =>
      dispatch(setConfig("FleetCatalog".concat(type), config)),
    // loadFleetClasses: () => dispatch(loadFleetClasses()),
    // loadEquipmentCatalog: (companyId, typeId, maxResults, page, filters) =>
    //   dispatch(
    //     loadFleetEquipmentCatalog(companyId, 2, maxResults, page, filters)
    //   ),
    loadEquipmentCatalog: (params, options) =>
      dispatch(
        readEntities(
          "COMPANIES.FLEETS.CATALOG.EQUIPMENTS",
          params,
          options
        )
      ),  
    // loadOperatorCatalog: (companyId, typeId, maxResults, page, filters) =>
    //   dispatch(
    //     loadFleetOperatorCatalog(companyId, 3, maxResults, page, filters)
    //   ),
    loadOperatorCatalog: (params, options) =>
      dispatch(
        readEntities(
          "COMPANIES.FLEETS.CATALOG.OPERATORS",
          params,
          options
        )
      ),
    loadOperatorCatalogV2: (params, options) =>
      dispatch(
        readEntities(
          "COMPANIES.FLEETS.CATALOG.OPERATORSV2.TR",
          params,
          options
        )
      ),
    loadOperatorCatalogCOV2: (params, options) =>
        dispatch(
          readEntities(
            "COMPANIES.FLEETS.CATALOG.OPERATORSV2.CO",
            params,
            options
          )
        ),
    // loadVehicleCatalog: (companyId, typeId, maxResults, page, filters) =>
    //   dispatch(
    //     loadFleetVehicleCatalog(companyId, 1, maxResults, page, filters)
    //   ),
    loadVehicleCatalog: (params, options) =>
      dispatch(
        readEntities(
          "COMPANIES.FLEETS.CATALOG.VEHICLES",
          params,
          options
        )
      ),
    loadStatusLog: (params, options) =>
      dispatch(
        readEntities(
          "COMPANIES.FLEETS.CATALOG.STATUSLOG",
          params,
          options
        )
      ),
    getOperatorByLicense: (fleetId, license) =>
      dispatch(getOperatorByLicense(fleetId, license)),
    setSanctions: (company_id, provider_id, operator_id, sanctionObj) =>
      dispatch(setSanctions(company_id, provider_id, operator_id, sanctionObj)),
    patchSanctions: (company_id, provider_id, operator_id, sanctionId, sanctionObj) =>
      dispatch(patchSanctions(company_id, provider_id, operator_id, sanctionId, sanctionObj)),
    deleteSanctions: (company_id, provider_id, operator_id, sanctionId) =>
      dispatch(deleteSanctions(company_id, provider_id, operator_id, sanctionId)),
    filterByName: (name) => dispatch(searchFleetsByName(name)),
    removeToast: () => dispatch(removeToast()),
    setSearchStr: (searchStr) => dispatch(setFleetSearchStr(searchStr)),
    // getNormativityHistory: (fleetId, fleetDataID, type) =>
    //   getNormativityHistoryData(fleetId, fleetDataID, type),
    // getNormativityHistoryOperator: (fleetId, operatorID, type) =>
    //   getNormativityHistoryOperatorData(fleetId, operatorID, type),
    validateObjPerms: (perms, obj) => validateObjPerms(perms, obj),
    getLoadFleetCompanyTypes: (type) => dispatch(loadFleetCompanyTypes(type)),
    inviteOperator: (params, options) =>
      dispatch(
        createEntity(
          "COMPANIES.FLEETS.OPERATORS.INVITATIONS",
          params,
          options
        )
      ),
    
    deleteOperator: (params, options) =>
      dispatch(
        deleteEntity(
          "COMPANIES.FLEETS.OPERATORS",
          params,
          options
        )
      ),
    deleteFleetData: (params, options) =>
      dispatch(
        deleteEntity(
          "COMPANIES.FLEETS.DATA",
          params,
          options
        )
      ),
    deleteShareFleetData: (params, options) =>
      dispatch(
        deleteEntity(
          "COMPANIES.FLEETS.DATA.UNSHARE", 
          params,
          options
        )
      ),
    deleteShareOperator: (params, options) =>
      dispatch(
        deleteEntity(
          "COMPANIES.FLEETS.OPERATORS.UNSHARE", 
          params,
          options
        )
      ),
    // Nueva version de descompartir operador - TR
    unshareOperator: (params, opt) => 
      dispatch(
        deleteEntity("COMPANIES.FLEETS.DATA.OPERATORS.UNSHARE", params, opt)
      ),
    unshareVehicle: (params, opt) => 
      dispatch(
        deleteEntity("COMPANIES.FLEETS.DATA.VEHICLES.UNSHARE", params, opt)
      ),
    unshareEquipment: (params, opt) => 
      dispatch(
        deleteEntity("COMPANIES.FLEETS.DATA.EQUIPMENTS.UNSHARE", params, opt)
      ),
  };
};

const connectToStore = connect(mapStateToProps, mapDispatchToProps);

export default connectToStore(FleetCatalogView);

// FleetCatalogView.defaultProps = {}

const getVehicleItems = (vehiclesList) => {
  // console.log("LIST", vehiclesList);
  let uniqueVehicles = [];
  if (Array.isArray(vehiclesList) && vehiclesList.length) {
    let uniqueIds = [];
    vehiclesList.forEach((v) => {
      if (!uniqueIds.includes("".concat(v.fleet_id, "-", v.fleet_data_id))) {
        uniqueIds.push("".concat(v.fleet_id, "-", v.fleet_data_id));
      }
    });

    uniqueIds.forEach((id) => {
      let uniqueVehicle = {};
      let repeatedVehicles = vehiclesList.filter(
        (v) => "".concat(v.fleet_id, "-", v.fleet_data_id) === id
      );
      repeatedVehicles.forEach((vehicle, index) => {
        if (index === 0) {
          uniqueVehicle.fleet_data_id = vehicle.fleet_data_id;
          uniqueVehicle.avatar = vehicle.avatar;
          uniqueVehicle.company_avatar = vehicle.company_logo;
          uniqueVehicle.id = "".concat(
            vehicle.fleet_id,
            "-",
            vehicle.fleet_data_id
          );
          uniqueVehicle.fiscal_number = vehicle.fiscal_number;
          uniqueVehicle.economic_number = vehicle.economic_number;
          uniqueVehicle.name = vehicle.operator_name;
          uniqueVehicle.plates = vehicle.licence;
          uniqueVehicle.model = vehicle.model;
          uniqueVehicle.brand = vehicle.brand;
          uniqueVehicle.year = vehicle.year;
          uniqueVehicle.company_id = vehicle.company_id;
          uniqueVehicle.company_name = vehicle.company_name;
          uniqueVehicle.hasGps = vehicle.gps; //
          uniqueVehicle.validated = vehicle.status; //
          uniqueVehicle.shared = vehicle.shared;
          uniqueVehicle.fleetId = vehicle.fleet_id;
          uniqueVehicle.detail_id = vehicle.detail_id;
          uniqueVehicle.validations = [
            {
              id: vehicle.normativity_id,
              type: vehicle.normativity,
              value: vehicle.normativity_status_id,
              description: vehicle.normativity_status,
              isConsidered: vehicle.normativity_is_considered,
            },
          ];
          uniqueVehicle.tags = vehicle.tags || []; // vehicle.tags;
          uniqueVehicle.company_icon = vehicle.company_avatar;
          uniqueVehicle.network = vehicle.network || [];
          uniqueVehicle.fleet_data_type = vehicle.fleet_data_type;
          uniqueVehicle.sanctions = vehicle.sanctions.length === 0 ? null : vehicle.sanctions;
          uniqueVehicle.fleet_catalog_id = vehicle.fleet_catalog_id
        } else {
          uniqueVehicle.validations.push({
            id: vehicle.normativity_id,
            type: vehicle.normativity,
            value: vehicle.normativity_status_id,
            description: vehicle.normativity_status,
            isConsidered: vehicle.normativity_is_considered,
          });
        }
      });
      uniqueVehicles.push(uniqueVehicle);
    });
  }
  return uniqueVehicles;
};

const getOperatorItemsV2 = (operatorsList, isTR) => {
  let operators = [];

  operatorsList.forEach((item) => {
    let newInfo = {}
    
    newInfo.fleet_data_id = isTR ? item.operator?.id ?? 0 : item.id ?? null;
    newInfo.avatar = item.user?.profile_image ?? null;
    newInfo.company_avatar = item.company?.logo ?? null;
    newInfo.id = "".concat(
      isTR ? item.fleet ?? 0 : item.fleet_operator ?? 0,
      "-",
      item.operator?.id ?? 0
    );
    newInfo.fiscal_number = item.fiscal_number;
    newInfo.economic_number = item.fiscal_number;
    newInfo.name = item.user?.full_name ?? '';
    newInfo.plates = item.user?.username ?? '';
    newInfo.model = 0;
    newInfo.brand = "";
    newInfo.year = 0;
    newInfo.company_id = item.company?.id ?? 0;
    newInfo.company_name = item.company?.orbi_id ?? '';
    newInfo.hasGps = false;
    newInfo.validated = item.global_status?.description ?? '';
    newInfo.shared = isTR ? (item.network ?? []).length : -1;
    newInfo.fleetId = isTR ? item.fleet_operator ?? 0 : item.fleet_provider ?? 0;
    newInfo.detail_id = item.operator?.id ?? null;
    
    newInfo.validations = (item.normativities ?? []).map(normativity => ({
      id: normativity.type?.id ?? '',
      type: normativity.type?.description ?? '',
      value: normativity.status?.id ?? 0,
      description: normativity.status?.description ?? '',
      isConsidered: ""
    }));
    newInfo.norm_ids = (item.normativities ?? []).map(normativity => normativity.id);
    newInfo.validations = newInfo.validations.sort((a, b) => a.id - b.id);

    newInfo.tags = item.tags ?? [];
    newInfo.company_icon = "images/truck-bckgrnd.png";
    newInfo.network = item.network ?? [];
    newInfo.fleet_data_type = "Tracto camión T3"
    newInfo.sanctions = (item.sanctions ?? []).length > 0 ? item.sanctions ?? [] : null;
    newInfo.fleet_catalog_id = null;
    newInfo.user_id = isTR ? item.operator?.id ?? 0 : item.id

    operators.push(newInfo);
  });

  return operators
}

const NotAllowedContent = styled.div`
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
  font-size: 35;
`;
