import React from "react";
// import FleetNormativityRow from '../../shared/component/fleet/fleet-normativity-row';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  loadFleets,
  loadFleetData,
  loadHints,
  loadFleetDataTags,
  loadFleetClasses,
  loadNormativityTypes,
  DATA_TYPES,
  loadFleetDataNormativities,
  loadNormativityImagesForFleetData,
  loadGpsProvider,
  patchNormativityData,
  listEquipments,
  loadLoadShaftSuspensionTypes,
  getNormativitiesChanges,
  loadShareFleetData,
  loadShareFleetDataNormativities,
  loadShareNormativityImagesForFleetData,
  patchShareNormativityData,
  loadNormativitiesStatus,
} from "../../redux/reducers/fleet-reducer";
import {
  loadSession,
  loadStatusDescriptions,
  removeToast,
  setFleetCatalogTabSeleted,
} from "../../redux/reducers/global-catalog.reducer";
import { loadCompanyByOrbiId } from "../../redux/reducers/company-reducer";
import { getUrlsEnv } from "../../shared/backend-api";
import { loadTags } from "../../redux/reducers/tags.reducer";
import FleetNormativityRow from "../../shared/component/fleet/fleet-normativity-row";
import SVGIcon, { icons } from "../../shared/svg-icon";
import Button from "../../shared/controls/button";
import SelectOptionDialog from "../../shared/dialog/select-option-dialog";
import SelectInput from "../../shared/controls/select-input";
import FleetSpecificationCompactVehicle, {
  units,
} from "../../shared/component/fleet/fleet-specification-compact-vehicle";
// import NormativityItem from '../../shared/component/fleet/normativity-profile-item'
import { ToastContainer, toast } from "react-toastify";
import { getLastSession, DISPLAY_FORMAT, LOG_FORMAT, isPremiumSubscriptionCo } from "../../shared/utils";
import Tag from "../../shared/controls/tag";
import ExceedPlus from "../../shared/controls/exceed-plus";
import TruckIcon from "../../shared/component/truck-icon";
import TruckIconStatus from "../../shared/component/truck-icon-status";
import ReactTooltip from "react-tooltip";
import ValidatePerms from "../../shared/component/validate-perms";
import { updateDataNormativityStatus } from "../../redux/reducers/fleet-reducer";
import ConfirmDialog from "../../shared/dialog/confirm-dialog";
import styled from "styled-components";

class FleetEquipmentProfile extends React.Component {
  constructor() {
    super();

    this.showToast = (text) => {
      toast.success(
        <div>
          <img
            alt=""
            src="/images/white-check.svg"
            style={{ marginRight: "8px", marginLeft: "4px" }}
          />
          {text}
        </div>,
        {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          className: "my-toast-class",
        }
      );
    };
  }

  state = {
    showModal: false,
    rejectReason: 0,
    mounted: false,
    normativityImages: [],
    validated: 0,
    normativityUpdated: false,
    saveExit: false,
    isFreeSubscription: false
  };
  dataLoaded = false;
  equipmentLoaded = false;
  companyLoaded = false;
  fleetDataTagsLoaded = false;
  fleetDataNormativitiesLoaded = false;
  fleetDataNormativitiesImagesLoaded = false;

  componentDidUpdate() {
    if (
      this.props.match.params.dataId &&
      !this.dataLoaded &&
      this.props.fleetId
    ) {
      if (this.isShared()) {
        this.props
          .loadShareFleetData(
            parseInt(this.props.match.params.dataId),
            this.getShareFleetId(),
            1
          )
          .then(() => {
            this.setState({
              validated: this.props.fleetData.id
                ? this.props.fleetData.status.id
                : 0,
            });
            this.loadCompany();
          });
      } else {
        this.props
          .loadFleetData(
            parseInt(this.props.match.params.dataId),
            this.props.fleetId
          )
          .then(() => {
            this.setState({
              validated: this.props.fleetData.id
                ? this.props.fleetData.status.id
                : 0,
            });
            this.loadCompany();
          });
      }
      this.dataLoaded = true;
    }
    if (
      this.props.match.params.dataId &&
      !this.equipmentLoaded &&
      this.props.fleetId
    ) {
      this.props.loadEquipmentData(
        parseInt(this.props.match.params.dataId),
        this.props.fleetId
      );
      this.equipmentLoaded = true;
    }
    if (
      !this.fleetDataTagsLoaded &&
      this.props.match.params.dataId &&
      this.props.fleetId
    ) {
      this.props.loadFleetDataTags(
        this.props.fleetId,
        this.props.match.params.dataId
      );
      this.fleetDataTagsLoaded = true;
    }
    if (
      !this.fleetDataNormativitiesLoaded &&
      this.props.match.params.dataId &&
      this.props.fleetId
    ) {
      this.props.loadNormativitiesStatus(
        this.getShareFleetId(),
        this.props.match.params.dataId,
        this.isShared() ? 1 : 4
      );
      (this.isShared()
        ? this.props.loadShareFleetDataNormativities(
            this.getShareFleetId(),
            this.props.match.params.dataId,
            1
          )
        : this.props.loadFleetDataNormativities(
            this.props.fleetId,
            this.props.match.params.dataId
          )
      ).then(() => {
        if (
          !this.fleetDataNormativitiesImagesLoaded &&
          this.fleetDataNormativitiesLoaded &&
          this.props.match.params.dataId &&
          this.props.fleetId &&
          this.props.normativities.length > 0
        ) {
          this.props.normativities.forEach((n) => {
            (this.isShared()
              ? this.props.loadShareNormativityImagesForFleetData(
                  this.props.match.params.dataId,
                  this.getShareFleetId(),
                  n.id,
                  1
                )
              : this.props.loadNormativityImagesForFleetData(
                  this.props.match.params.dataId,
                  this.props.fleetId,
                  n.id
                )
            ).then((response) => {
              this.setState({
                normativityImages: this.state.normativityImages.concat(
                  response.response
                ),
              });
            });
          });
          this.fleetDataNormativitiesImagesLoaded = true;
        }
      });
      this.fleetDataNormativitiesLoaded = true;
    }
    if (this.props.toast) {
      this.props.removeToast();
      this.showToast(this.props.toast.text);
    }
  }

  componentDidMount() {
    this.setState({ mounted: true });

    if (this.props.isCO && !this.props.isPremiumSubscriptionCo) {
      this.setState({isFreeSubscription: true});
      return;
    }

    this.unblock = this.props.history.block((targetLocation) => {
      if (this.state.normativityUpdated) {
        this.setState({ saveExit: true });
        this.props
          .updateDataNormativityStatus(
            this.isShared() ? this.props.fleetData.provider_data.id : undefined,
            this.props.fleetData.fleet,
            this.props.fleetData.id
          )
          .then((r) => {
            this.setState(
              { saveExit: false, normativityUpdated: false },
              this.props.history.goBack()
            );
          });
      }
      return !this.state.normativityUpdated;
    });
  }

  componentWillUnmount() {
    this.unblock();
  }

  loadCompany = () => {
    if (!this.companyLoaded && this.props.sessionCompany.orbi_id) {
      if (this.isShared()) {
        this.props.loadCompanyById(this.props.fleetData.owner_company);
      } else {
        this.props.loadCompanyById(this.props.sessionCompany.orbi_id);
      }
      this.companyLoaded = true;
    }
  };

  isShared = () => {
    const { search = "" } = this.props.history.location;
    const variables = search === "" ? [] : search.substr(1).split("&");
    const result = variables.length > 0 ? variables[0].split("=") : [];
    if (Array.isArray(result) && result.length > 1) {
      return result[1] === "1";
    } else {
      return false;
    }
  };

  getShareFleetId() {
    if (this.isShared()) {
      const { search = "" } = this.props.history.location;
      const variables = search === "" ? [] : search.substr(1).split("&");
      const result = variables > 1 ? [] : variables[1].split("=");

      if (Array.isArray(result) && result.length > 1) {
        return String(result[1]);
      } else {
        return "0";
      }
    } else {
      return this.props.fleetId;
    }
  }

  getShareStatusLogContent = (data, key) => {
    const results = this.getNormativitiesStatus(data);

    if (Array.isArray(results) && results.length > 0) {
      const item = results[0];

      return (
        <React.Fragment>
          <ReactTooltip
            className={"truck-tooltip"}
            id={"status-log".concat(String(key))}
            getContent={() =>
              React.Children.toArray([
                <TruckIconStatus
                  title={item.name}
                  image={item.imageIcon}
                  date={item.date}
                  statusLabel={`${VALIDATED_MESSAGE[item.status]} ${item.comment}`}
                  statusIcon={VALIDATED_ICONS[item.status]}
                />,
              ])
            }
            delayHide={200}
            // delayShow={200}
            place={"right"}
            border={true}
            type={"light"}
          />

          <div data-tip data-for={"status-log".concat(String(key))}>
            <TruckIconStatus
              title={item.name}
              image={item.imageIcon}
              date={item.date}
              statusLabel={`${VALIDATED_MESSAGE[item.status]} ${item.comment}`}
              statusIcon={VALIDATED_ICONS[item.status]}
            />
          </div>
        </React.Fragment>
      );
    } else {
      return <div></div>;
    }
  };

  getStatusLogContent = (data, keyNormativity) => {
    return (
      <ExceedPlus
        maxVisibleItems={2}
        name={"status-".concat(`${keyNormativity}`)}
        onTooltipClassname="truck-tooltip"
      >
        {this.getNormativitiesStatus(data).map((item, id) => {
          return (
            <div key={keyNormativity}>
              <ReactTooltip
                className={"truck-tooltip"}
                id={"status-log".concat(item.name).concat(`${keyNormativity}`)}
                getContent={() =>
                  React.Children.toArray([
                    <TruckIconStatus
                      title={item.name}
                      image={item.imageIcon}
                      date={item.date}
                      statusLabel={`${VALIDATED_MESSAGE[item.status]} ${item.comment}`}
                      statusIcon={VALIDATED_ICONS[item.status]}
                    />,
                  ])
                }
                clickable={true}
                delayHide={200}
                // delayShow={200}
                // delayUpdate={500}
                place={"right"}
                border={true}
                type={"light"}
                effect="solid"
              />

              <div
                data-tip
                data-for={"status-log"
                  .concat(item.name)
                  .concat(`${keyNormativity}`)}
              >
                <TruckIconStatus
                  title={item.name}
                  image={item.imageIcon}
                  key={id}
                  date={item.date}
                  statusLabel={`${VALIDATED_MESSAGE[item.status]} ${item.comment}`}
                  statusIcon={VALIDATED_ICONS[item.status]}
                />
              </div>
            </div>
          );
        })}
      </ExceedPlus>
    );
  };

  getFreeSubscription = () => {
    return(
      <NotAllowedContent>
        {'La cuenta no tiene acceso a este modulo'}
      </NotAllowedContent>
    );
  }

  getMainContent() {
    return (
      <div className="equipment-profile view">
        <ToastContainer className="global-toast-container" />

        <div className="left-column">
          <div className="credentials panel">
            <div className="upper-row">
              <div className="profile-information">
                <div className="avatars">
                  <img
                    alt=""
                    src={`${
                      this.props.fleetData.photo_url || "/images/remolque.svg"
                    }`}
                    style={{
                      backgroundColor: "#FFF",
                      padding: "10px",
                      width: "60px",
                      height: "55px",
                    }}
                  />
                  <img
                    alt=""
                    src={`${
                      (this.props.company || {}).logo
                        ? getUrlsEnv().files.concat(this.props.company.logo)
                        : "/images/factory-bckgrnd.png"
                    }`}
                    className="company-logo"
                  />
                </div>
                <div className="edit">
                  {!this.isShared() && (
                    <div
                      className="edit-info"
                      onClick={() =>
                        this.props.history.push(
                          `/company/fleet/equipment/${this.props.match.params.dataId}`
                        )
                      }
                    >
                      <ValidatePerms
                        perms={[
                          {
                            id: "35",
                            perm: "u",
                          },
                        ]}
                      >
                        <img alt="" src="/images/edit-transparent.svg" />
                      </ValidatePerms>
                    </div>
                  )}
                </div>
              </div>
              <p className="id">
                {this.props.fleetData.economic_number} /{" "}
                {this.props.fleetData.plates}
              </p>
              <p className="description">
                {this.props.fleetData.model} {this.props.brandStr}{" "}
                {this.props.fleetData.year}
              </p>
              <p
                className={"validated ".concat(
                  VALIDATED_MESSAGE[this.state.validated]
                )}
              >
                {VALIDATED_MESSAGE[this.state.validated]}
              </p>
            </div>
            <div className="lower-row">
              {this.props.isDolly ? (
                ""
              ) : (
                <div className="half">
                  <p className="submodule-title">Número de serie:</p>
                  <p>{this.props.fleetData.niv}</p>
                </div>
              )}

              <div className={this.props.isDolly ? "full" : "half"}>
                {this.isShared() ? (
                  <React.Fragment>
                    <p className="submodule-title">Propietario:</p>
                    <p>
                      {(this.props.fleetData || { owner_company: "" })
                        .owner_company || ""}
                    </p>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <p className="submodule-title">Compartido con:</p>
                    <div
                      style={{
                        height: "50px",
                        marginTop: "-15px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <ExceedPlus
                        maxVisibleItems={3}
                        name={""}
                        onTooltipClassname="truck-tooltip"
                      >
                        {this.props.fleetShares.map((item, id) => {
                          return (
                            <React.Fragment>
                              <div
                                data-tip
                                data-for={"tooltip-truck-icon".concat(
                                  item.name
                                )}
                              >
                                <TruckIcon
                                  title={item.name}
                                  image={item.logo}
                                  key={id}
                                />
                              </div>
                              <ReactTooltip
                                className="truck-tooltip"
                                id={"tooltip-truck-icon".concat(item.name)}
                                // getContent={() => el}
                                clickable={true}
                                delayHide={600}
                                delayShow={200}
                                delayUpdate={500}
                                place={"bottom"}
                                // isCapture={true}
                                border={true}
                                type={"light"}
                                effect="solid"
                              >
                                {item.name}
                              </ReactTooltip>
                            </React.Fragment>
                          );
                        })}
                      </ExceedPlus>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
          {!this.isShared() && (
            <div className="tags panel">
              <span className="submodule-title">
                Etiquetas:&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
              {/* eslint-disable-next-line */}
              {this.props.fleetDataTags.map((tag) => {
                if (tag) {
                  return (
                    <Tag
                      key={tag.id}
                      id={tag.id}
                      title={tag.title} 
                      color={tag.color}
                    />
                  );
                }
              })}
            </div>
          )}
          <div className="specifications panel">
            <div className="upper-row">
              { (this.props.fleetData.fleet_class === 21 || this.props.fleetData.fleet_class == 22) ? <></> :
                <>
                  <p className="submodule-title">Especificaciones</p>
                  <FleetSpecificationCompactVehicle
                    // className={}
                    type={"EQUIPMENT"}
                    height={String(this.props.fleetData.height)}
                    width={String(this.props.fleetData.width)}
                    length={String(this.props.fleetData.length)}
                    weight={String(this.props.fleetData.weight)}
                    // singleMaxLoad={}
                    // doubleMaxLoad={}
                    unitSystem={units.systems[0]}
                  />
                </>
              }
            </div>
            <div className="lower-row">
              {this.props.isDolly ? (
                ""
              ) : (
                <>
                  <div>
                    <div className="submodule-title">Tipo / Clase:</div>
                    <div className="value">
                      {this.props.fleetData.fleet_data_type}
                    </div>
                  </div>
                  <div>
                    <div className="submodule-title">Carga máxima:</div>
                    <div className="value">
                      {this.props.fleetData.load} tons
                    </div>
                  </div>
                  <div>
                    <div className="submodule-title">No. de ejes:</div>
                    <div className="value">{this.props.fleetClass.axles}</div>
                  </div>
                  <div>
                    <div className="submodule-title">No. de llantas:</div>
                    <div className="value">{this.props.fleetClass.tires}</div>
                  </div>
                  <div>
                    <div className="submodule-title">
                      Suspensión eje de carga:
                    </div>
                    <div className="value">
                      {this.props.loadShaftSuspensionTypes.description}
                    </div>
                  </div>
                </>
              )}
              <div>
                <div className="submodule-title">
                  Sistema anti-bloqueo de frenos:
                </div>
                <div className="value">
                  {this.props.fleetData.abs ? "Si" : "No"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="right-column">
          <div className="normativity-panel">
            {this.mapNormativities().map((item, keyNormativity) => (
              <FleetNormativityRow
                ittm={item}
                key={item.key}
                documentStatus={item.status}
                documentStatusLocked={item.status === 4 || item.status === 5}
                title={item.description}
                title_icon={"/images/help.png"}
                shareID={item.shareId}
                iconElement={
                  <SVGIcon
                    name={item.iconName}
                    fill="#98BCDF"
                    className="icon"
                  />
                }
                details={item.details}
                images={item.images}
                onChangeStatus={(e) => {
                  e.target = {
                    name: e.target.name,
                    value: e.target.value,
                    id: e.target.id,
                    normId: item.normData,
                  };
                  return this.updateNormativityStatus(e);
                }}
                normId={item.normId}
                normData={item.normData}
                exceedPlusDiv={
                  <div
                    style={{
                      width: "110px",
                      height: "45px",
                      marginTop: "15px",
                    }}
                  >
                    {this.isShared()
                      ? this.getShareStatusLogContent(item, keyNormativity)
                      : this.getStatusLogContent(item, keyNormativity)}
                  </div>
                }
                documentLabel={item.documentLabel}
                isConsideredDisplayed={item.isConsideredDisplayed}
                isConsidered={item.isConsidered}
              />
            ))}
          </div>
          {/* {this.props.isDolly ? (
            ""
          ) : (
            <div className="normativity-panel">
              <FleetNormativityRow
                documentStatus={null}
                title={"Proveedor GPS"}
                title_icon={"/images/help.png"}
                iconElement={
                  <img
                    alt=""
                    style={{
                      width: "20px",
                      height: "20px",
                      alignSelf: "center",
                      marginLeft: "30px",
                      marginRight: "30px",
                    }}
                    src={"/images/location-pin.svg"}
                  />
                }
                details={[
                  {
                    name: "Proveedor:",
                    value: this.props.gpsProvider.name || "",
                  },
                  {
                    name: "Identificador:",
                    value: this.props.fleetData.gps_identifier || "",
                  },
                  {
                    name: "Nombre:",
                    value: this.props.fleetData.gps_contact || "",
                  },
                  {
                    name: "Correo:",
                    value: this.props.fleetData.gps_email || "",
                  },
                  {
                    name: "Teléfono:",
                    value: this.props.fleetData.gps_telephone || "",
                  },
                ]}
              />
            </div>
          )} */}
          <div className="actions">
            <Button
              onClick={() => this.props.history.goBack()}
              type={"primary"}
              text={"Finalizar"}
              name={"finish"}
            />
          </div>
        </div>
        <SelectOptionDialog
          open={this.state.showModal}
          title={"Razón de rechazo"}
          message={"Señala la razón por la cual se rechaza el documento."}
          options={[]}
          acceptText={"Aceptar"}
          acceptAction={this.handleModalClose}
          showCancel={true}
          cancelText={"Cancelar"}
          closeAction={() =>
            this.setState({ showModal: false, rejectReason: 0 })
          }
          class="footer-buttons-position"
          contentObject={
            <SelectInput
              label={"Razón rechazo"}
              name="reject"
              value={this.state.rejectReason}
              items={this.props.rejectReasons}
              onChange={this.handleRejectReason}
            />
          }
        />
        <ConfirmDialog
          open={this.state.saveExit || false}
          title="Actualizando"
          disabledAccept={true}
        />
      </div>
    );
  }

  render() {
    return( this.state.isFreeSubscription ? this.getFreeSubscription() : this.getMainContent() );
  }

  handleRejectReason = (e) => {
    this.setState({ rejectReason: e.target.value });
  };

  handleModalClose = (e) => {
    if (e && this.state.rejectReason) {
      this.setState({ normativityUpdated: true });
      if (this.isShared()) {
        this.props
          .updateShareNormativityStatus(
            this.getShareFleetId(),
            this.props.match.params.dataId,
            this.state.normativityId,
            {
              status: 3,
              reason: this.state.rejectReason,
              description: this.state.rejectReason,
            },
            1
          )
          .then((res) => {
            this.updateGlobalStatus(res);
            this.props.loadNormativitiesStatus(
              this.getShareFleetId(),
              this.props.match.params.dataId,
              this.isShared() ? 1 : 4
            );
          });
      } else {
        this.props
          .updateNormativityStatus(
            this.props.fleetId,
            this.props.match.params.dataId,
            this.state.normativityId,
            {
              status: 3,
              reason: this.state.rejectReason,
              description: this.state.rejectReason,
            }
          )
          .then((res) => {
            this.updateGlobalStatus(res);
            this.props.loadNormativitiesStatus(
              this.props.fleetId,
              this.props.match.params.dataId,
              this.isShared() ? 1 : 4
            );
          });
      }
    }
    this.setState({
      showModal: false,
      rejectReason: 0,
    });
  };

  updateGlobalStatus = (data) => {
    if (data.type && data.type === "FLEET_NORMATIVITY_PATCH_SUCCESS") {
      const { response = { global_status: {} } } = data;
      const { global_status } = response;

      if (global_status.id) {
        this.setState({ validated: parseInt(global_status.id) });
      }
    }
  };

  updateNormativityStatus = (e) => {
    let imageExist = false;
    this.state.normativityImages.forEach((n) => {
      if (this.isShared()) {
        if (
          String(n.normativity) === String(e.target.normId) &&
          n.description !== null
        ) {
          imageExist = true;
        }
      } else {
        if (
          String(n.normativity) === String(e.target.id) &&
          n.description !== null
        ) {
          imageExist = true;
        }
      }
    });

    if (
      imageExist &&
      (e.target.value.toString() === "2" || e.target.value.toString() === "3")
    ) {
      if (e.target.value.toString() === "3" && this.state.mounted) {
        this.setState({
          showModal: true,
          normativityId: e.target.id,
        });
      } else {
        this.setState({ normativityUpdated: true });
        if (this.isShared()) {
          this.setState({saveExit: true});
          this.props
            .updateShareNormativityStatus(
              this.getShareFleetId(),
              this.props.match.params.dataId,
              e.target.id,
              { status: parseInt(e.target.value) },
              1
            )
            .then((res) => {
              this.setState({saveExit: false});
              this.updateGlobalStatus(res);
              this.props.loadNormativitiesStatus(
                this.getShareFleetId(),
                this.props.match.params.dataId,
                this.isShared() ? 1 : 4
              );
            });
        } else {
          this.setState({saveExit: true});
          this.props
            .updateNormativityStatus(
              this.props.fleetId,
              this.props.match.params.dataId,
              e.target.id,
              { status: parseInt(e.target.value) }
            )
            .then((res) => {
              this.setState({saveExit: false});
              this.updateGlobalStatus(res);
              this.props.loadNormativitiesStatus(
                this.props.fleetId,
                this.props.match.params.dataId,
                this.isShared() ? 1 : 4
              );
            });
        }
      }
    } else {
      return;
    }
  };

  getNormativitiesStatus = (data) => {
    let n =
      this.props.fleetsStatusLog.filter((n) => {
        // const { object_id: { id = "" } = { id: "" } } = n;
        const id = n.object_id?.id || "";
        return String(id) === String(data.normId);
      }) || [];

    let statusResults = [];
    let companyAdded = [];

    n.forEach((n) => {
      const {
        user = {},
        data_after: { id = 1 },
        company = {},
        log_date = "",
        comment = "",
      } = n;

      if (this.isShared()) {
        statusResults.push({
          imageIcon: company.logo || "",
          name:
            String(user.first_name || "") +
            " " +
            String(user.last_name || "") +
            " - " +
            String(company.orbi_id || ""),
          date: getLastSession(log_date, LOG_FORMAT),
          status: id,
          comment: comment || "",
        });
      } else {
        if (!companyAdded.includes(company.orbi_id)) {
          companyAdded.push(company.orbi_id);
          statusResults.push({
            imageIcon: company.logo || "",
            name:
              String(user.first_name || "") +
              " " +
              String(user.last_name || "") +
              " - " +
              String(company.orbi_id || ""),
            date: getLastSession(log_date, LOG_FORMAT),
            status: id,
            comment: comment || "",
          });
        }
      }
    });

    return statusResults;
  };

  getNumberTitleNormativity = (type) => {
    switch (type) {
      case 6:
        return "No. de tarjeta de circulación:";

      case 7:
        return "No. de póliza de seguro:";

      case 8:
        return "No. de autorización expresa:";

      case 9:
        return "No. de certificado:";

      default:
        return "Número:";
    }
  };

  getNumberTitleDocument = (type) => {
    switch (type) {
      case 6:
        return "Tarjeta de circulación:";

      case 7:
        return "Póliza de seguro:";

      case 8:
        return "Autorización expresa:";

      case 9:
        return "Certificado:";

      default:
        return "Documentos:";
    }
  };

  mapNormativities = () => {
    return this.props.normativityTypes
      .filter((nT) => {
        if (this.props.isDolly && (nT.id === 8 || nT.id === 6)) {
          return false;
        }
        return true;
      })
      .map((nt) => {
        let n = this.props.normativities.find((n) => n.type === nt.id) || {is_considered: false};
        let imgs = (this.state.normativityImages || []).filter(
          (i) => i.normativity === n.id
        );

        let details = [
          {
            name: this.getNumberTitleNormativity(n.type),
            value: n.code || "",
          },
          {
            name: "Expedición:",
            value: getLastSession(n.expedition, DISPLAY_FORMAT),
          },
          {
            name: "Expiración:",
            value: getLastSession(n.expiration, DISPLAY_FORMAT),
          },
        ];

        if(nt.description.includes("seguro")){
          details.push({
            name: "Aseguradora:",
            value: n.data?.assurance_company || ''
          })
        }

        return {
          shareId: n.shared_normativity_id || "",
          description: nt.description,
          images: imgs,
          iconName: this.normativityIcons(nt.id),
          normId: n.id,
          normData: n.normativity_data,
          key: nt.id,
          status: n.status,
          details,
          documentLabel: this.getNumberTitleDocument(n.type),
          isConsideredDisplayed: nt.is_considered_displayed,
          isConsidered: n.is_considered,
        };
      });
  };
  normativityIcons = (normativityId) => {
    switch (normativityId) {
      case 1:
      case 6:
        return icons.normativityCard;
      case 2:
      case 7:
        return icons.normativityShield;
      case 3:
      case 8:
        return icons.normativityBox;
      case 4:
      case 9:
        return icons.normativityTool;
      case 5:
        return icons.normativityBubble;
      default:
        return icons.user;
    }
  };
}

const mapStateToProps = (state) => {
  let fleetData = state.fleetReducer.fleetData.data || defaultData.fleetData;
  let fleetDataEquipment = (state.fleetReducer.equipment.data || [{}])[0] || {};
  //console.log(state);
  return {
    fleetsStatusLog: state.fleetReducer.normativityStatusLog.data || [],
    fleetShares: Array.isArray(fleetData.network) ? fleetData.network : [],
    toast: state.globalCatalog.toast,
    fleetId: ((state.fleetReducer.fleets.data || [{}])[0] || {}).id,
    fleetData,
    fleetDataEquipment,
    sessionCompany: state.globalCatalog.session.company || {},
    company: (state.companyReducer.company || {}).data || {},
    brandStr: Array.isArray(state.fleetReducer.hints.data)
      ? state.fleetReducer.hints.data.find((h) => h.id === fleetData.brand) !==
        undefined
        ? state.fleetReducer.hints.data.find((h) => h.id === fleetData.brand)
            .description
        : ""
      : "",
    fleetDataTags: (state.fleetReducer.dataTags.data || []).map((fdt) =>
      state.tags.tags.find((tr) => tr.id === fdt.tags)
    ),

    fleetClass:
      ((state.fleetReducer.fleetClass || {}).data || []).find(
        (v) => v.id === fleetData.fleet_class
      ) || {},
    isDolly:
      (
        ((state.fleetReducer.fleetClass || {}).data || []).find(
          (v) => v.id === fleetData.fleet_class
        ) || { description: "" }
      ).description.toLowerCase().includes("dolly"),
    loadShaftSuspensionTypes:
      (state.fleetReducer.loadShaftSuspensionTypes.data || []).find(
        (l) => l.id === fleetDataEquipment.load_shaft_suspension
      ) || {},

    normativityTypes: (state.fleetReducer.normativityType.data || []).filter(
      (n) => n.type === DATA_TYPES.EQUIPMENT
    ),
    normativities: ((state.fleetReducer.normativities || {}).data || []).map(
      (n) => {
        delete n.created;
        delete n.modified;
        delete n.visible;
        return n;
      }
    ),
    gpsProvider:
      (state.fleetReducer.gpsProvider.data || []).find(
        (p) => p.id === fleetData.gps_provider
      ) || {},
    rejectReasons: state.globalCatalog.statusDescription.data || [],
    isCO: (state.globalCatalog.session.company.type.id || 0) === 2,
    isPremiumSubscriptionCo: isPremiumSubscriptionCo(state.globalCatalog.session)
  };
};
const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());
  dispatch(loadFleets());
  dispatch(loadTags());
  dispatch(loadNormativityTypes());
  dispatch(loadHints());
  dispatch(loadFleetClasses());
  dispatch(loadLoadShaftSuspensionTypes());

  dispatch(loadGpsProvider());
  dispatch(loadStatusDescriptions());

  return {
    loadCompanyById: (orbiId) => dispatch(loadCompanyByOrbiId(orbiId)),
    loadFleetData: (fleetDataId, fleetId) =>
      dispatch(loadFleetData(fleetDataId, fleetId)),
    loadShareFleetData: (fleetDataId, fleetId, type) =>
      dispatch(loadShareFleetData(fleetDataId, fleetId, type)),
    loadEquipmentData: (fleetDataId, fleetId) =>
      dispatch(listEquipments(fleetDataId, fleetId)),
    loadFleetDataTags: (fleet_id, fleet_data_id) =>
      dispatch(loadFleetDataTags(fleet_id, fleet_data_id)),
    loadFleetDataNormativities: (fleet_id, fleet_data_id) =>
      dispatch(loadFleetDataNormativities(fleet_id, fleet_data_id)),
    loadShareFleetDataNormativities: (fleet_id, fleet_data_id, type) =>
      dispatch(loadShareFleetDataNormativities(fleet_id, fleet_data_id, type)),
    loadShareNormativityImagesForFleetData: (
      fleetDataId,
      fleetId,
      NormativityId,
      type
    ) =>
      dispatch(
        loadShareNormativityImagesForFleetData(
          fleetDataId,
          fleetId,
          NormativityId,
          type
        )
      ),
    loadNormativityImagesForFleetData: (fleetDataId, fleetId, NormativityId) =>
      dispatch(
        loadNormativityImagesForFleetData(fleetDataId, fleetId, NormativityId)
      ),
    updateNormativityStatus: (fleetId, fleetDataId, id, normativityDataObj) =>
      dispatch(
        patchNormativityData(fleetId, fleetDataId, id, normativityDataObj)
      ),
    updateShareNormativityStatus: (
      fleetId,
      fleetDataId,
      id,
      normativityDataObj,
      type
    ) =>
      dispatch(
        patchShareNormativityData(
          fleetId,
          fleetDataId,
          id,
          normativityDataObj,
          type
        )
      ),
    loadNormativitiesStatus: (fleet_id, fleet_data_id, type) =>
      dispatch(loadNormativitiesStatus(fleet_id, fleet_data_id, type)),
    removeToast: () => dispatch(removeToast()),
    getNormativitiesChanges: (id) => dispatch(getNormativitiesChanges(id)),
    updateDataNormativityStatus: (companyId, fleetId, dataId) =>
      dispatch(updateDataNormativityStatus(companyId, fleetId, dataId)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FleetEquipmentProfile)
);

const defaultData = {
  fleetData: {
    brand: 0,
  },
};

const VALIDATED_MESSAGE = [
  "",
  "Pendiente",
  "Validado",
  "Rechazado",
  "Incompleto",
  "Vencido",
];

const VALIDATED_ICONS = [
  "",
  "/images/info_pendiente.svg",
  "/images/oval green.svg",
  "/images/interrogacion_red.svg",
  "/images/interrogacion.svg",
  "/images/interrogacion.svg",
];

const NotAllowedContent = styled.div`
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
  font-size: 35;
`;