import * as React from "react";
import { getUrlsEnv } from "../../shared/backend-api";

class ProductRow extends React.Component {
  state = {}

  static defaultProps = {
    productInfo: {},
    removeProduct: () => {},
    unitCatalog: []
  }

  render() {
    return (
      <div className={"product-row ".concat(this.props.className)}>
        <div className="avatar" style={{width: "30%"}}>
          {
            !this.props.emptyImage &&
            <img 
              alt="" 
              src={this.props.productInfo?.product?.url || "/images/interrogacion.svg"}
              onError={(e)=>{e.target.onerror = null; e.target.src="/images/interrogacion.svg"}}
              className={this.props.productInfo?.product?.url ? "" : "default"}
            />
          }
          <div>
            {this.props.productInfo?.product?.description}
          </div>
        </div>
        <div style={{width: "19%"}}>
          {this.props.productInfo?.product?.sku}
        </div>
        <div style={{width: "10%"}}>
          {this.props.qty}
        </div>
        <div style={{width: "9%"}}>
          {''}
        {/* {(this.props.unitCatalog.find(u => u.id === this.props.productInfo?.product?.product_unit) || {description: ""}).description} */}
        </div>
        <div style={{width: "10%"}}>
        {this.props.productInfo?.product?.gross_weight} {" kg"} {/*(this.props.unitCatalog.find(u => u.id === this.props.productInfo?.product?.net_weight_unit) || {code: ""}).code*/}
        </div>
        <div style={{width: "10%"}}>
        {this.props.productInfo?.product?.net_weight} {" kg"} {/*(this.props.unitCatalog.find(u => u.id === this.props.productInfo?.product?.gross_weight_unit) || {code: ""}).code*/}
        </div>
        <div style={{width: "10%"}}>
        {this.props.productInfo?.product?.volume} {" cbm"} {/*(this.props.unitCatalog.find(u => u.id === this.props.productInfo?.product?.volume_unit) || {code: ""}).code*/}
        </div>
        <div style={{width: "2%"}}>
          {
            !this.props.emptyActions && 
            <img
              alt=""
              src="/images/bin.png"
              style={{ cursor: "pointer"}}
              onClick={() => {this.props.removeProduct(this.props.productInfo.id)}}
            />
          }
        </div>
      </div>
    )
  }
}

export default ProductRow;