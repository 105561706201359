export const BACKEND = {
  SERVER: getUrlsEnv('orbiback.').api,
  FILES_SERVER: getUrlsEnv('orbiback.').files,
  API: "/api/",
  DOCS: "/api/docs",

  SESSION: {
    REFRESH_TOKEN: "v1/session/refresh/",
    REFRESH: "v2/refresh/",
  },

  PERMISSIONS: {
    GET_PERMISSION_FILE:
      getUrlsEnv('orbiback.').files + "permission/user/{company_id}/{user_id}.json",
  },

  PUBLIC: {
    COUNTRY: "v1/public/country/",
    STATE: "v1/public/state/",
    CITY: "v1/public/city/",
    TIME_ZONES: "v1/public/time/zone/",
    TAG_FACILITY: "v1/public/tag/facility/",
    AVATAR: "v1/public/avatar/",
    SIGNUP: "v1/session/signup/",
    ASSIGN_COMPANY: "v1/session/assigncompany/",
    CREATE_COMPANY: "v1/company/",
    GET_COMPANY: "v1/company/{id_orbi}/",
    PUT_COMPANY: "v1/company/",
    VALIDATE_EMAIL: "v1/session/recovery/",
    COMPANY_TYPE: "v2/companies/catalogs/company_type/",
    USERS: "v1/users/",
    USERS_V2: "v2/users/",
    // GET_USERS: "v1/users/{id}/",
    SUGGESTED_COMPANIES: "v2/companies/?view=suggested",
    ACCEPTED_COMPANIES: "v2/companies/?view=accepted",
    SEND_INVITATION: "v2/users/{user_id}/?request_company={request_company}",
    RESEND_EMAIL_VALIDATION: "v2/users/?view=resendUserValidation",
    UPDATE_PASSWORD: "v2/users/changepassword/",
  },

  COMPANY_CATALOG: {
    // USER_TYPE: 'v2/companies/catalogs/user_type',
    USER_TYPE: "v2/companies/{company_id}/?user_types=true",
    ACCOUNT_STATUS: "v2/companies/catalogs/account_status",
    ASSIGN:
      "v2/companies/{company_id}/users/{user_id}/?assign_company=true&users_type=4",
    FLEET_CLASS: "v2/companies/catalogs/fleet_class/",
    FLEET_PROVIDER: "v2/companies/{company_id}/fleet_provider/",
    // EQUIPMENT_TYPE: 'v2/commons/choices/equipment_type/',
    COMPANY_TYPE:
      "v2/companies/{company_id}/fleets/catalog/{type_catalog}/?view=shared_companies",
    VEHICLE_TYPE: "v2/companies/catalogs/vehicle_type/",
    OPERATOR_TYPE: "v2/companies/catalogs/operator_licence_type/",
    GET_SANCTIONS: "v2/companies/catalogs/fleet_lock_motives/?type=3",
    SET_SANCTIONS: "v2/companies/{company_id}/fleet_provider/{provider_id}/operators/{operator_id}/sanctions/",
    DEL_SANCTIONS: "v2/companies/{company_id}/fleet_provider/{provider_id}/operators/{operator_id}/sanctions/{sanction_id}/",
    NORMATIVITY_TYPE: "v2/companies/catalogs/normativity_type/",
    OPERATOR_NORMATIVITY_TYPE:
      "v2/companies/catalogs/operator_normativity_type/",
    OPERATE_TYPE: "v2/companies/catalogs/operator_fit_to_operate_type/",
    NORMATIVITY_STATUS: "v2/companies/catalogs/fleet_status/",
    GPS_PROVIDER: "v2/companies/catalogs/gps_provider/",
    PERMISSIONS: "v2/companies/catalogs/module_permission/",

    HINT: "v2/companies/catalogs/hint/",
    DRIVE_AXLE_SUSPENSION: "v2/companies/catalogs/drive_axle_suspension_type/",
    STEERING_AXLE_SUSPENSION:
      "v2/companies/catalogs/steering_axle_suspension_type/",
    NORMATIVITY_STATUS_DESCRIPTION: "v2/companies/catalogs/status_description/",
    LOAD_SHAFT_SUSPENSION: "v2/companies/catalogs/load_shaft_suspension_type/",
    SHARE_EQUIPMENTS:
      "v2/companies/{company_id}/fleet_provider/{provider_id}/equipments/",
    SHARE_VEHICLES:
      "v2/companies/{company_id}/fleet_provider/{provider_id}/vehicles/",
    SHARE_OPERATORS:
      "v2/companies/{company_id}/fleet_provider/{provider_id}/operators/",
    EXPEDITION_CENTER: "v2/companies/catalogs/expedition_center/",
  },

  FILTER: {},

  FACILITY: {
    GET_BY_CODE: "v1/facility_catalog/{code}/",
    GET_CATALOG: "v1/facility_catalog/?limit={limit}&offset={offset}",

    PUT_BY_CODE: "v1/facility_catalog/{code}/",

    GET_CATALOG_FILTERS:
      "v1/facility_catalog/?tags={tags}&locations={locations}&capacity={capacity}&status={status}",

    GET_STAFF:
      "v1/facility_catalog/staff/{id_facility}/?limit={limit}&page={page}",
    // DELETE_STAFF: "v1/facility_catalog/staff/{id_facility}/delete_user/",
    SEARCH_STAFF: "v1/facility_catalog/staff/{id_facility}?user={user}",
    INVITE_STAFF: "v1/facility_catalog/staff/{id_facility}/send_invitations/",

    GET_GI_TYPES: "v1/facility_catalog/gi/type/",
    GET_EQUIPMENT_TYPES: "v1/facility_catalog/equipment/type/",
    GET_RAMP_TYPES: "v1/facility_catalog/ramp/type/",

    GET_NOTIFICATIONS:
      "v1/facility_catalog/notifications/from_facility/{id_facility}/",
    SET_NOTIFICATION:
      "v1/facility_catalog/notifications/set_facilitynotification/",
    SET_USER_NOTIFICATION:
      "v1/facility_catalog/notifications/set_usernotification/",

    GET_ALERTS: "v1/facility_catalog/alerts/from_facility/{id_facility}/",
    SET_ALERT: "v1/facility_catalog/alerts/set_facilityalert/",
    SET_USER_ALERT: "v1/facility_catalog/alerts/set_useralert/",
    RESET_ALERT: "v1/facility_catalog/alerts/delete_users_alerts/",
    GEO: {
      POST: "v1/facility_catalog/geo/",
      GET_BY_FACILITY_ID: "v1/facility_catalog/geo/facility/{facility_id}/",
      POST_POLYGON: "v1/facility_catalog/geo/{facility_gi_id}/data",
      DELETE: "v1/facility_catalog/geo/{facility_gi_id}/",
      PUT: "v1/facility_catalog/geo/{facility_gi_id}/",
    },
    RAMP: {
      POST: "v1/facility_catalog/geo/{facility_gi_id}/ramp",
      POST_WINDOW:
        "v1/facility_catalog/geo/{facility_gi_id}/ramp/{ramp_id}/windows",
      POST_WINDOW_CAPACITY:
        "v1/facility_catalog/geo/{facility_gi_id}/ramp/{ramp_id}/window/{window_id}/capacity",
      GET_BY_GEO_ID: "v1/facility_catalog/geo/{facility_gi_id}/ramp",
      PUT: "v1/facility_catalog/geo/{facility_gi_id}/ramp/{ramp_id}",
      PUT_WINDOW:
        "v1/facility_catalog/geo/{facility_gi_id}/ramp/{ramp_id}/window/{window_id}",
      DELETE_WINDOW:
        "v1/facility_catalog/geo/{facility_gi_id}/ramp/{ramp_id}/window/{window_id}",
      DELETE_WINDOW_CAPACITY:
        "v1/facility_catalog/geo/{facility_gi_id}/ramp/{ramp_id}/window/{window_id}/capacity",
    },
    GET_FACILITY_LOCATIONS: "v1/company/facility_locations/",

    // FACILITY V2

    POST: "v2/companies/{company_id}/facilities/",
    PUT: "v2/companies/{company_id}/facilities/{facility_id}/",
    // POST: "v1/facility_catalog/",
    // PUT: "v1/facility_catalog/",
    GET: "v2/companies/{company_id}/facilities/{facility_id}/",
    LIST: "v2/companies/{company_id}/facilities/?view=short_detail",
    CATALOG:
      "v2/companies/{company_id}/facilities/?limit={limit}&offset={offset}&view=catalog&tags={tags}&locations={locations}&capacity={capacity}&status={status}&text={text}",
    CATALOG_SIMPLE: "v2/companies/{company_id}/facilities/?view=catalog",
    NOTIFICATIONS: {
      LIST: "v2/companies/{company_id}/facilities/{facility_id}/notifications/",
      POST: "v2/companies/{company_id}/facilities/{facility_id}/notifications/",
      PUT:
        "v2/companies/{company_id}/facilities/{facility_id}/notifications/{id}/",
      LIST_MAILS:
        "v2/companies/{company_id}/facilities/{facility_id}/notifications/{id}/?view=emails",
      EMAIL: {
        POST:
          "v2/companies/{company_id}/facilities/{facility_id}/notifications/{notification_facility_id}/email_list/",
        DELETE:
          "v2/companies/{company_id}/facilities/{facility_id}/notifications/{notification_facility_id}/email_list/{id}/",
      },
      USER: {
        POST:
          "v2/companies/{company_id}/facilities/{facility_id}/notifications/{notification_facility_id}/user_list/",
        DELETE:
          "v2/companies/{company_id}/facilities/{facility_id}/notifications/{notification_facility_id}/user_list/{id}/",
        PUT:
          "v2/companies/{company_id}/facilities/{facility_id}/notifications/{notification_facility_id}/user_list/{id}/",
      },
    },
    ALERTS: {
      GET: "v2/companies/{company_id}/facilities/{facility_id}/alerts/{id}",
      LIST: "v2/companies/{company_id}/facilities/{facility_id}/alerts/",
      POST: "v2/companies/{company_id}/facilities/{facility_id}/alerts/",
      PUT: "v2/companies/{company_id}/facilities/{facility_id}/alerts/{id}/",
      DELETE: "v2/companies/{company_id}/facilities/{facility_id}/alerts/{id}/",
      LIST_MAILS:
        "v2/companies/{company_id}/facilities/{facility_id}/alerts/{alert_facility_id}/?view=emails",
      EMAIL: {
        POST:
          "v2/companies/{company_id}/facilities/{facility_id}/alerts/{alert_facility_id}/email_list/",
        DELETE:
          "v2/companies/{company_id}/facilities/{facility_id}/alerts/{alert_facility_id}/email_list/{id}/",
        LIST:
          "v2/companies/{company_id}/facilities/{facility_id}/alerts/{alert_facility_id}/email_list/",
      },
      USER: {
        POST:
          "v2/companies/{company_id}/facilities/{facility_id}/alerts/{alert_facility_id}/user_list/",
        DELETE:
          "v2/companies/{company_id}/facilities/{facility_id}/alerts/{alert_facility_id}/user_list/{id}/",
        PUT:
          "v2/companies/{company_id}/facilities/{facility_id}/alerts/{alert_facility_id}/user_list/{id}/",
        LIST:
          "v2/companies/{company_id}/facilities/{facility_id}/alerts/{alert_facility_id}/user_list/",
      },
    },

    SPACES: {
      GET: "v2/companies/{company_id}/facilities/{facility_id}/spaces/",
      LIST: "v2/companies/{company_id}/facilities/{facility_id}/spaces/",
      POST: "v2/companies/{company_id}/facilities/{facility_id}/spaces/",
      DELETE: "v2/companies/{company_id}/facilities/{facility_id}/spaces/{id}/",
      PATCH: "v2/companies/{company_id}/facilities/{facility_id}/spaces/{id}/",
      CALENDAR:
        "v2/companies/{company_id}/facilities/{facility_id}/spaces/?view=calendar&date={date}",
      CAPACITY_SUMMARY:
        "v2/companies/{company_id}/facilities/{facility_id}/spaces/?view=capacity&date={date}",
      TAGS: {
        LIST:
          "v2/companies/{company_id}/facilities/{facility_id}/spaces/{space_id}/tags/",
        POST:
          "v2/companies/{company_id}/facilities/{facility_id}/spaces/{space_id}/tags/",
        DELETE:
          "v2/companies/{company_id}/facilities/{facility_id}/spaces/{space_id}/tags/{id}",
      },
      WINDOWS: {
        LIST:
          "v2/companies/{company_id}/facilities/{facility_id}/spaces/{space_id}/windows/",
        POST:
          "v2/companies/{company_id}/facilities/{facility_id}/spaces/{space_id}/windows/",
        DELETE:
          "v2/companies/{company_id}/facilities/{facility_id}/spaces/{space_id}/windows/{id}/",
        PATCH:
          "v2/companies/{company_id}/facilities/{facility_id}/spaces/{space_id}/windows/{id}/",
        CAPACITIES: {
          LIST:
            "v2/companies/{company_id}/facilities/{facility_id}/spaces/{space_id}/windows/{window_id}/capacities/",
          POST:
            "v2/companies/{company_id}/facilities/{facility_id}/spaces/{space_id}/windows/{window_id}/capacities/",
          DELETE:
            "v2/companies/{company_id}/facilities/{facility_id}/spaces/{space_id}/windows/{window_id}/capacities/{id}/",
          PATCH:
            "v2/companies/{company_id}/facilities/{facility_id}/spaces/{space_id}/windows/{window_id}/capacities/{id}/",
          TRANSPORT: {
            LIST:
              "v2/companies/{company_id}/facilities/{facility_id}/spaces/{space_id}/windows/{window_id}/capacities/{capacity_id}/transport/",
            POST:
              "v2/companies/{company_id}/facilities/{facility_id}/spaces/{space_id}/windows/{window_id}/capacities/{capacity_id}/transport/",
            DELETE:
              "v2/companies/{company_id}/facilities/{facility_id}/spaces/{space_id}/windows/{window_id}/capacities/{capacity_id}/transport/{id}/",
          },
        },
      },

      SCHEDULES: {
        LIST:
          "v2/companies/{company_id}/facilities/{facility_id}/spaces/{space_id}/schedule/",
        POST:
          "v2/companies/{company_id}/facilities/{facility_id}/spaces/{space_id}/schedule/",
        DELETE:
          "v2/companies/{company_id}/facilities/{facility_id}/spaces/{space_id}/schedule/{id}/",
        PATCH:
          "v2/companies/{company_id}/facilities/{facility_id}/spaces/{space_id}/schedule/{id}/",
        CAPACITIES: {
          LIST:
            "v2/companies/{company_id}/facilities/{facility_id}/spaces/{space_id}/schedule/{schedule_id}/capacity/",
          POST:
            "v2/companies/{company_id}/facilities/{facility_id}/spaces/{space_id}/schedule/{schedule_id}/capacity/",
          DELETE:
            "v2/companies/{company_id}/facilities/{facility_id}/spaces/{space_id}/schedule/{schedule_id}/capacity/{id}/",
          PATCH:
            "v2/companies/{company_id}/facilities/{facility_id}/spaces/{space_id}/schedule/{schedule_id}/capacity/{id}/",
          TRANSPORT: {
            LIST:
              "v2/companies/{company_id}/facilities/{facility_id}/spaces/{space_id}/schedule/{schedule_id}/capacity/{capacity_id}/transport/",
            POST:
              "v2/companies/{company_id}/facilities/{facility_id}/spaces/{space_id}/schedule/{schedule_id}/capacity/{capacity_id}/transport/",
            DELETE:
              "v2/companies/{company_id}/facilities/{facility_id}/spaces/{space_id}/schedule/{schedule_id}/capacity/{capacity_id}/transport/{id}/",
          },
        },
      },
    },

    DELETE: "v2/companies/{company_id}/facilities/{facility_id}/",
    LOCATIONS: "v2/companies/{company_id}/facilities/locations/",
    VALIDATE: {
      UNIQUE: "v2/companies/{company_id}/facilities/?{field}={value}",
      // CODE: "v2/companies/{company_id}/facilities/?code={code}",
      // ALIAS: "v2/companies/{company_id}/facilities/?alias={alias}",
      // NAME: "v2/companies/{company_id}/facilities/?name={name}"
    },
  },

  // V2

  USERS: {
    GET: "v2/users/",
    REQUESTS: "v2/users/?view=requests",
    LOGIN: "v2/login/",
    RECOVER: "v2/recover/",
    RESET: "v2/reset/",
    SIGN_UP: {
      POST: "v2/signup/",
      CHECK_EMAIL: "v2/signup/?checkemail={email}",
    },
    PERMISSIONS: {
      LIST:
        "v2/companies/{company_id}/companies_users/?user={user_id}&role_detail=true",
      POST: "v2/companies/{company_id}/users/{user_id}/permissions/", //body -> permissions:[{module_permission: 1, p_create: false,...}]
    },
    DATA: {
      LIST:
        "v2/companies/{company_id}/users/?view=catalog&limit={limit}&offset={offset}&type={userType}&facilities={facilities}&roles={roles}&account_status={account_status}&text={text}",
      GET: "v2/companies/{company_id}/users/{user_id}/?view=profile",
      GET_BY_EMAIL: "v2/users/?email={email}&get_invitations={invitation}&company_id={company_id}&from_recommendations={from_recommendations}",
    },
    HISTORICAL: {
      LIST:
        "v2/companies/{company_id}/users/?view=historical&limit={limit}&offset={offset}&type={userType}&facilities={facilities}&roles={roles}&account_status={account_status}&search={text}&start_date={start_date}&end_date={end_date}",
      DOWNLOAD:
        "v2/companies/{company_id}/users/?view=historical_download&limit={limit}&offset={offset}&type={userType}&facilities={facilities}&roles={roles}&account_status={account_status}&search={text}&start_date={start_date}&end_date={end_date}",
    },
    MOBILE: {
      POST: "v2/mobile_users/",
      GET: "v2/mobile_users/{user_id}/",
      VALIDATE_USER: "v2/mobile_users/?username={username}",
    },
    CATALOG:
      "v2/users/catalog/?offset={offset}&limit={limit}&companyid={companyId}&accountstatus={accountStatusId}&role={roleId}&facilities={facilitiesId}",
  },

  FLEETS: {
    POST: "v2/companies/{company}/fleets/",
    GET: "v2/companies/{company}/fleets/",
    CATALOG:
      "v2/companies/{company_id}/fleets/catalog/{type_id}/?page={page}&limit={limit}&tags={tags}&status={status}&companies={companies}&type={type}&gps={gps}&text={text}",
    // STATUS_LOG:
    //   "v2/companies/{company_id}/fleets/catalog/{type_id}/?view=statuslog",
    STATUS_LOG:
      "v2/companies/{company_id}/fleets/catalog/{type_id}/?view=statuslog&page={page}&limit={limit}&tags={tags}&status={status}&companies={companies}&type={type}&gps={gps}&text={text}",
    DATA: {
      POST: "v2/companies/{company_id}/fleets/{fleet_id}/data/",
      PUT: "v2/companies/{company_id}/fleets/{fleet_id}/data/{id}/",
      UPDATE_NORMATIVITY_STATUS:
        "v2/companies/{company_id}/fleets/{fleet_id}/data/{id}/?view=update",
      GET: "v2/companies/{company_id}/fleets/{fleet_id}/data/{data_id}/",
      SHARE: {
        VEHICLE:
          "v2/companies/{company_id}/fleet_provider/{fleet_id}/vehicles/{data_id}/",
        EQUIPMENT:
          "v2/companies/{company_id}/fleet_provider/{fleet_id}/equipments/{data_id}/",
        OPERATOR:
          "v2/companies/{company_id}/fleet_provider/{fleet_id}/operators/{id}/",
      },
      VALIDATIONS: {
        ECONOMIC:
          "v2/companies/{company_id}/fleets/{fleet_id}/data/?economic_number={economic_number}",
        NIV: "v2/companies/{company_id}/fleets/{fleet_id}/data/?niv={niv}",
        PLATE:
          "v2/companies/{company_id}/fleets/{fleet_id}/data/?plates={plates}",
        GPS:
          "v2/companies/{company_id}/fleets/{fleet_id}/data/?gps_identifier={gps_identifier}",
        ENGINE:
          "v2/companies/{company_id}/fleets/{fleet_id}/data/?engine_number={engine_number}",
      },
      EQUIPMENT: {
        GET:
          "v2/companies/{company_id}/fleets/{fleet_id}/data/{fleet_data_id}/equipments/",
        LIST:
          "v2/companies/{company_id}/fleets/{fleet_id}/data/{fleet_data_id}/equipments/",
        POST:
          "v2/companies/{company_id}/fleets/{fleet_id}/data/{fleet_data_id}/equipments/",
        PUT:
          "v2/companies/{company_id}/fleets/{fleet_id}/data/{fleet_data_id}/equipments/{id}/",
        GET_TYPE: "v2/commons/choices/equipment_type/",
        GET_SUSPENSION_TYPE:
          "v2/companies/catalogs/load_shaft_suspension_type/",
      },
      OPERATOR: {
        GET: "v2/companies/{company_id}/fleets/{fleet_id}/operators/{id}/",
        POST: "v2/companies/{company_id}/fleets/{fleet_id}/operators/",
        PUT: "v2/companies/{company_id}/fleets/{fleet_id}/operators/{id}/",
        UPDATE_NORMATIVITY_STATUS:
          "v2/companies/{company_id}/fleets/{fleet_id}/operators/{id}/?view=update",
        NORMATIVITY: {
          SHARE:
            "v2/companies/{company_id}/fleet_provider/{fleet_id}/operators/{operator_id}/normativities/",
          GET:
            "v2/companies/{company_id}/fleets/{fleet_id}/operators/{operator_id}/normativities/",
          POST:
            "v2/companies/{company_id}/fleets/{fleet_id}/operators/{operator_id}/normativities/",
          PUT:
            "v2/companies/{company_id}/fleets/{fleet_id}/operators/{operator_id}/normativities/{id}/",
          IMAGE:
            "v2/companies/{company_id}/fleets/{fleet_id}/operators/{operator_id}/normativities/{normativity_id}/images/",
          DELETE_IMAGE:
            "v2/companies/{company_id}/fleets/{fleet_id}/operators/{operator_id}/normativities/{normativity_id}/images/{id}/",
          HISTORY:
            "v2/companies/{company_id}/fleets/{fleet_id}/operators/{operator_id}/normativities/?view=statuslog",
        },
        TAG: {
          GET:
            "v2/companies/{company_id}/fleets/{fleet_id}/operators/{operator_id}/tags/",
          POST:
            "v2/companies/{company_id}/fleets/{fleet_id}/operators/{operator_id}/tags/",
          PUT:
            "v2/companies/{company_id}/fleets/{fleet_id}/operators/{operator_id}/tags/{id}/",
          DELETE:
            "v2/companies/{company_id}/fleets/{fleet_id}/operators/{operator_id}/tags/{id}/",
        },
        VALIDATIONS: {
          ID: "v2/companies/{company_id}/fleets/{fleet_id}/operators/?id={id}",
          LICENSE:
            "v2/companies/{company_id}/fleets/{fleet_id}/operators/?licensenum={licensenum}",
          APTITUDE:
            "v2/companies/{company_id}/fleets/{fleet_id}/operators/?aptitude={aptitude}",
          SECURITY:
            "v2/companies/{company_id}/fleets/{fleet_id}/operators/?socialsecurity={socialsecurity}",
        },
      },
      VEHICLE: {
        POST:
          "v2/companies/{company_id}/fleets/{fleet_id}/data/{fleet_data_id}/vehicles/",
        PUT:
          "v2/companies/{company_id}/fleets/{fleet_id}/data/{fleet_data_id}/vehicles/{id}/",
        GET:
          "v2/companies/{company_id}/fleets/{fleet_id}/data/{fleet_data_id}/vehicles/{id}/",
        LIST:
          "v2/companies/{company_id}/fleets/{fleet_id}/data/{fleet_data_id}/vehicles/",
        LIST_SHARE:
          "v2/companies/{company_id}/fleet_provider/{fleet_id}/vehicles/{fleet_data_id}/vehicles/",
      },
      NORMATIVITY: {
        GET:
          "v2/companies/{company_id}/fleets/{fleet_id}/data/{fleet_data_id}/normativities/",
        POST:
          "v2/companies/{company_id}/fleets/{fleet_id}/data/{fleet_data_id}/normativities/",
        IMAGE:
          "v2/companies/{company_id}/fleets/{fleet_id}/data/{fleet_data_id}/normativities/{normativity_id}/images/",
        SHARE_IMAGE_EQUIPMENT:
          "v2/companies/{company_id}/fleet_provider/{fleet_id}/equipments/{fleet_data_id}/normativities/{normativity_id}/images/",
        SHARE_IMAGE_VEHICLE:
          "v2/companies/{company_id}/fleet_provider/{fleet_id}/vehicles/{fleet_data_id}/normativities/{normativity_id}/images/",
        SHARE_IMAGE_OPERATOR:
          "v2/companies/{company_id}/fleet_provider/{fleet_id}/operators/{fleet_data_id}/normativities/{normativity_id}/images/",
        DELETE_IMAGE:
          "v2/companies/{company_id}/fleets/{fleet_id}/data/{fleet_data_id}/normativities/{normativity_id}/images/{id}/",
        PUT:
          "v2/companies/{company_id}/fleets/{fleet_id}/data/{fleet_data_id}/normativities/{id}/",
        PATCH:
          "v2/companies/{company_id}/fleets/{fleet_id}/data/{fleet_data_id}/normativities/{id}/",
        PATCH_SHARE_VEHICLE:
          "v2/companies/{company_id}/fleet_provider/{fleet_id}/vehicles/{fleet_data_id}/normativities/{id}/",
        PATCH_SHARE_EQUIPMENT:
          "v2/companies/{company_id}/fleet_provider/{fleet_id}/equipments/{fleet_data_id}/normativities/{id}/",
        PATCH_SHARE_OPERATOR:
          "v2/companies/{company_id}/fleet_provider/{fleet_id}/operators/{fleet_data_id}/normativities/{id}/",
        LIST:
          "v2/companies/{company_id}/fleets/{fleet_id}/data/{fleet_data_id}/normativities/",
        SHARE_EQUIPMENT_LIST:
          "v2/companies/{company_id}/fleet_provider/{fleet_id}/equipments/{fleet_data_id}/normativities/",
        SHARE_VEHICLE_LIST:
          "v2/companies/{company_id}/fleet_provider/{fleet_id}/vehicles/{fleet_data_id}/normativities/",
        SHARE_OPERATOR_LIST:
          "v2/companies/{company_id}/fleet_provider/{fleet_id}/operators/{fleet_data_id}/normativities/",
        HISTORY:
          "v2/companies/{company_id}/fleets/{fleet_id}/data/{fleet_data_id}/normativities/?view=statuslog",
        STATUS: {
          GET:
            "v2/companies/{company_id}/fleets/{fleet_id}/data/{fleet_data_id}/normativities/?view=statuslog",
          VEHICLE:
            "v2/companies/{company_id}/fleet_provider/{fleet_id}/vehicles/{fleet_data_id}/normativities/?view=statuslog",
          EQUIPMENT:
            "v2/companies/{company_id}/fleet_provider/{fleet_id}/equipments/{fleet_data_id}/normativities/?view=statuslog",
          OPERATOR:
            "v2/companies/{company_id}/fleet_provider/{fleet_id}/operators/{fleet_data_id}/normativities/?view=statuslog",
          OWN_OPERATOR:
            "v2/companies/{company_id}/fleets/{fleet_id}/operators/{fleet_data_id}/normativities/?view=statuslog",
        },
      },
      TAG: {
        POST:
          "v2/companies/{company_id}/fleets/{fleet_id}/data/{fleet_data_id}/tags/",
        GET:
          "v2/companies/{company_id}/fleets/{fleet_id}/data/{fleet_data_id}/tags/",
        DELETE:
          "v2/companies/{company_id}/fleets/{fleet_id}/data/{fleet_data_id}/tags/{id}/",
        LIST:
          "v2/companies/{company_id}/fleets/{fleet_id}/data/{fleet_data_id}/tags/",
      },
    },
    OPERATOR: {
      GET_BY_LICENSE:
        "v2/companies/{company_id}/fleets/{fleet_id}/operators/?license={license}",
    },
    UPLOAD: {
      POST: "v2/companies/{company_id}/upload/?type={type_id}",
    },
  },

  COMPANY: {
    FLEET: {
      SUMMARY: "v2/companies/{company_id}/fleets/?view=summary",
    },
    GET: "v2/companies/{company_id}/",
    LIST: "v2/companies/",
    VALIDATE_ORBI_ID: "v2/companies/?orbi_id={orbi_id}",
    VALIDATE_DOMAIN: "v2/companies/?email={email}",
    VALIDATE_COMPANY:
      "v2/companies/{company_id}/network/?lookup={search_value}&action={action}",
    INVITATION: {
      LIST: "v2/companies/{company_id}/invitations/",
      POST: "v2/companies/{company_id}/invitations/",
      GET:
        "v2/companies/{company_id}/invitations/{invitation_id}/?reject={reject}&accept={accept}&resend={resend}",
      TYPES: {
        LIST: "v2/commons/choices/invitation_type/",
        POST: "v2/commons/choices/invitation_type/",
      },
      FACILITY: {
        LIST:
          "v2/companies/{company_id}/invitations/{invitation_id}/facilities/",
        POST:
          "v2/companies/{company_id}/invitations/{invitation_id}/facilities/",
        DELETE:
          "v2/companies/{company_id}/invitations/{invitation_id}/facilities/{id}",
      },
    },
    NETWORK: {
      SEARCH: "v2/companies/{company_id}/network/?search={searchStr}",
      INVITATIONS: {
        LIST: {
          RECEIVED:
            "v2/companies/{company_id}/network/?view=received_request&limit={limit}&page={offset}&text={searchStr}",
          SENT:
            "v2/companies/{company_id}/network/?view=sent_request&limit={limit}&page={offset}&text={searchStr}",
          APPROVED:
            "v2/companies/{company_id}/network/?view=approved_request&limit={limit}&page={offset}&text={searchStr}",
        },
        REJECT:
          "v2/companies/{company_id}/network/{request_id}/?request=reject",
        ACCEPT:
          "v2/companies/{company_id}/network/{request_id}/?request=accept",
        RESEND:
          "v2/companies/{company_id}/network/{request_id}/?request=resend",
        DELETE: "v2/companies/{company_id}/network/{request_id}/",
        POST: "v2/companies/{company_id}/network/", //body: to_companies:
        INVITE_NON_USER:
          "v2/companies/{company_id}/users/{user_id}/?invite_company={email}",
      },
      CONTACTS: {
        PATCH: "v2/companies/{company_id}/network/{network_id}/",
      },
    },
    COMPANY_USERS: {
      POST: "v2/companies/{company_id}/companies_users/",
      LIST: "v2/companies/{company_id}/companies_users/",
      GET: "v2/companies/{company_id}/companies_users/{user_id}",
      DELETE: "v2/companies/{company_id}/companies_users/{user_id}",
    },
    CATALOG: {
      USERS:
        "v2/companies/{company_id}/facilities/{facility_id}/users/?view=catalog&limit={limit}&offset={offset}",
      NETWORK_STATUS: "v2/companies/catalogs/network_status/",
      COMPANY_TYPE: "v2/companies/catalogs/company_type/",
    },
    USERS: {
      LIST:
        "v2/companies/{company_id}/users/?type={type}&cards={cards}&filtered={filtered}&facilities={facilities}&role={role}&account_status={account_status}",
      FACILITY: {
        LIST: "v2/companies/{company_id}/users/{user_id}/users_facilities/",
        POST: "v2/companies/{company_id}/users/{user_id}/users_facilities/",
        DELETE:
          "v2/companies/{company_id}/users/{user_id}/users_facilities/{id}/",
      },
      FACILITIES: "v2/companies/{company_id}/users/{user_id}/facilities/",
      TAG: {
        LIST: {
          USER:
            "v2/companies/{company_id}/users/{user_id}/users_tags/?tag_type=1",
          FLEET:
            "v2/companies/{company_id}/users/{user_id}/users_tags/?tag_type=3",
        },
        POST: "v2/companies/{company_id}/users/{user_id}/users_tags/", //body: {tags: 1}
        PATCH:
          "v2/companies/{company_id}/users/{user_id}/users_tags/{user_tag_id}/", //body: {tags: 1}
        DELETE:
          "v2/companies/{company_id}/users/{user_id}/users_tags/{user_tag_id}/",
      },
      ROLES: {
        PATCH: "v2/companies/{company_id}/companies_users/{company_user_id}/", //body -> role
        DELETE:
          "v2/companies/{company_id}/companies_users/?user={user_id}&default=true",
      },
      ALERT_FACILITY:
        "v2/companies/{company_id}/users/{user_id}/alert_facility/",
      NOTIFICATION_FACILITY:
        "v2/companies/{company_id}/users/{user_id}/notification_facility/",
    },
    INVITATIONS: {
      ACCEPT_USER_INVITATION:
        "v2/companies/{company_id}/invitations/{invitation_id}/?accept=true",
      RESEND_USER_INVITATION:
        "v2/companies/{company_id}/invitations/{invitation_id}/?resend=true",
      CANCEL_USER_INVITATION:
        "v2/companies/{company_id}/invitations/{invitation_id}/?reject=true",
      CANCEL_USER_INVITATION_FROM_FACILITY:
        "v2/companies/{company_id}/invitations/{invitation_id}/?reject_fromfacility=true",
    },
    EXPEDITION_CLASS: {
      LIST: "v2/companies/{company_id}/transport_class/",
      POST: "v2/companies/{company_id}/transport_class/",
      PATCH: "v2/companies/{company_id}/transport_class/{class_id}/",
      DELETE: "v2/companies/{company_id}/transport_class/{class_id}/",
      DETAIL: "v2/companies/{company_id}/transport_class/?view=detailed",
      TAGS: {
        LIST: "v2/companies/{company_id}/transport_class/{class_id}/tags/",
        POST: "v2/companies/{company_id}/transport_class/{class_id}/tags/", //body -> tags
        DELETE:
          "v2/companies/{company_id}/transport_class/{class_id}/tags/{class_tag_id}/",
      },
    },
    ROLES: {
      LIST: "v2/companies/{company_id}/roles/",
      LIST_WITH_PERMISSIONS: "v2/companies/{company_id}/roles/?detail=true",
      CREATE: "v2/companies/{company_id}/roles/", // body -> description, color
      PATCH: "v2/companies/{company_id}/roles/{rol_id}/", // body -> description, color
      DELETE: "v2/companies/{company_id}/roles/{rol_id}/?on_cascade=true",
      PERMISSIONS: {
        LIST: "v2/companies/{company_id}/roles/{rol_id}/detail/",
        PATCH:
          "v2/companies/{company_id}/roles/{rol_id}/detail/{permission_id}/", //body -> p_create, p_read, p_update, p_delete
      },
    },
    CONTRACTS: {
      LIST:
        "v2/companies/{company_id}/contracts/?view=catalog&limit={limit}&offset={offset}&facility={facilities}&transportclass={transportClass}&network={network}&search={search}",
      POST: "v2/companies/{company_id}/contracts/", //body- quantity, date_from, date_to, network, transport_class, facilities
      DELETE: "v2/companies/{company_id}/contracts/{contract_id}/",
      ADDENDUM: {
        POST: "v2/companies/{company_id}/contracts/{contract}/addendums/", //body - quantity, date_from, date_to, contract
      },
      CLASSES: {
        POST: "v2/companies/{company_id}/contracts/{contract_id}/classes/",
      },
      BASE: {
        PUT: "v2/companies/{company_id}/contracts/{contract_id}/",
      },
    },
    ALERTS: {
      LIST: "v2/companies/{company_id}/alerts/",
    },
    NOTIFICATIONS: {
      LIST: "v2/companies/{company_id}/notifications/",
    },
    TAGS: {
      LIST: "v2/companies/{company_id}/tags/?type={type}",
    },
  },
  PRODUCTS: {
    BASE: 'v2/companies/',
    FILTERS: "v2/companies/{company_id}/products/?view=filters&tags=&skus=&products=&search=&limit=&offset=",
    NEW_PRODUCT: "v2/companies/{company}/products/",
    PRODUCT: "v2/companies/{company_id}/products/{product_id}/",
    UNITS_CATALOGS: "v2/companies/{company_id}/units/" 
  },
  TAG: {
    GET: "v2/companies/{company_id}/tags/",
    POST: "v1/tag/",
    PUT: "v1/tag/{tag_id}/",
    DELETE: "v1/tag/{tag_id}/",
    TYPE: {
      GET: "v2/commons/choices/tag_type/",
    },
  },
  ROUTES: {
    NOMENCLATURE: {
      FILES: "v2/companies/{company_id}/routes/{route_id}/{type_file}/",
    },
  },
};

const responseEvaluator = (response, resolve, reject) => {
  if (response.ok) {
    return resolve(response);
  } else if (response.status === 401) {
    window.location.replace("/login?expiration=true");
  } else if (response.status === 403) {
    return reject(response.status);
  } else {
    return reject(response.json());
  }
};

export const createApiUrl = (path, params) => {
  return BACKEND.SERVER.concat(BACKEND.API, interpolate(path, params));
};

export const interpolate = function(str, params) {
  const names = Object.keys(params);
  const values = Object.values(params);
  names.forEach((p, index) => {
    str = str.replace(`{${p}}`, values[index]);
  });
  return str;
};

export function getUrlsEnv(prefix = 'orbiback.') {
  var apiUrlEnv = window.location.protocol + "//" + prefix + window.location.hostname;
  var filesUrlEnv = apiUrlEnv + "/api/v2/bridge/resource/private?path="
  var mqttUrlEnv = "mqtt." + window.location.hostname;
  var socketIoUrl = window.location.hostname.includes('qa.') ? 'https://zzezcmz2xi.us-west-2.awsapprunner.com/' : 'https://tmzbrygje2.us-west-2.awsapprunner.com/';
  var satUrl = window.location.hostname.includes('qa.') ? 'https://qasat.s3.us-west-1.amazonaws.com/' : 'https://prodsat.s3.us-west-1.amazonaws.com/';

  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    apiUrlEnv = "https://orbiback.qa.orbinetwork.com";
    filesUrlEnv =  "https://orbiback.qa.orbinetwork.com/api/v2/bridge/resource/private?path=";
    mqttUrlEnv = "mqtt.qa.orbinetwork.com";
    socketIoUrl = 'https://zzezcmz2xi.us-west-2.awsapprunner.com/';
    satUrl = 'https://qasat.s3.us-west-1.amazonaws.com/';
  }
  return { api: apiUrlEnv, files: filesUrlEnv, mqtt: mqttUrlEnv, socket: socketIoUrl, satUrl };
}

export function getApiUrl(apiCall, params = {}) {
  return new Promise((resolve, reject) => {
    fetch(
      new Request(
        BACKEND.SERVER.concat(BACKEND.API, interpolate(apiCall, params)),
        {
          method: "GET",
          headers: {
            [HEADERS().authorization.title]: HEADERS().authorization.value,
          },
        }
      )
    ).then((response) => responseEvaluator(response, resolve, reject));
  });
}

export function getApiUrlWithToken(apiCall, params = {}) {
  return new Promise((resolve, reject) => {
    fetch(
      new Request(
        BACKEND.SERVER.concat(BACKEND.API, interpolate(apiCall, params)),
        {
          method: "GET",
          headers: {
            [HEADERS().authorization.title]: params.session
              ? "Bearer ".concat(params.session)
              : HEADERS().authorization.value,
          },
        }
      )
    ).then((response) => responseEvaluator(response, resolve, reject));
  });
}

export function getApiUrlWithoutAuthorization(apiCall, params = {}) {
  return new Promise((resolve, reject) => {
    fetch(
      new Request(
        BACKEND.SERVER.concat(BACKEND.API, interpolate(apiCall, params)),
        {
          method: "GET",
        }
      )
    ).then((response) => responseEvaluator(response, resolve, reject));
  });
}

export function deleteApiUrl(apiCall, params = {}) {
  return new Promise((resolve, reject) => {
    fetch(
      new Request(
        BACKEND.SERVER.concat(BACKEND.API, interpolate(apiCall, params)),
        {
          method: "DELETE",
          headers: {
            [HEADERS().contentType.title]: HEADERS().contentType.json,
            [HEADERS().authorization.title]: HEADERS().authorization.value,
          },
        }
      )
    ).then((response) => responseEvaluator(response, resolve, reject));
  });
}

export function postApiUrl(apiCall, params = {}) {
  return new Promise((resolve, reject) => {
    fetch(
      new Request(
        BACKEND.SERVER.concat(BACKEND.API, interpolate(apiCall, params)),
        {
          method: "POST",
          body: JSON.stringify(params),
          headers: {
            [HEADERS().contentType.title]: HEADERS().contentType.json,
            [HEADERS().authorization.title]: HEADERS().authorization.value,
          },
        }
      )
    ).then((response) => responseEvaluator(response, resolve, reject));
  });
}

export function postApiUrlWithoutAuthorization(apiCall, params = {}) {
  return new Promise((resolve, reject) => {
    fetch(
      new Request(
        BACKEND.SERVER.concat(BACKEND.API, interpolate(apiCall, params)),
        {
          method: "POST",
          body: JSON.stringify(params),
          headers: {
            [HEADERS().contentType.title]: HEADERS().contentType.json,
          },
        }
      )
    ).then((response) => responseEvaluator(response, resolve, reject));
  });
}

export function putApiUrl(apiCall, params = {}) {
  return new Promise((resolve, reject) => {
    return fetch(
      new Request(
        BACKEND.SERVER.concat(BACKEND.API, interpolate(apiCall, params)),
        {
          method: "PUT",
          body: JSON.stringify(params),
          headers: {
            [HEADERS().contentType.title]: HEADERS().contentType.json,
            [HEADERS().authorization.title]: HEADERS().authorization.value,
          },
        }
      )
    ).then((response) => responseEvaluator(response, resolve, reject));
  });
}

export function putApiUrlTemp(apiCall, params = {}) {
  return new Promise((resolve, reject) => {
    return fetch(
      new Request(
        BACKEND.SERVER.concat(BACKEND.API, interpolate(apiCall, params)),
        {
          method: "PUT",
          body: JSON.stringify(params),
          headers: {
            [HEADERS().contentType.title]: HEADERS().contentType.json,
            [HEADERS().authorization.title]: HEADERS().authorization.value,
          },
        }
      )
    ).then((response) => responseEvaluator(response, resolve, reject));
  });
}

export function putMultipartApiUrl(apiCall, params = {}) {
  return new Promise((resolve, reject) => {
    fetch(
      new Request(BACKEND.SERVER.concat(BACKEND.API, apiCall), {
        method: "PUT",
        body: params,
        headers: {
          [HEADERS().authorization.title]: HEADERS().authorization.value,
        },
      })
    ).then((response) => responseEvaluator(response, resolve, reject));
  });
}

export function postMultipartApiUrl(apiCall, params = {}) {
  return new Promise((resolve, reject) => {
    fetch(
      new Request(BACKEND.SERVER.concat(BACKEND.API, apiCall), {
        method: "POST",
        body: params,
        headers: {
          [HEADERS().authorization.title]: HEADERS().authorization.value,
        },
      })
    ).then((response) => responseEvaluator(response, resolve, reject));
  });
}

export function patchMultipartApiUrl(apiCall, params = {}) {
  return new Promise((resolve, reject) => {
    fetch(
      new Request(
        BACKEND.SERVER.concat(BACKEND.API, interpolate(apiCall, params)),
        {
          method: "PATCH",
          body: params,
          headers: {
            [HEADERS().authorization.title]: HEADERS().authorization.value,
          },
        }
      )
    ).then((response) => responseEvaluator(response, resolve, reject));
  });
}

export function patchApiUrl(apiCall, params = {}) {
  return new Promise((resolve, reject) => {
    fetch(
      new Request(
        BACKEND.SERVER.concat(BACKEND.API, interpolate(apiCall, params)),
        {
          method: "PATCH",
          body: JSON.stringify(params),
          headers: {
            [HEADERS().contentType.title]: HEADERS().contentType.json,
            [HEADERS().authorization.title]: HEADERS().authorization.value,
          },
        }
      )
    ).then((response) => responseEvaluator(response, resolve, reject));
  });
}

export function openSecureDoc(url) {
  // let newUrl = url.concat(
  //   "?tokenauth=" +
  //     (JSON.parse(sessionStorage.getItem("orbinetwork_session")) || {}).access
  // );
  let newUrl = url;
  return new Promise((resolve, reject) => {
    fetch(
      new Request(newUrl, {
        method: "GET",
      })
    ).then((response) => responseEvaluator(response, resolve, reject));
  });
}

const HEADERS = () => ({
  contentType: {
    title: "Content-Type",
    json: "application/json",
  },
  authorization: {
    title: "Authorization",
    value:
      "Bearer " +
      (JSON.parse(sessionStorage.getItem("orbinetwork_session")) || {}).access,
  },
});
